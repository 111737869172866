.pill__container {
    .grid-container {
        > span {
            @include block-title;
        }
        .pill_slider {
            &.--right{
                .swiper-slide:first-child{
                    margin-left:auto;
                }
            }
            section {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 2rem;
                .swiper-container {
                    .swiper-slide {
                        margin-right: 1rem;
                        width: auto;
                    }
                }
                .swiper-button-disabled {
                    @include breakpoint(medium up) {
                        display: none;
                    }
                }
            }

            a {
                line-height: 3.8rem;
                padding: 0 1.5rem;
                border: 0.1rem solid #eae7e8;
                border-radius: 25px;
                color: color(base, DefaultFontColor);
                font-size: 1.5rem;
                width: 100%;
                display: inline-block;
                text-align: center;
                margin-right: 1rem;

                &:hover {
                    span {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    &--two-column{
        > div{
            display: flex;
            gap: .8rem;
            margin-bottom: .8rem;
        }
        .--button {
            line-height: 2.4rem;
            padding:1.2rem 1.6rem;
            max-width: 50%;
            width:50%;
            height: auto;
            justify-content: center;
            font-weight: normal;
            font-size: 1.4rem;
            text-transform: none;
        }
        .show-more{
            width: 100%;
            text-align: center;
            font-size:1.3rem;
            text-decoration: underline;
        }
    }
}
