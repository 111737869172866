.opening-hours{
    width: 100%;
    &__button{
        font-size:1.4rem;
        font-weight: 400;
        &:after{
            content: '';
            display: inline-block;
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M2.03818 4.70479C2.38531 4.35766 2.94813 4.35766 3.29526 4.70479L8.00005 9.40959L12.7048 4.70479C13.052 4.35766 13.6148 4.35766 13.9619 4.70479C14.3091 5.05193 14.3091 5.61474 13.9619 5.96187L8.62859 11.2952C8.28146 11.6423 7.71865 11.6423 7.37151 11.2952L2.03818 5.96187C1.69105 5.61474 1.69105 5.05193 2.03818 4.70479Z" fill="black"/></svg>');
            background-size: contain;
            height: 1.6rem;
            width: 1.6rem;
            background-repeat: no-repeat;
            vertical-align: middle;
            margin-left: .4rem;
        }
        &.--active{
            margin-bottom: .8rem;
            &:after{
                transform: rotate(180deg);
            }
        }
    }
    &__items{
        padding-right: 2rem;
        li{
            width: 100%;
            display: flex;
            justify-content: space-between;
            font-size: 1.3rem;
            padding:.8rem;
            &:nth-child(even){
                background-color: color(gray, Gray-soft);
            }
        }
    }
}
