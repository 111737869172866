.custom-form {
    h1 {
        font-size: 2.6rem;
        line-height: 4.6rem;
        margin: 0 0 1rem;
    }

    h2 {
        font-size: 2rem;
        line-height: 32px;
        margin: 25px 0 5px;
    }

    p {
        display: block;
        margin-bottom: 10px;
    }

    a {
        color: color(base, Tertiary);
    }

    .required-note {
        display: block;
        font-size: 12px;
    }

    button {
        display: block;
        margin: 20px 0 0;
        @include default-button;
    }

    form {
        display: block;
        @include breakpoint(medium) {
            max-width: 580px;
        }

        .form-group {
            margin-bottom: 10px;
        }

        input, textarea {
            width: 100%;
        }
    }

    .form-group > label {
        display: block;
        font-size: 13px;
        line-height: 30px;
        padding: 0;
    }

    .--flex {
        display: flex;
    }
}

.contact-form {
    .success {
        color: color(alert, Success);
        display: block;
        font-size: 1.3rem;
        margin: 0 0 1rem;
    }

    .image-label span,
    .extra-checkboxes {
        display: none;
    }

    .file-field{
        > .image-label{
            display: flex;
        }
    }

    .file-upload{
        opacity: 0;
    }

    input[type="checkbox"] + label {
        display: flex;
        font-size: 1.4rem;
    }

    .form-group {
        label {
            font-size: 1.4rem;
            line-height: 30px;
        }

        &.postcode,
        &.address {
            display: flex;
            gap: 2rem;
        }


        .postcode,
        .number,
        .additional,
        .city {
            display: block;
            flex-basis: 50%;
        }
    }

    .comments {
        max-width: 100%;

        textarea {
            height: 200px;
            line-height: 24px;
            padding: 10px 24px;
        }
    }

    .subject {
        select {
            width: 100%;
        }
    }

    .file-field {
        .__filename {
            font-size: 12px;
        }

        .button {
            margin-right: 10px;

            &.secondary {
                background-color: color(gray, Gray-100);
                border-color: color(gray, Gray-200);
                @include breakpoint(medium) {
                    &:hover {
                        background-color: color(gray, Gray-200);
                        border-color: color(gray, Gray-300);
                    }
                }
            }
        }

        #__remove_file {
            color: red;
            cursor: pointer;
            font-size: 1.4rem;
            margin-left: 0.5rem;
        }

        #__file_error {
            color: red;
            font-size: 1.4rem;
            margin-left: 0.5rem;
        }
    }

    .extra-checkboxes {
        input[type="checkbox"] {
            position: unset;
        }
    }

    .required-note {
        display: block;
        font-size: 12px;
    }

    button {
        display: block;
        margin: 20px 0 0;
        @include default-button;
    }
}

.business-form {
    .firstname {
        clear: none;
        float: left;
        margin-right: 10px;
        max-width: 160px;
    }

    .lastname {
        clear: none;
        float: left;
        margin-right: 10px;
        max-width: 160px;
    }

    .company {
        max-width: 370px;
    }

    .telephone {
        max-width: 270px;
    }

    .mail {
        max-width: 370px;
    }

    .street {
        clear: none;
        float: left;
        margin-right: 10px;
        max-width: 290px;
        width: 100%;
    }

    .number {
        clear: none;
        float: left;
        margin-right: 10px;
        max-width: 120px;
        width: 100%;
    }

    .postcode {
        clear: none;
        float: left;
        margin-right: 10px;
        max-width: 160px;
        width: 100%;
    }

    .city {
        clear: none;
        float: left;
        max-width: 200px;
        width: 100%;
    }

    .comments {
        clear: none;
        float: left;
        max-width: 100%;
        width: 100%;
    }

    input[type=date] {
        max-width: 270px;
    }

    input[type=checkbox] + label {
        clear: both;
    }

    .required-note {
        clear: both;
    }
}

.sidebar_right {
    ._contact {
        background: url(../assets/images/xenos-employees.png) bottom no-repeat color(gray, Gray-100);

        background-size: contain;
        padding: 16px 20px 105px;

        h3 {
            color: color(base, Black);
            font-size: 16px;
            font-weight: 700;
            line-height: 26px;
        }

        p {
            font-size: 14px;
            line-height: 26px;
            margin-bottom: 10px;

            a {
                background-color: transparent;
                color: color(base, Tertiary);
                outline: none;
                text-decoration: none;
            }
        }

        ul li {
            font-size: 14px;
            line-height: 26px;
            margin-bottom: 10px;
        }
    }

    .__usp {
        margin: 0 0 20px;
        padding: 0 20px 15px;

        li {
            &.title {
                font-size: 16px;
                line-height: 50px;
                margin: 0;
            }
        }
    }
}
