.global__navigation {
    .--dropdown {
        margin-top: -.1rem;
        position: absolute;
        left: -1rem;
        display: flex;
        width: calc(100% + 1rem);
        nav {
            background: rgba(255, 255, 255, .98);
            flex-shrink: 0;
            display: flex;
            flex-flow: column;
            border: .1rem solid color(gray, Gray-100);
            width: 21.5rem;
            > ul {
                margin: 1rem 0 0;
                > li {
                    > a {
                        display: block;
                        line-height: 3.4rem;
                        padding: 0 1rem;
                        font-size: 1.4rem;
                    }
                    .item__dropdown {
                        display: none;
                        position: absolute;
                        top: -3.6rem;
                        left: 21.4rem;
                        background: color(base, White);
                        padding: 1rem 2rem 2rem;
                        z-index: 3;
                        border: .1rem solid color(gray, Gray-100);
                        width: max-content;
                        > div {
                            flex: 1 0 fit-content;
                            &:not(:first-child) {
                                margin-left: 2rem;
                            }
                            ul {
                                margin: 1rem 0 0;
                                li {
                                    a {
                                        font-size: 1.2rem;
                                        line-height: 2.4rem;
                                        &:hover {
                                            text-decoration: underline;
                                        }
                                    }
                                    &:first-child a {
                                        color: color(base, Tertiary);
                                        font-weight: 700;
                                        &:hover {
                                            color: color(base, TertiaryHover);
                                            text-decoration: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &:hover {
                        > a {
                            background-color: color(gray, Gray-soft);
                            background-repeat: no-repeat;
                            background-size: 2.5rem;
                            background-position: right .5rem center;
                        }
                        &.--has-sub > a {
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='#{color(gray, Gray-200)}' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M9 5l7 7-7 7' /%3E%3C/svg%3E");
                        }
                        .item__dropdown {
                            display: flex;
                        }
                    }
                }
            }
        }
    }
}

.room__navigation,
.main__navigation {
    position: relative;
}

.room__navigation {
    margin-left: 5rem;

    .global__navigation {
        .--dropdown {
            width: 100%;
            nav {
                width: 80rem;
                max-width: calc(100vw - 30rem);

                ul {
                    display: grid;
                    padding-bottom: 1rem;
                    row-gap: 1rem;
                    grid-template-columns: repeat(4, 1fr);

                    li {
                        cursor: pointer;

                        a {
                            padding: 1rem 0.5rem;
                            line-height: 2rem;
                            text-align: center;

                            img {
                                @include border-radius();
                            }
                        }
                    }
                }
            }
        }
    }
}

.cat-navigation-mobile {
    #menu__overview a {
        color: color(base, Tertiary);
        font-weight: 700;
    }
    .navigation-group-header.navigation-header {
        display: none;
    }
    .menu.vertical.nested {
        > div {
            ul {
                li {
                    ul {
                        li {
                            &:first-child a {
                                font-weight: 700;
                            }
                            &:not(:first-child) a {
                                padding-left: 2rem;
                            }
                        }
                    }
                }
            }
        }
    }
}
