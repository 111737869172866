.global__message {
    font-size: 1.2rem;
    line-height: 1.6rem;
    display: inline-block;
    &.--margin {
        margin: 1rem 0;
    }
    &.--margin--top {
        margin: .5rem 0 0;
    }
    &.--full-width {
        width: 100%;
    }
    &.--space {
        padding: .5rem 1rem;
    }
    @each $messageStyle in map-keys(map-get($color-palette, alert)) {
        &.--#{to-lower-case($messageStyle)} {
            color: map-get(map-get($color-palette, alert), $messageStyle);
        }
    }
    &--bg {
        @extend .global__message;
        border-radius: .5rem;
        padding: .25rem 1rem;
        @each $messageStyle in map-keys(map-get($color-palette, alert)) {
            &.--#{to-lower-case($messageStyle)} {
                background-color: map-get(map-get($color-palette, alert-backgrounds), $messageStyle);
                border: .1rem solid darken(map-get(map-get($color-palette, alert-backgrounds), $messageStyle), 10%);
            }
        }
    }
    a {
        font-size: 1.2rem;
        line-height: 1.6rem;
        text-decoration: underline;
    }
}

input, textarea, select {
    &.required.failed {
        border-color: color(alert, Error);
    }
}

.global__messages {
    position: fixed;
    right: 1rem;
    bottom: 1rem;
    z-index: 7;
    max-height: 50vh;
    overflow: hidden;
    li {
        max-width: 30rem;
        margin: 1rem 0 0;
        padding: 1rem;
        display: block;
        line-height: 1.8rem;
    }
}

.field-error {
    display: block;
    font-size: 1.2rem;
    margin: .5rem 0 0;
    color: color(alert, Error);
}
