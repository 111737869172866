.sidebar_right, .receipt {
    .__usp {
        li{
            @include icon('check-green', 'left');
            &.title {
                background: none;
                font-weight: 700;
                padding: 0;
            }
        }
    }
    .--contact {
        background-color: color(gray, Gray-100);
        margin: 2rem 0;
        padding: 1rem 2rem 12rem;
        background-image: url(../assets/images/xenos-employees.png);
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: contain;
        h3 {
            font-size: 1.6rem;
            line-height: 2.6rem;
            font-weight: 700;
            margin: 0 0 .5rem;
        }
        p, ul {
            font-size: 1.4rem;
            line-height: 2.6rem;
            margin: 0 0 1rem;
        }
        a {
            color: color(base, Tertiary);
            @include breakpoint(medium) {
                &:hover {
                    color: color(base, TertiaryHover);
                }
            }
        }
    }
}
