.customer-account {
    .--toggle {
        width: 100%;
    }
    .--header {
        margin: 2rem 0;
        display: flex;
        align-items: center;
        h1 {
            margin: 0;
        }
        .--date {
            margin-left: auto;
            font-size: 1.4rem;
            font-style: italic;
        }
    }
    h1 {
        text-align: center;
        margin: 2rem 0;
        text-transform: uppercase;
        font-size: 2.2rem;
        @include breakpoint(medium) {
            text-align: left;
            font-size: 2.6rem;
            line-height: 4.6rem;
            margin: 2rem 0 1rem;
        }
        &.--left {
            text-align: left;
        }
        > span {
            font-family: 'True North Textures', sans-serif;
            color: color(gray, Gray-400);
        }
    }
    .customer__account-navigation {
        @include breakpoint(medium) {
            margin: 1rem 0;
            .menu {
                height: auto;
                overflow: initial;
                > ul {
                    border: none;
                    a {
                        font-size: 1.6rem;
                        line-height: 3rem;
                        padding: 0;
                        color: color(gray, Gray-500);
                        display: inline-block;
                        &:hover {
                            color: color(base, DefaultFontColor);
                        }
                    }
                }
            }
        }
    }
    .--intro {
        margin: 0 0 2rem;
        strong {
            font-size: 1.6rem;
            line-height: 2.6rem;
        }
        p {
            line-height: 2.6rem;
        }
    }
    .__usp {
        margin: 2rem 0 0;
        li {
            font-size: 1.3rem;
            @include breakpoint(medium) {
                font-size: 1.4rem;
                margin: 1rem 0 0;
                &.title {
                    font-size: 1.6rem;
                }
            }
        }
    }
    .customer__account--block {
        padding: 2rem;
        @include breakpoint(medium) {
            form {
                max-width: 70%;
            }
            a:not(.--button):hover {
                text-decoration: underline;
            }
        }
        &:not(:first-child) {
            margin: 0 0 2rem;
        }
        h2, h3, .block-title {
            font-size: 1.6rem;
            line-height: 2.8rem;
            font-weight: 700;
            font-family: 'Gotham Rounded', sans-serif;
            @include breakpoint(medium) {
                font-size: 2rem;
                line-height: 3.6rem;
            }
        }
        p, .note {
            line-height: 2.6rem;
            font-size: 1.4rem;
        }
        &.--border {
            border: .2rem solid color(base, Black);
        }
    }
    h5, h3, .block-title {
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 2.6rem;
        margin: 0 0 1rem;
    }
    .customer__block {
        position: relative;
        background: color(gray, Gray-100);
        padding: 1.5rem;
        margin: 0 0 1rem;
        &.--no-bg {
            background: transparent;
            padding: 0;
        }
        h5, h3 {
            margin: 0;
        }
        address {
            font-size: 1.4rem;
            line-height: 2.6rem;
        }
        p:not(.global__message) {
            line-height: 2.6rem;
        }
        a:not(.--button) {
            color: color(base, Tertiary);
            @include breakpoint(medium) {
                color: color(base, TertiaryHover);
            }
            &.--floating {
                position: absolute;
                top: 1.5rem;
                right: 2rem;
                color: color(base, Secondary);
                font-size: 1.2rem;
            }
        }
        a.--button {
            margin: 1rem 0 0;
        }
    }
    .empty__favorites {
        text-align: center;
        a {
            margin: 2rem 0 0;
        }
    }
    .customer__wishlist {
        .product__tile {
            display: flex;
            flex-direction: column;
            height: 100%;

            .product__holder {
                flex-grow: 1;
            }
            .product__actions > span.add-to-cart button {
                display: block;
            }
        }
    }
    .order__totals {
        display: flex;
        margin: 2rem 0 0;
        ul {
            margin-left: auto;
            font-size: 1.4rem;
            @include breakpoint(small only) {
                width: 100%;
            }
            li {
                display: flex;
                padding: .5rem 0;
                span, strong {
                    display: block;
                    flex: 1;
                    &.--free {
                        color: color(alert, Success);
                    }
                    &:last-child {
                        text-align: right;
                        @include breakpoint(medium) {
                            width: 25rem;
                        }
                    }
                }
            }
        }
    }
    table {
        width: 100%;
        font-size: 1.4rem;
        tr {
            th, td {
                padding: .5rem 0;
                &.--right {
                    text-align: right;
                }
                &.--center {
                    text-align: center;
                }
                .--old-price {
                    text-decoration: line-through;
                    padding-right: .5rem;
                    font-size: 1.2rem;
                }
                a {
                    color: color(base, Tertiary);
                    @include breakpoint(medium) {
                        &:hover {
                            color: color(base, TertiaryHover);
                        }
                    }
                }
            }
            th {
                font-weight: 600;
                border-bottom: .1rem solid color(gray, Gray-100);
                padding-bottom: .75rem;
            }
        }
    }
    .--back {
        color: color(base, Tertiary);
        @include breakpoint(medium) {
            &:hover {
                color: color(base, TertiaryHover);
            }
        }
    }
    .checkout__address-autocomplete {
        .--flex {
            display: flex;
            gap: 1rem;
            > div {
                flex: 1;
                &:first-of-type {
                    flex: 20%;
                }
            }
        }
        .checkout__address-autocomplete_result {
            max-width: 100%;
        }
    }
}
