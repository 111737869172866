footer {
    font-size: 1.4rem;
    line-height: 2.6rem;
    margin: 4rem 0 0;
    @include breakpoint(small only) {
        text-align: center;
        margin: 2rem 0 0;
    }
    p {
        margin: 1rem auto;
        @include breakpoint(medium) {
            font-size: 1.6rem;
            line-height: 2.6rem;
        }
    }
    h3 {
        font-weight: 700;
        @include breakpoint(medium) {
            font-size: 1.6rem;
            line-height: 2.6rem;
        }
    }
    .footer__block--top {
        input {
            border-style: dashed;
            width: 100%;
            height: 4rem;
            line-height: 3.8rem;
        }
        @include breakpoint(small only) {
            background-color: color(gray, Gray-100);
            text-align: left;
            a.cta {
                width: 100%;
            }
            .footer__block--top__container {
                background-color: color(base, White);
                padding: 2.4rem;
                border-radius: 1rem;
                a {
                    display: inline-block;
                    height: 4.8rem;
                    line-height: 4.8rem;
                    font-size: 1.4rem;
                    &:first-letter {
                        text-transform: capitalize;
                    }
                }
                p {
                    height: auto !important;
                }
                h3 {
                    position: relative;
                    &:after {
                        content: "";
                        background-image: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.9618 11.495C13.6147 11.8422 13.0519 11.8422 12.7047 11.495L7.99995 6.79025L3.29515 11.495C2.94802 11.8422 2.3852 11.8422 2.03807 11.495C1.69094 11.1479 1.69094 10.5851 2.03807 10.238L7.37141 4.90464C7.71854 4.5575 8.28135 4.5575 8.62849 4.90464L13.9618 10.238C14.309 10.5851 14.309 11.1479 13.9618 11.495Z' fill='black'/%3E%3C/svg%3E%0A");
                        background-size: 1.6rem;
                        background-repeat: no-repeat;
                        background-position: center;
                        width: 1.6rem;
                        height: 1.6rem;
                        display: block;
                        margin: 0.5rem 0;
                        position: absolute;
                        right: 0;
                        top: 0;
                        transition: transform 0.3s ease;
                        transform: rotate(180deg);
                    }
                    &.open:after {
                        transform: rotate(0deg);
                    }
                }
                .hidden {
                    display: none;
                }
            }
        }
        @include breakpoint(medium) {
            background: color(gray, Gray-100);
            .cell {
                &:not(:last-child) {
                    .footer__block--top__container {
                        @media (min-width: 970px) {
                            padding: 0 5rem 0 0;
                        }

                        margin: 0 5rem 0 0;
                    }
                }
                .footer__block--top__container {
                    p {
                        margin: 1rem auto 2rem;
                    }
                    input {
                        border: 0.1rem solid color(gray, Gray-300);
                    }
                }
            }
        }
        .cell {
            border-top: 0.1rem solid color(gray, Gray-100);
            padding-top: 2rem;
            padding-bottom: 2rem;
            @include breakpoint(medium) {
                padding-top: 4rem;
                padding-bottom: 4rem;
            }
            @include breakpoint(small only) {
                padding-top: 0.25rem;
                padding-bottom: 0.25rem;
                &:first-of-type {
                    padding-top: 1rem;
                }
            }
        }
        .store-widget__fields {
            position: relative;
            margin: 1rem 0 0;
            input {
                height: 4rem;
                line-height: 3.8rem;
            }
            button {
                position: absolute;
                right: 0;
                top: 0;
                padding: 0.8rem;
                width: 4rem;
                height: 4rem;
                svg {
                    fill: color(gray, Gray-300);
                    @include breakpoint(medium) {
                        fill: color(base, DarkFontColor);
                    }
                }
            }
        }
        .footer__block__newsletter {
            @include breakpoint(medium) {
                form {
                    section {
                        display: flex;
                        gap: 1rem;
                        input {
                            flex-grow: 1;
                        }
                        button {
                            margin: 0;
                            width: auto;
                            white-space: nowrap;
                        }
                    }
                }
            }
            button {
                margin: 1rem 0 0;
                width: 100%;
            }
        }
    }
    .footer__block__links {
        @include breakpoint(small only) {
            border-top: 0.1rem solid color(gray, Gray-100);
            background-color: color(gray, Gray-100);
            text-align: left;
            padding: 2.4rem 0 4rem 0;
            .grid-x {
                .cell {
                    &:first-of-type {
                        p {
                            border-top: 0.1rem solid #c7c7c7;
                        }
                    }
                }
            }
            p {
                border-bottom: 0.1rem solid #c7c7c7;
                margin: 0;
                > span {
                    position: relative;
                    display: block;
                    line-height: 5rem;
                    font-size: 1.5rem;
                    font-weight: 700;
                    &::before {
                        content: "";
                        position: absolute;
                        right: 4px;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 1.5rem;
                        height: 1.5rem;
                        background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 1.69995V16.7M16.5 9.19995H1.5' stroke='black' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: center;
                        transition: transform 0.3s ease;
                    }
                }
                &.--active > span::before {
                    transform: translateY(-50%) rotate(45deg);
                }
            }

            ul {
                padding: 1rem 0;
                border-bottom: 0.1rem solid color(gray, Gray-100);
                display: none;
                li a {
                    line-height: 3rem;
                }
            }
        }
        @include breakpoint(medium) {
            padding: 3rem 0 6rem;
            p,
            p > span {
                font-weight: 700;
                line-height: 3.6rem;
            }
            ul li a {
                line-height: 3.6rem;
                font-size: 1.6rem;
                &:hover {
                    text-decoration: underline;
                }
            }
        }

        @include breakpoint(medium) {
            ul {
                display: block !important;
            }
        }
    }
    .footer__block--bottom {
        .footer__block--bottom__badges {
            display: flex;
            img {
                max-width: 104px;
            }
        }

        @include breakpoint(small only) {
            background-color: color(gray, Gray-100);
            .footer__block--bottom__badges {
                justify-content: space-around;
                padding: 4rem 0;
                position: relative;
                > div {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 1rem;
                    position: relative;
                    &::after {
                        content: "";
                        position: absolute;
                        right: -40%;
                        top: 0;
                        bottom: 0;
                        width: 1px;
                        background-color: #c7c7c7;
                    }
                    h3 {
                        font-size: 1.5rem;
                    }

                }
                > div:last-child::after {
                    display: none;
                }
            }
        }
        .--safe {
            display: flex;
            gap: 1rem;
            margin: 1rem 0 0;
            padding-right: 2rem;
            flex-flow: row wrap;
            align-items: stretch;
            @include breakpoint(small only) {
                justify-content: center;
                margin: 1rem 0 2rem;
            }
            .hide-for-small-only {
                flex-grow: 1;
            }
        }
        .footer__block--bottom__socials {
            @include breakpoint(small only) {
                padding: 2rem 0;
                background-color: color(base, White);
                border-radius: 1rem;
                h3 {
                    font-size: 1.5rem;
                }
            }
            ul {
                display: flex;
                gap: 0.5rem;
                margin-top: 1rem;
                @include breakpoint(small only) {
                    justify-content: center;
                    gap: 1.6rem;
                    margin-top: 1.6rem;
                }
                li {
                    a {
                        display: flex;
                        width: 4rem;
                        height: 4rem;
                        background: color(base, Black);
                        border-radius: 50%;
                        padding: 0.5rem;
                        align-items: center;
                        justify-content: center;
                        i {
                            display: block;
                            width: 80%;
                            height: 80%;
                            background-size: cover;
                            &.fa-facebook-f {
                                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-brand-facebook' width='44' height='44' viewBox='0 0 24 24' stroke-width='1.5' stroke='White' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M0 0h24v24H0z' stroke='none'/%3E%3Cpath d='M7 10v4h3v7h4v-7h3l1-4h-4V8a1 1 0 0 1 1-1h3V3h-3a5 5 0 0 0-5 5v2H7'/%3E%3C/svg%3E");
                            }
                            &.fa-instagram {
                                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-brand-instagram' width='44' height='44' viewBox='0 0 24 24' stroke-width='1.5' stroke='White' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M0 0h24v24H0z' stroke='none'/%3E%3Crect x='4' y='4' width='16' height='16' rx='4'/%3E%3Ccircle cx='12' cy='12' r='3'/%3E%3Cpath d='M16.5 7.5v.001'/%3E%3C/svg%3E");
                            }
                            &.fa-youtube-play {
                                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-brand-youtube' width='44' height='44' viewBox='0 0 24 24' stroke-width='1.5' stroke='White' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M0 0h24v24H0z' stroke='none'/%3E%3Crect x='3' y='5' width='18' height='14' rx='4'/%3E%3Cpath d='m10 9 5 3-5 3z'/%3E%3C/svg%3E");
                            }
                            &.fa-pinterest-p {
                                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-brand-pinterest' width='44' height='44' viewBox='0 0 24 24' stroke-width='1.5' stroke='White' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M0 0h24v24H0z' stroke='none'/%3E%3Cpath d='m8 20 4-9M10.7 14c.437 1.263 1.43 2 2.55 2 2.071 0 3.75-1.554 3.75-4a5 5 0 1 0-9.7 1.7'/%3E%3Ccircle cx='12' cy='12' r='9'/%3E%3C/svg%3E");
                            }
                        }
                        svg {
                            width: 2.4rem;
                            height: 2.4rem;
                        }
                        @include breakpoint(medium) {
                            &:hover {
                                background-color: color(gray, Gray-600);
                            }
                        }
                    }
                }
            }
        }
        .footer__block--bottom__paymentlogos {
            display: flex;
            justify-content: start;
            flex-wrap: wrap;
            gap: 0.5rem;
            padding: 0 6px;
            margin-top: 1rem;
            @include breakpoint(small only) {
                justify-content: center;
            }
            div {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 3.2rem;
                width: 6rem;
                background-color: color(base, White);
                border-radius: 0.25rem;
                img {
                    @include breakpoint(small only) {
                        height: 2rem;
                    }
                    height: 3.2rem;
                    max-width: 90%;
                    -o-object-fit: scale-down;
                    object-fit: contain;
                    aspect-ratio: 16 / 9;
                }
            }
        }
        .trustedshop {
            margin: 0.5rem 0 0;
        }
        .grid-x .cell:nth-of-type(3){
            @include breakpoint(medium){
            display: flex;
            justify-content: end;
            }
        }
    }
    .footer__block--absolute {
        padding: 2rem;
        font-size: 1.3rem;
        @include breakpoint(small only) {
            background-color: color(gray, Gray-100);
        }
        ul {
            display: inline-flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: 0 auto;
            li {
                display: flex;
                align-items: center;
                justify-content: center;
                white-space: nowrap;
                a {
                    display: block;
                    line-height: 1.8rem;
                    @include breakpoint(medium) {
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
                &:not(:first-child)::before {
                    content: "|";
                    margin: 0 1.5rem;
                }
            }
        }
        .grid-container > span {
            display: block;
            margin: 1rem 0 0;
            font-size: 1.4rem;
            @include breakpoint(medium) {
                display: inline-flex;
                align-items: center;
                a {
                    margin-left: 0.5rem;
                    &:hover {
                        text-decoration: underline;
                    }
                }
                &::before {
                    content: "|";
                    margin: 0 1.5rem;
                }
            }
        }
    }
}

.footer-checkout {
    .footer__block--bottom__paymentlogos {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        justify-content: start;
        align-items: center;
    }
    .footer-checkout__badges {
        img {
            max-height: 40px;
        }
    }
}
