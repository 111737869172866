.product__shopthelook {
    margin: 0 0 1rem;
    position: relative;
    width: 100%;

    > .grid-container {
        display: block;
    }

    .blaze-slider {
        width: 100%;
        @include breakpoint(medium) {
            --slides-to-show: 3;
            --slide-gap: 1rem;
        }

        .--header {
            display: flex;
            align-items: center;
            gap: 2rem;
            margin: 0 0 1rem;

            @include breakpoint(medium) {
                margin: 0 0 2rem;
            }

            .--navigation {
                display: flex;
                gap: 1rem;
                margin-left: auto;
                @include inline-swiper-nav();
            }
        }

        &.static .--navigation {
            display: none;
        }

        @include breakpoint(small only) {
            .blaze-container {
                margin-right: -1rem;
            }
        }
    }

    h3 {
        font-family: Gotham Rounded, sans-serif;
        font-size: 1.8rem;
        font-weight: 700;
        margin: 0 0 .5rem;

        @include breakpoint(medium) {
            font-size: 2.1rem;
            line-height: normal;
            margin: 0;
        }
    }

    p {
        font-size: 1.3rem;
    }

    section {
        position: relative;
        display: flex;
        align-items: center;

        a {
            text-align: center;

            &:hover {
                text-decoration: underline;

                .image__holder {
                    picture {
                        transform: scale(1.05);
                    }
                }
            }

            .image__holder {
                position: relative;
                margin-bottom: 0.5rem;
                overflow: hidden;
                border-radius: 2.2rem;

                &::before {
                    padding-top: 132%;
                    display: block;
                    content: '';
                }

                picture {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: transform .3s ease-in-out;
                }
            }
        }
    }
}

