.global__content {
    h1 {
        line-height: 3rem;
        font-size: 2rem;
        @include breakpoint(medium) {
            line-height: 4.6rem;
            font-size: 2.6rem;
        }
    }
    h2 {
        font-size: 1.8rem;
        @include breakpoint(medium) {
            font-size: 2.2rem;
        }
    }
    h3 {
        font-size: 1.4rem;
        font-family: 'Gotham Rounded', sans-serif;
        font-weight: 700;
        margin: 0 0 .5rem;
    }
    p {
        font-size: 1.4rem;
        line-height: 2.4rem;
        @include breakpoint(medium) {
            font-size: 1.6rem;
            line-height: 3rem;
        }
        &:not(:last-child) {
            margin: 0 0 1.6rem;
        }
    }
    a:not(.--button) {
        font-size: 1.4rem;
        line-height: 2.4rem;
        @include breakpoint(medium) {
            font-size: 1.6rem;
            line-height: 3rem;
        }
        color: color(base, Tertiary);
        &:hover {
            color: color(base, TertiaryHover);
        }
    }
    ul {
        list-style: disc inside;
        font-size: 1.4rem;
        line-height: 2.4rem;
        @include breakpoint(medium) {
            font-size: 1.6rem;
            line-height: 3rem;
        }
    }
}
