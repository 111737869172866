.hotspot__container {
    @include border-radius();
    //overflow: hidden;
    position: relative;
    display: inline-flex;
    picture {
        display: flex;
    }
    .hotspot__item {
        position: absolute;
        .hotspot__item__content {
            button {
                padding: 0;
                display: flex;
                justify-content: center;
                width: 4rem;
                height: 4rem;
                background: color(base, Primary);
                border: .5rem solid color(base, PrimaryHover);
                border-radius: 50%;
                font-size: 3rem;
                line-height: 2.6rem;
                z-index: 1;
                position: relative;
                transition: height 0.2s, width 0.2s, line-height 0.2s, font-size 0.2s, margin 0.2s, transform 0.1s;
                -webkit-animation: breathing 5s ease-out infinite normal;
                animation: breathing 5s ease-out infinite normal;
                &.--small {
                    width: 2.4rem;
                    height: 2.4rem;
                    line-height: 1.8rem;
                    font-size: 2.2rem;
                    border-width: .2rem;
                    + .hotspot__item__detail {
                        margin: 1.5rem;
                        z-index: 3;
                    }
                }
                &:hover, &.--active {
                    -webkit-animation: 0;
                    animation: 0;
                    transform: scale(1.2);
                    z-index: 2;
                    &.--active {
                        transform: rotate(45deg) scale(1.2);
                    }
                }
            }
            .hotspot__item__detail {
                padding: 1rem;
                border: .1rem solid color(gray, Gray-100);
                background: color(base, White);
                position: absolute;
                width: 18rem;
                z-index: 2;
                margin: 2rem;
                top: 0;
                left: 0;
                -webkit-box-shadow: 5px 5px 5px 1px rgba(0, 0, 0, 0.4);
                box-shadow: 5px 5px 5px 1px rgba(0, 0, 0, 0.4);
                @include breakpoint(medium) {
                    width: 32.5rem;
                    display: flex;
                    gap: 2rem;
                    align-items: center;
                }
                &.--right {
                    left: auto;
                    right: 0;
                }
                &.--bottom {
                    top: auto;
                    bottom: 0;
                }
                .image__holder {
                    @include breakpoint(medium) {
                        width: 10.4rem;
                    }
                    img {
                        aspect-ratio: 1;
                        object-fit: scale-down;
                        width: 100%;
                        height: 100%;
                    }
                }
                .item__details {
                    a:not(.--button) {
                        font-size: 1.2rem;
                        line-height: 1.6rem;
                        display: block;
                        color: color(base, DefaultFontColor);
                    }
                    .price__holder {
                        display: flex;
                        gap: .5rem;
                        span {
                            display: block;
                            line-height: 2.8rem;
                            font-size: 1.3rem;
                            &.price__old-price {
                                text-decoration: line-through;
                            }
                            &.price {
                                font-weight: 700;
                            }
                        }
                    }
                    .--button {
                        line-height: 2.6rem;
                        height: 2.8rem;
                        font-size: 1.3rem;
                        padding: 0 3rem 0 1.5rem;
                        @include breakpoint(medium) {
                            line-height: 3rem;
                            height: 3.2rem;
                        }
                    }
                }
            }
        }
    }
    > a {
        position: absolute;
        right: .5rem;
        bottom: .5rem;
        @include breakpoint(medium) {
            right: 2rem;
            bottom: 2rem;
        }
    }
}

@keyframes breathing {
    0% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }

    25% {
        -webkit-transform: scale(1.15);
        -ms-transform: scale(1.15);
        transform: scale(1.15);
    }

    50% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }

    75% {
        -webkit-transform: scale(1.15);
        -ms-transform: scale(1.15);
        transform: scale(1.15);
    }

    100% {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }
}
