.tw__recommendation-container {

    .tw__recommendation-row {
        position: relative;

        h3 {
            font-family: 'True North Textures', sans-serif;
            font-size: 2.4rem;
            margin: 2rem 0;
            text-transform: uppercase;
            font-weight: 500;
            line-height: 3rem;
            @include breakpoint(small only) {
                font-size: 2.1rem;
                line-height: 2.6rem;
                margin: 0 0 1.6rem;
                text-align: center;
            }
        }

        .tw__recommendation-product__row {
            margin: 0 -1rem 2rem;

            .slider-controls {
                top:17rem;
                @include breakpoint(small only) {
                    top:10rem;
                }
                justify-content: space-between;
                width: 100%;
                button{
                    background: hsla(0,0%,100%,.8) url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M.705 12.962a.889.889 0 0 1 0-1.257L5.41 7 .705 2.295a.889.889 0 1 1 1.257-1.257l5.333 5.333a.889.889 0 0 1 0 1.257l-5.333 5.334a.889.889 0 0 1-1.257 0Z" fill="%23000"/></svg>') 50%/1.6rem no-repeat;
                    background-size: .7rem 1.2rem;
                    border: .1rem solid color(gray, Gray-100);
                    border-radius: 10rem;
                    box-shadow: 0 .4rem .8rem 0 rgba(0, 0, 0, .08);
                    &.blaze-prev {
                        transform: scaleX(-1);
                    }
                }
            }
            .blaze-product {
                padding: 0 0 .1rem;

                > div {
                    height: 100%;

                    > .product__tile {
                        height: auto;
                        line-height: 2.4;
                        padding: 1.2rem 2rem;

                        .product__holder {
                            display: flex;
                            flex-direction: column;
                        }
                    }
                }
            }
        }
    }
}

.page-builder__wrapper {
    .tweakwise-recommendation {
        margin: 4rem auto;
        @include breakpoint(small only) {
            margin: 4rem 1rem;
        }
    }
}

.catalog-product-page {
    .tweakwise-recommendation {
        h3 {
            border-top: .1rem solid color(gray, Gray-100);
            padding-top: 2rem;
        }

        &.off-canvas {
            margin: 0 auto 2rem;

            h3 {
                border: none;
                padding: 0;
            }
        }
    }
}

#addToCart-offCanvas {
    .tweakwise-recommendation {
        @include breakpoint(medium down) {
            margin: 0;
            padding: 0;
        }
    }

    .tw__recommendation-container {
        margin: 0;
    }

    .swiper-slide {
        width: 45%;

        .product__tile {
            h2 {
                font-size: 1.4rem;
            }
        }
    }

    .product__list {
        .product__holder {
            display: flex;
            gap: 1rem;
            border-bottom: .1rem solid color(gray, Gray-100);
            padding: 1rem 0;

            .image__holder {
                flex-shrink: 1;
                aspect-ratio: 1;
                padding: .5rem;

                img {
                    display: flex;
                    width: 9.5rem;
                    height: 9.5rem;
                }
            }

            .item__details {
                flex: 1;

                .--title {
                    display: block;
                    line-height: 2rem;
                    font-size: 1.4rem;
                }

                .price__holder {
                    .old__price {
                        margin: 1rem 0 0;
                        display: block;
                        line-height: 1.6rem;
                        font-size: 1.6rem;
                        text-decoration: line-through;
                    }

                    .price {
                        display: block;
                        line-height: 2rem;
                        font-size: 1.6rem;
                        font-weight: 700;
                    }
                }
            }

            .action__holder {
                align-self: flex-end;

                .add-to-cart {
                    justify-content: flex-end;

                    button {
                        width: 3.2rem;
                        height: 3.2rem;
                        background-color: color(base, Primary);
                        background-image: url("data:image/svg+xml,%3Csvg width='25' height='29' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M7.971 10.65V4.098c0-1.103.954-2.487 2.118-3.075 1.165-.588 3.011-.468 4.104.267 1.09.736 2.164 2.12 2.382 3.076.218.955.396 2.761.396 4.011v2.273' stroke='%231E1E1C' stroke-width='1.3' stroke-linecap='round'/%3E%3Cpath stroke='%23020203' stroke-width='2' d='m3.333 6.445-.941 2.167-.726 8.186L1 25.278v2.667h22.666l-2-21.5H14z'/%3E%3Cpath d='M9.11 10.364a1.139 1.139 0 1 1-2.278-.002 1.139 1.139 0 0 1 2.278.002' fill='%23000'/%3E%3Cpath d='M18.11 10.364a1.139 1.139 0 1 1-2.278-.002 1.139 1.139 0 0 1 2.278.002' fill='%23020203'/%3E%3C/g%3E%3C/svg%3E");
                        background-size: 1.5rem;
                        background-repeat: no-repeat;
                        background-position: center;
                        border-radius: 2rem;
                        @include breakpoint(medium) {
                            &:hover {
                                background-color: color(base, PrimaryHover);
                            }
                        }

                        &.--loading-cart {
                            background-image: url('../assets/images/loader.svg');
                            background-color: color(gray, Gray-100);
                            cursor: not-allowed;
                        }
                    }
                }
            }
        }

        .stock__message {
            font-size: 1.2rem;
            color: color(base, Tertiary);
            @include breakpoint(medium) {
                font-size: 1.3rem;
            }

            &.--stock-low {
                color: color(alert, Warning);
            }

            &.--out-of-stock {
                color: color(alert, Error);
            }
        }
    }
}
