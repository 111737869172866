.product__day-deal__container {
    @include border-radius();
    border: .1rem solid color(gray, Gray-100);
    height: 100%;
    padding: 2rem 0;
    @include breakpoint(small only) {
        padding: 1rem 0;
    }

    > a {
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;

        .label__holder {
            position: absolute;
            top: 2rem;
            left: 2rem;

            @include breakpoint(small only) {
                top: -.5rem;
                left: -.5rem;
                scale: .75;
            }
        }

        picture {
            display: flex;

            @include breakpoint(large) {
                padding-left: 20%;
                padding-bottom: 12%;
            }
            @include breakpoint(medium only) {
                padding-left: 20%;
                padding-bottom: 20%;
            }
            @include breakpoint(small only) {
                max-height: 20rem;
            }

            img {
                width: initial;
                height: initial;
                @include breakpoint(small only) {
                    max-height: 20rem;
                }
            }
        }

        .--bottom {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            padding: 0 2rem 2rem;
            @include breakpoint(small only) {
                padding: 0 2rem 0rem;
                align-items: center;
            }
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            flex: 1;

            @include breakpoint(medium) {
                position: absolute;
                width: initial;
                flex: initial;
            }

            .--content {
                span {
                    display: block;

                    &.--title {
                        font-weight: 700;
                        font-size: 1.2rem;

                        @include breakpoint(medium) {
                            font-size: 1.4rem;
                        }
                    }

                    &.product__title {
                        font-family: 'True North Textures', sans-serif;
                        font-size: 3.2rem;
                        line-height: 1;

                        @include breakpoint(medium) {
                            font-size: 4rem;
                        }
                    }
                }
            }

            > span {
                flex: 0 0 4rem;

                @include breakpoint(medium) {
                    flex: 0 0 5.6rem;
                }
            }
        }
    }
}
