/* Override default */
$grid__max-width: 125.8rem;
$grid__gutter: 1rem;
$grid__max-padding: 1rem;

$grid__breakpoints: (
    '': 0,
    small: 576,
    medium: 768,
    between-medium-small: 941,
    large: 1025,
    between-large-medium: 1100,
    xlarge: 1200,
    xxlarge: 1440,
) !default;

$color-palette: (
    base: (
        White: #FFF,
        Black: #000,
        DefaultFontColor: #000,
        DarkFontColor: #1d1d1b,
        Primary: #FFD700,
        PrimaryHover: darken(#FFD700, 5%),
        Secondary: #EC008C,
        Tertiary: #0CBAC4,
        TertiaryHover: #0ba7b0,
        Placeholder: #FAFAFA,
        Green: #00c853,
        Gray: #EEE8E2,
        Red: #EB001B,
        Orange: #FF9D0E
    ),
    gray: (
        Gray-soft: #f4f3f3,
        Gray-100: #EAE7E8,
        Gray-150: #EAE7DE,
        Gray-200: #D7D1D3,
        Gray-300: #A59B9E,
        Gray-400: #979797,
        Gray-500: #5A5A5A,
        Gray-600: #4a4a4a,
    ),
    alert: (
        Error: #CC4B37,
        Warning: #9F6000,
        Success: #00C853,
        Neutral: #059,
    ),
    alert-backgrounds: (
        Error: #ffeeeb,
        Warning: #ffebcc,
        Success: #f0fff6,
        Neutral: #d7edff,
    )
);
