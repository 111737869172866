@keyframes marquee {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translatex(-100%);
    }
}

@keyframes marquee-desktop {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(calc(-100% - 1rem));
    }
}

@keyframes shake-input {
    0% {
        transform: translate(20px);
    }
    20% {
        transform: translate(-20px);
    }
    40% {
        transform: translate(10px);
    }
    60% {
        transform: translate(-10px);
    }
    80% {
        transform: translate(5px);
    }
    100% {
        transform: translate(0px);
    }
}
