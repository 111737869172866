.customer-service{
    background-color: color(base, White);
    padding:2.4rem;
    border-radius: 1.2rem;
    h4{
        font-size:1.5rem;
        line-height: 1.5rem;
        margin-bottom: .8rem;
        font-weight: bold;
    }
    p{
        font-size: 1.4rem;
        margin-bottom: 2rem;
    }
    ul{
        li{
            display:flex;
            background-color: color(gray, Gray-soft);
            border-radius: .8rem;
            padding:1.2rem;
            font-size:1.3rem;
            margin-bottom: .8rem;
            align-items: center;
            &:last-child{
                margin-bottom: 0;
            }
            span p{
                margin-bottom: 0;
            }
            a{
                font-size:1.3rem;
                text-decoration: underline;
                color: color(base, Tertiary);
            }
            .icon{
                background-size: contain;
                height: 2.4rem;
                width: 2.4rem;
                display: inline-block;
                background-repeat: no-repeat;
                background-position: center center;
                margin-right: .8rem;
            }
            .icon-phone{
                background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_885_1082)"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.17107 15.8285C12.0169 19.6743 15.5797 20.0954 16.6254 20.1339C17.8899 20.1804 19.1797 19.1483 19.7371 18.0906C18.8475 17.0465 17.6887 16.2367 16.419 15.3587C15.6707 16.107 14.7479 17.4973 13.5181 16.9999C12.8198 16.7175 11.0936 15.9226 9.58529 14.4143C8.07691 12.9059 7.28205 11.1797 6.99965 10.4815C6.50183 9.25062 7.89525 8.32614 8.64411 7.57728C7.76656 6.28659 6.9701 5.0982 5.92819 4.25322C4.85677 4.81371 3.81858 6.09372 3.86569 7.37414C3.90417 8.4198 4.32527 11.9827 8.17107 15.8285ZM16.5518 22.1325C15.1109 22.0795 11.0302 21.5161 6.75686 17.2427C2.48347 12.9693 1.92007 8.88864 1.86705 7.44769C1.78625 5.25223 3.46805 3.11904 5.41166 2.28607C5.86772 2.09061 6.42011 2.12638 6.85679 2.44491C8.46442 3.61758 9.57357 5.39533 10.5261 6.78733C10.9319 7.38039 10.8649 8.18492 10.3497 8.70014L8.99329 10.0565C9.3081 10.7518 9.95037 11.9509 10.9995 13C12.0486 14.0492 13.2477 14.6914 13.943 15.0063L15.2987 13.6505C15.8144 13.1349 16.6224 13.0669 17.2168 13.4787C18.6368 14.4625 20.3045 15.556 21.5205 17.1133C21.862 17.5507 21.9128 18.1227 21.7091 18.5981C20.8723 20.5507 18.7547 22.2136 16.5518 22.1325Z" fill="%23151A1F"/></g><defs><clipPath id="clip0_885_1082"><rect width="24" height="24" fill="white"/></clipPath></defs></svg>');
            }
            .icon-email{
                background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M21.75 6.75V17.25C21.75 17.8467 21.5129 18.419 21.091 18.841C20.669 19.2629 20.0967 19.5 19.5 19.5H4.5C3.90326 19.5 3.33097 19.2629 2.90901 18.841C2.48705 18.419 2.25 17.8467 2.25 17.25V6.75M21.75 6.75C21.75 6.15326 21.5129 5.58097 21.091 5.15901C20.669 4.73705 20.0967 4.5 19.5 4.5H4.5C3.90326 4.5 3.33097 4.73705 2.90901 5.15901C2.48705 5.58097 2.25 6.15326 2.25 6.75M21.75 6.75V6.993C21.75 7.37715 21.6517 7.75491 21.4644 8.0903C21.2771 8.42569 21.0071 8.70754 20.68 8.909L13.18 13.524C12.8252 13.7425 12.4167 13.8582 12 13.8582C11.5833 13.8582 11.1748 13.7425 10.82 13.524L3.32 8.91C2.99292 8.70854 2.72287 8.42669 2.53557 8.0913C2.34827 7.75591 2.24996 7.37815 2.25 6.994V6.75" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
            }
            .icon-customer-service{
                background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none"><g clip-path="url(%23clip0_2531_110)"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.83325 10.5C5.83325 5.98966 9.4896 2.33331 13.9999 2.33331C18.5103 2.33331 22.1666 5.98966 22.1666 10.5V11.7079C24.1454 11.9911 25.6666 13.6929 25.6666 15.75V16.0416C25.6666 18.1357 23.969 19.8333 21.8749 19.8333C21.8427 19.8333 21.8108 19.8326 21.779 19.8311C20.9152 22.3043 18.7207 23.9711 16.3506 24.3942C15.8031 24.5942 15.1536 24.5 14.5833 24.5C13.6168 24.5 12.8333 23.7164 12.8333 22.75C12.8333 21.7835 13.6168 21 14.5833 21H15.7499C16.327 21 16.8388 21.2793 17.1575 21.71C18.6707 20.9905 19.8333 19.4699 19.8333 17.5V10.5C19.8333 7.27832 17.2216 4.66665 13.9999 4.66665C10.7783 4.66665 8.16659 7.27832 8.16659 10.5V17.7916C8.16659 18.9192 7.2525 19.8333 6.12492 19.8333C4.03083 19.8333 2.33325 18.1357 2.33325 16.0416V15.75C2.33325 13.6929 3.85441 11.9911 5.83325 11.7079V10.5ZM5.83325 14.0996C5.15355 14.3398 4.66659 14.988 4.66659 15.75V16.0416C4.66659 16.7471 5.1676 17.3357 5.83325 17.4708V14.0996ZM22.1666 14.0996V17.4708C22.8323 17.3357 23.3333 16.7471 23.3333 16.0416V15.75C23.3333 14.988 22.8463 14.3398 22.1666 14.0996Z" fill="%23151A1F"/></g><defs><clipPath id="clip0_2531_110"><rect width="28" height="28" fill="white"/></clipPath></defs></svg>');
            }
            .service-item__text{
                font-size:1.3rem;
                flex:1;
            }
            .availability-status{
                font-size: .9rem;
                border-radius: .4rem;
                background-color: color(gray, Gray-200);
                color:color(base, Black);
                padding:.4rem;
                text-transform: uppercase;
                font-weight: 600;
                display:none;
                &.--open{
                    display:block;
                    background-color: color(base, Green);
                    color: color(base, White);
                    .availability-text--closed {
                        display:none;
                    }
                }
                &.--closed{
                    display:block;
                    background-color: color(base, Red);
                    color: color(base, White);
                    .availability-text--open{
                        display:none;
                    }
                }
            }
        }
    }
}
