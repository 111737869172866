.header__menu {
    display: flex;
    align-items: center;
    padding: 0 0 1rem;
    border-bottom: .1rem solid color(gray, Gray-100);
    position: relative;
    > nav ul li > a, button {
        display: block;
        font-weight: 700;
        font-size: clamp(1.2rem, 1vw, 1.5rem);
        color: color(base, DarkFontColor);
        text-transform: uppercase;
        padding: .5rem 0;
        &:is(a):hover {
            background-image: url("data:image/svg+xml,%3Csvg width='51' height='2' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M34.812.041a95.924 95.924 0 0 0-2.901.02c-.38.008-.71.017-1.054.027-.153-.005-.288-.012-.455-.015a110.186 110.186 0 0 0-4.534.039c-.53.012-1.49.03-2.558.049a194.567 194.567 0 0 0-3.466-.01c-1.068.009-2.188.072-3.373.138-.96.054-1.953.11-2.83.132C13.564.419 13.35.4 13.21.387c-.456-.04-1.023-.09-1.695-.071-6.548.188-8.68.464-9.37.675-1.38.15-2.169.312-2.095.543a.105.105 0 0 0 .007.016L0 1.563l.068.001c.243.386 2.832.44 4.37.436 1.512-.005 3.613-.087 5.837-.173l.306-.012c.751.016 1.566.021 2.497 0a77.935 77.935 0 0 0 2.982-.124c.36-.023.662-.049.939-.077.13.005.253.012.392.017.736.025 2.52.036 4.373.034 2.744.07 7.06.059 9.32.03.525-.008 1.046-.03 1.55-.05.38-.015.768-.031 1.14-.04.806-.015 1.614-.029 2.395-.041 2.092-.035 3.9-.064 5.185-.126.257-.012.52-.027.783-.043 3.925.074 6.078.068 7.269.008.544.03.952.013 1.098-.11.308-.064.405-.143.461-.234.123-.198.328-.53-7.32-.841l-5.42-.16A207.91 207.91 0 0 0 35.027 0c-.153-.002-.204.016-.215.041' fill='%231A1919' fill-rule='evenodd'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: bottom center;
        }
    }
    button {
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: .5rem 1rem;
        line-height: 2.4rem;
        margin: 0 0 0 -1rem;
        border: .1rem solid transparent;
        background: color(base, White);
        z-index: 2;
        position: relative;
        &::after {
            content: '';
            @include icon('chevron-down-black', center);
            background-size: 1.8rem;
            display: block;
            width: 2.4rem;
            height: 2.4rem;
            border-radius: 1.2rem;
            background-color: color(base, Primary);
        }
        &:hover {
            border-color: color(gray, Gray-100);
        }
        &.--active {
            border-color: color(gray, Gray-100);
            border-bottom-color: transparent;
            &::after {
                @include icon('chevron-up-black', center);
            }
        }
    }
    .main__navigation button{
        width: 21.5rem;
    }
    > nav {
        margin-left: auto;
        ul {
            display: flex;
            gap: 5rem;
            li.--special a {
                color: color(base, Secondary);
                &:hover {
                    background-image: url("data:image/svg+xml,%3Csvg width='51' height='2' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M34.812.041a95.924 95.924 0 0 0-2.901.02c-.38.008-.71.017-1.054.027-.153-.005-.288-.012-.455-.015a110.186 110.186 0 0 0-4.534.039c-.53.012-1.49.03-2.558.049a194.567 194.567 0 0 0-3.466-.01c-1.068.009-2.188.072-3.373.138-.96.054-1.953.11-2.83.132C13.564.419 13.35.4 13.21.387c-.456-.04-1.023-.09-1.695-.071-6.548.188-8.68.464-9.37.675-1.38.15-2.169.312-2.095.543a.105.105 0 0 0 .007.016L0 1.563l.068.001c.243.386 2.832.44 4.37.436 1.512-.005 3.613-.087 5.837-.173l.306-.012c.751.016 1.566.021 2.497 0a77.935 77.935 0 0 0 2.982-.124c.36-.023.662-.049.939-.077.13.005.253.012.392.017.736.025 2.52.036 4.373.034 2.744.07 7.06.059 9.32.03.525-.008 1.046-.03 1.55-.05.38-.015.768-.031 1.14-.04.806-.015 1.614-.029 2.395-.041 2.092-.035 3.9-.064 5.185-.126.257-.012.52-.027.783-.043 3.925.074 6.078.068 7.269.008.544.03.952.013 1.098-.11.308-.064.405-.143.461-.234.123-.198.328-.53-7.32-.841l-5.42-.16A207.91 207.91 0 0 0 35.027 0c-.153-.002-.204.016-.215.041' fill='#{color(base, Secondary)}' fill-rule='evenodd'/%3E%3C/svg%3E");
                }
            }
        }
    }
}

.off-canvas__container {
    .menu {
        overflow-x: hidden;
        height: calc(100vh - 4.1rem);
        > ul {
            border-bottom: .1rem solid color(gray, Gray-100);
            padding: .5rem 0;
            margin: .5rem 0;
            button {
                margin: 1rem 0 0;
                font-size: 14px;
                padding: 0 1.5rem 0 3.5rem;
                line-height: 3.2rem;
                display: block;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m15 19-7-7 7-7'/%3E%3C/svg%3E");
                background-position: left 1rem center;
                background-repeat: no-repeat;
                background-size: 1.8rem;
            }
            a {
                border: none;
                font-size: 14px;
                line-height: 3.2rem;
                padding: 0 1.5rem;
                font-weight: normal;
            }
            li.--active a {
                font-weight: 700;
                pointer-events: none;
                color: color(base, DefaultFontColor);
            }
            li.is-drilldown-submenu-parent {
                > a {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m9 5 7 7-7 7'/%3E%3C/svg%3E");
                    background-position: right 1rem center;
                    background-repeat: no-repeat;
                    background-size: 1.8rem;
                }
            }
            &.cms-navigation {
                a {
                    font-size: 1.2rem;
                }
            }
        }
        .country__switch--mobile {
            ul li {
                a {
                    border: none;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m9 5 7 7-7 7'/%3E%3C/svg%3E");
                    font-size: 1.2rem;
                    gap: .5rem;
                    line-height: 4rem;
                    background-position: right 1rem center;
                    background-repeat: no-repeat;
                    background-size: 1.8rem;
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
}
