.dropdown__element {
    @include icon('chevron-down-black', 'right');
    position: relative;
    font-size: 1.6rem;
    cursor: pointer;
    z-index: 2;
    > span {
        display: flex;
        align-items: center;
        padding: 0 2rem 0 0;
        min-width: 6.5rem;
        > span {
            line-height: normal;
        }
    }
    > ul {
        display: none;
        position: absolute;
        top: 100%;
        right: 0;
        left: 0;
        li a {
            display: flex;
            align-items: center;
        }
    }
    &:hover > ul {
        display: block;
    }
}
