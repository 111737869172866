.breadcrumbs {
    overflow: hidden;

    ol {
        display: flex;
        align-items: center;

        li {
            display: flex;
            align-items: center;
            line-height: 4rem;
            @include breakpoint(medium) {
                line-height: 6rem;
            }

            a, span {
                display: block;
                font-weight: normal;
                font-size: 1.4rem;
                white-space: nowrap;
                line-height: inherit;
                .--prefixed {
                    display: flex;
                }
            }

            .--sep {
                display: flex;
                margin: 0 .8rem;
                top: 0.1rem;
                position: relative;

                @include breakpoint(small only) {
                    text-indent: -99999rem;
                    width: 1.8rem;
                    height: 1.8rem;
                    background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='17.5' y='17.5' width='17' height='17' rx='8.5' transform='rotate(180 17.5 17.5)' fill='white'/%3E%3Crect x='17.5' y='17.5' width='17' height='17' rx='8.5' transform='rotate(180 17.5 17.5)' stroke='black'/%3E%3Cpath d='M10.25 12.2953L9.76484 12.75L5.75 9L9.76484 5.25L10.25 5.70234L6.72266 9L10.25 12.2953Z' fill='black'/%3E%3C/svg%3E%0A");
                    background-repeat: no-repeat;
                    background-position: left center;
                    margin: 0 1rem 0 .5rem;
                }
            }
        }
    }
}
