.checkout-checkout_body .checkout-checkout .checkout-container .checkout-step-container {
    .shipping__method__options {
        margin: 0;
        padding:0;
        position: relative;
        .--loading {
            position: absolute;
            height: auto;
        }
        .vue-map-container {
            height: 28rem;
            margin: 0 0 1rem;
            @include breakpoint(medium) {
                height: 100%;
                margin: 0;
            }
        }
        .pick-up-point__details {
            display: flex;
            flex-flow: column;
            gap: 1rem;
            .--selected {
                padding: 1.5rem;

                @include breakpoint(small only) {
                    margin: 0 0 2rem;
                    padding: 0;
                }

                address {
                    margin: 0 0 1rem;
                }
                address, ul {
                    font-size: 1.2rem;
                    line-height: 2rem;
                    li {
                        display: flex;
                        span {
                            display: block;
                            &:first-child {
                                width: 30%;

                                @include breakpoint(medium) {
                                    width: 50%;
                                }
                            }
                        }
                    }
                }
            }
        }
        .postcode-search__fields {
            display: flex;
            margin: 0 0 1rem;
            input {
                flex: 1;
                padding: 0 1rem;
                font-size: 1.3rem;
                height: 4.8rem;
            }
            button {
                height: 4rem;
                width: 4rem;
                padding: 0;
            }
        }
        .pick-up-points__address{
            display: flex;
            align-items: center;
            address{
                flex:1;
                .address__top{
                    display: flex;
                    justify-content: space-between;
                }
            }
            button{
                height:4.8rem;
                width:4.8rem;
                margin-right: 1.6rem;
            }
        }
        .pick-up__distance{
            font-size:.9rem;
            background-color: color(gray, Gray-150);
            border-radius: .4rem;
            padding: 0.4rem;
            font-weight: 600;
            vertical-align: text-bottom;
            height: 1.8rem;
            display: inline-flex;
            align-items: center;
        }
        .pick-up__selected{
            color:color(base, White);
            background-color: color(base, Black);
            text-transform:uppercase;
            font-size:.9rem;
            height: 1.8rem;
            border-radius: .4rem;
            font-weight: 600;
            display: flex;
            padding:0 .4rem;
            align-items: center;
            margin-right:.4rem;
        }
        .pick-up-points__result {

            &.--large {
                max-height: 50rem;
            }
            > li {
                padding: 2rem .8rem 2rem 5.2rem;
                border-bottom: .1rem solid color(gray, Gray-150);

                @include breakpoint(small only){
                    padding: 2rem 0 2rem 4.2rem;

                }
                &.--active{
                    background-color:color(gray, Gray-150);
                    .pick-up__distance{
                        background-color: color(base, White);
                    }
                }
                address {
                    font-size: 1.4rem;
                    line-height: 2.4rem;
                    background-repeat: no-repeat;
                    background-size: 3rem;
                    background-position: right 1rem center;
                    margin-bottom: 1.2rem;
                    @include breakpoint(medium){
                        padding: .5rem 0;
                    }
                    strong {
                        margin-right:.8rem;

                        font-size: 1.5rem;
                        font-weight: 600;
                        @include breakpoint(small only){
                            font-size:1.4rem;
                        }
                    }

                }

            }
        }

    }
}
body.checkout-checkout_body .checkout-checkout .checkout-container .shipping__methods .shipping__method.--active.--pickup-post-nl {
    .shipping__method__header{
        border-bottom:.1rem solid color(base, White);
        .shipping__label{
            margin-bottom: 1.2rem;
        }
        .pickuppoint__title{
            padding-left:3rem;
            font-size: 1.3rem;
            display:block;
            margin-bottom: .8rem;
        }
        .postcode-search__fields{
            display: flex;
            gap:.8rem;

            input{
                border-radius: 10rem;
                height: 4.8rem;
                border:none;
                flex: 1;
            }
        }
    }
    .postcode-search__fields{
        padding-left:3rem;
        @include breakpoint(small only){
            button{
                flex: 0 0 4.8rem;
            }
            input{
                padding:0;
                &::placeholder{
                    padding-left:2rem;
                    font-size:1.3rem;
                }
            }
        }
        button{
            width: 4.8rem;
            height: 4.8rem;
        }
    }
    .shipping__method__options {
        padding: 0;
    }
}

.pick-up-point__wrapper{
    .pick-up-point__show-more{
        color:color(base, Tertiary);
        font-size:1.3rem;
        text-decoration: underline;
        display:block;
        margin:auto;
    }
    @include breakpoint(small only){
        .pick-up-point__show-more{
            margin: 1.2rem auto;
        }
    }
}
