@use "sass:math";

.product__slider {
    div > section > section {
        @include breakpoint(medium) {
            display: flex;
            align-items: center;
        }
        // .--navi {
        //     margin-top: -10rem;
        // }
        .swiper-container {
            @include breakpoint(small only) {
                margin-right: -1rem;
            }
            .swiper-slide {
                width: 40%;
                @include breakpoint(medium) {
                    width: math.div(100%, 5);
                }
                .product__tile {
                    color: $default-font-color;
                }
                .product__holder h2{
                    border: none;
                    text-transform: none;
                    font-family: inherit;
                    font-size: 1.6rem;
                    line-height: 2.6rem;
                }
            }
        }
    }
}
