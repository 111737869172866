.ui__label {
    position: relative;
    width: 12rem;
    height: 12rem;

    .sub__label {
        position: absolute;
        z-index: 2;

        &.round {
            .sub__label__container {
                width: 6.4rem;
                height: 6.4rem;
            }

            &.top-right {
                top: -1.75rem;
                right: -2.5rem;

                @include breakpoint(small only) {
                    right: -3.8rem;
                    top: -3rem;
                }
            }

            &.top-left {
                top: -1.75rem;
                left: -2.5rem;

                @include breakpoint(small only) {
                    left: -3.8rem;
                    top: -3rem;
                }
            }
        }

        &.rectangle {
            .sub__label__container {
                width: 10.5rem;
                height: 3.2rem;
                gap: .4rem;
                flex-direction: initial;
                transform: skewY(-3.5deg);
            }

            &.top-right {
                top: -1.2rem;
                right: -.4rem;
            }

            &.top-left {
                top: -1.2rem;
                left: -.4rem;
            }
        }

        .sub__label__container {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: .4rem;
            overflow: hidden;

            span {
                color: color(base, White);
                text-align: center;
            }
        }
    }

    .ui__label__container {
        padding: .4rem;
        overflow: hidden;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        .--middle {
            display: flex;
            justify-content: center;
            align-items: flex-start;
        }

        .old__price {
            position: relative;

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                right: 0;
                height: .2rem;
                background-color: Black;
                width: 100%;
                left: -5%;
                transform: rotate(-13deg) translateY(calc(50% - .2rem));
            }
        }
    }

    .--bold {
        font-weight: 700;
    }

    span {
        text-align: center;
        min-height: 1.4rem;
    }
}
