.catalog__banner {
    text-align: center;

    a {
        position: relative;
        display: inline-block;
        line-height: 0;

        &::after {
            transition: background-color .15s ease-in-out;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.0);
            z-index: 1;
        }
        &:hover::after {
            background-color: rgba(0, 0, 0, 0.3);
        }
    }
}
