



















































.zoom-animation-enter-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  transform: scale(1);
}

.zoom-animation-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.zoom-animation-enter, .zoom-animation-leave-to {
  transform: scale(2);
}
