.checkout__address-autocomplete {

    .checkout__address-autocomplete_result {
        margin: 2rem 0 1.8rem;
        position: relative;

        @include breakpoint(medium) {
            margin-right: 0;
        }
        label {
            line-height: 2rem;
            font-size: 1.3rem;
            text-decoration: underline;
            cursor: pointer;
        }
        .resolved__address {
            font-size: 1.3rem;
            background: color(gray, Gray-soft);
            padding: 1rem 1.5rem;
            span {
                font-weight: 700;
                line-height: 2rem;
                display: block;
                margin: 0 0 .5rem;
                &.--error {
                    font-weight: normal;
                    color: color(alert, Error);
                    margin: 0 0 1rem;
                }
            }
            address {
                line-height: 2rem;
            }
        }
    }
}
