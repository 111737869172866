.product__gallery-container {
    .product__gallery-images {
        display: flex;
        position: relative;
        align-items: center;
        gap: 2rem;
        @include breakpoint(small only) {
            gap: 1rem;
        }
        .mobile__wishlist {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 2;
        }
        .product__icons {
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            display: flex;
            flex-flow: column;
            gap: 1rem;
            @include breakpoint(small only) {
                img {
                    max-height: 4rem;
                    max-width: 4rem;
                    object-fit: scale-down;
                }
            }
        }
        .zoom__modal {
            position: absolute;
            z-index: 2;
            right: 0;
            bottom: 0;
            font-size: 1.3rem;
            color: color(gray, Gray-500);
            background-color: rgba(255, 255, 255, .9);
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='#{color(gray, Gray-500)}' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='m21 21-6-6m2-5a7 7 0 1 1-14 0 7 7 0 0 1 14 0zm-7-3v3m0 0v3m0-3h3m-3 0H7'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 3rem;
            cursor: zoom-in;
            line-height: normal;
            height: 3rem;
            width: 3rem;
            @include breakpoint(small only) {
                right: .5rem;
                bottom: -.5rem;
            }
            &.zoom__mobile {
                background-size: 1.8rem;
                line-height: 1.8rem;
                height: 1.8rem;
            }
        }
        .swiper-pagination {
            margin: 0;
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 4;
        }
        .swiper-wrapper {
            align-items: center;
            .swiper-slide {
                padding: .5rem;
                display: flex;
                justify-content: center;
                .product__image {
                    width: 100%;
                    @include breakpoint(medium) {
                        padding: 0 10%;
                    }
                    picture {
                        display: block;
                        cursor: zoom-in;
                        img {
                            aspect-ratio: 1;
                            object-fit: scale-down;
                            @include breakpoint(small only) {
                                max-height: 25rem;
                            }
                        }
                    }
                }
            }
        }
    }
    .product__gallery-thumbnails {
        margin: 2rem 0 0;
        display: flex;
        gap: 1rem;
        .swiper-slide > span, > span {
            position: relative;
            max-height: 8.8rem;
            max-width: 8.8rem;
            flex: 1;
            aspect-ratio: 1;
            padding: .5rem;
            display: flex;
            background-color: color(base, White);
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border: 1px solid color(gray, Gray-100);
            @include breakpoint(small only) {
                max-width: 6rem;
            }
            &:hover, &.--active {
                border: 1px solid color(gray, Gray-300);
            }
            .youtube__play {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='44' height='44' viewBox='0 0 24 24' stroke-width='1.2' stroke='%23ffffff' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Crect x='3' y='5' width='18' height='14' rx='4' /%3E%3Cpath d='M10 9l5 3l-5 3z' /%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: center;
            }
            picture {
                display: block;
                img {
                    aspect-ratio: 1;
                    object-fit: scale-down;
                    @include breakpoint(small only) {
                        max-width: 5rem;
                    }
                }
            }
            &.--extra {
                position: relative;
                cursor: zoom-in;
                > span {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: rgba(215, 209, 211, .1);
                    font-size: 3.6rem;
                    color: color(gray, Gray-600);
                    font-family: 'True North Textures', sans-serif;
                    z-index: 1;
                }
                picture {
                    opacity: .3;
                }
            }
        }
    }
    .modal__wrapper {
        height: 90vh;
        width: 90vw;
        .modal-content {
            display: flex;
            height: calc(100% - 3rem);
            .product__gallery-thumbnails {
                flex-shrink: 1;
                display: flex;
                flex-flow: column;
                align-items: center;
                justify-content: center;
                margin: 0 1rem 0 0;
                .swiper-container {
                    height: 49rem;
                    .swiper-slide {
                        height: 20%;
                    }
                }
                .--navi {
                    .--prev {
                        transform: rotate(90deg);
                    }
                    .--next {
                        transform: rotate(270deg);
                    }
                }
            }
            .product__gallery-images {
                flex: 1;
                min-width: 0;
                @include breakpoint(small only) {
                    width: 100%;
                }
                .swiper-container {
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    .swiper__image {
                        position: relative;
                        width: 100%;
                        height: 100%;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: contain;
                        touch-action: pan-x pinch-zoom;
                        @include breakpoint(small only) {
                            display: flex;
                            align-items: center;
                        }
                        .video-container {
                            top: 50%;
                            transform: translateY(-50%);
                            iframe {
                                max-height: 90vh;
                            }
                        }
                    }
                }
            }
        }
    }
}


.product__media-gallery {
    .--gallery {
        position: relative;
        @include breakpoint(medium) {
            margin-top: 2.4rem;
        }
        a {
            display: block;

            picture {
                width: 100%;
                padding: 0 10%;
                cursor: zoom-in;
                display: block;
                @include breakpoint(small only) {
                    padding: 0 5%;
                }
            }
        }
        .blaze-slider {
            @include absolute-button();
            .responsive__video {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            > button {
                &.blaze-next, &.blaze-prev {
                    background-color: color(base, White);
                }
            }
        }
        .mobile__wishlist {
            position: absolute;
            top: 0;
            right: 4px;
            z-index: 2;
        }
        .share__button {
            position: absolute;
            top: 5rem;
            right: 0;
            z-index: 2;
            &.--active {
                z-index: 10;
            }
            .product__share {
                > button {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(gray, Gray-600)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M7.217 10.907a2.25 2.25 0 100 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186l9.566-5.314m-9.566 7.5l9.566 5.314m0 0a2.25 2.25 0 103.935 2.186 2.25 2.25 0 00-3.935-2.186zm0-12.814a2.25 2.25 0 103.933-2.185 2.25 2.25 0 00-3.933 2.185z' /%3E%3C/svg%3E%0A");
                    background-color: color(gray, Gray-100);
                    background-position: 50%;
                    background-repeat: no-repeat;
                    background-size: 1.8rem;
                    border-radius: 50%;
                    display: block;
                    height: 3.5rem;
                    width: 3.5rem;
                }
                .modal__overlay {
                    @include breakpoint(small only) {
                        background: none;
                    }
                    .modal__wrapper {
                        height: auto;
                        @include breakpoint(medium) {
                            width: 40%;
                        }
                        .modal-header {
                            h4 {
                                font-size: 1.4rem;
                                font-weight: 700;
                                margin: 0 0 0.5rem;
                            }
                        }
                        .modal-content {
                            padding: 10px 10px 20px;
                            overflow-y: auto;
                            height: auto;
                            display: block;
                            p {
                                font-size: 1.3rem;
                            }
                            ._message {
                                margin: 1rem 0 0;
                            }
                            ._form {
                                margin: 10px 0 0;
                                display: flex;
                                flex-wrap: wrap;
                                input {
                                    display: block;
                                    flex: 1;
                                    border-radius: 4px 0 0 4px;
                                }
                                button {
                                    display: block;
                                    cursor: pointer;
                                    width: 3.2rem;
                                    height: 3.2rem;
                                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-5 w-5' viewBox='0 0 20 20' fill='%231D1D1F'%3E%3Cpath d='M9 2a2 2 0 00-2 2v8a2 2 0 002 2h6a2 2 0 002-2V6.414A2 2 0 0016.414 5L14 2.586A2 2 0 0012.586 2H9z' /%3E%3Cpath d='M3 8a2 2 0 012-2v10h8a2 2 0 01-2 2H5a2 2 0 01-2-2V8z' /%3E%3C/svg%3E");
                                    flex-shrink: 1;
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    background-size: 22px;
                                    border: 1px solid color(grey, Gray-100);
                                    border-left: 0;
                                    border-radius: 0 4px 4px 0;

                                    @include breakpoint(medium) {
                                        width: 4rem;
                                        height: 4rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .--thumbnails {
        position: relative;
        margin-top: 5.6rem;
        @include breakpoint(small only) {
            margin-top: 1.6rem;
        }
        .blaze-track {
            transform: none !important;
        }
        .--thumb {
            border: 1px solid color(gray, Gray-100);
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            padding: 5px;
            position: relative;
            max-height: 8.8rem;
            max-width: 8.8rem;
            aspect-ratio: 1;
            flex: none;
            border-radius: .8rem;
            @include breakpoint(small only) {
                border-radius: 0;
                max-width: 6.2rem;
            }
            &:hover, &.--active {
                border: 1px solid color(base, Black);
            }
            .youtube__play {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='44' height='44' viewBox='0 0 24 24' stroke-width='1.2' stroke='%23ffffff' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Crect x='3' y='5' width='18' height='14' rx='4' /%3E%3Cpath d='M10 9l5 3l-5 3z' /%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: center;
            }
            &.--more-overlay {
                position: relative;
                overflow: hidden;

                .more-overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(0, 0, 0, 0.5);
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .more-count {
                        color: white;
                        font-size: 2.6rem;
                        font-weight: bold;
                        @include breakpoint(small only) {
                            font-size: 2rem;
                        }
                    }

                    .more-count-mobile {
                        display: none;
                    }

                    .more-count-desktop {
                        display: block;
                    }
                }

                @include breakpoint(small only) {
                    .more-overlay {
                        .more-count-mobile {
                            display: block;
                        }
                        .more-count-desktop {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    .zoom__container {
        display: grid;
        justify-content: center;
        margin-top: -2.4rem;
        margin-bottom: 3.4rem;
        .zoom__link {
            align-items: center;
            display: flex;
            justify-content: center;
            position: relative;
            height: 3.2rem;
            width: 10.7rem;
            background-color: color(base, White);
            background: rgba(255, 255, 255, 0.7);
            border-top: 1px solid #eae7de;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
            border-radius: 100px;
            font-size: 1.2rem;
            padding-left: 1.2rem;
            margin-top: -4rem;
            @include breakpoint(small only) {  
                margin-top: -2rem;
            }
            &:before {
                content: "";
                background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_44_2175)'%3E%3Cpath d='M7.00004 1.33331C10.1296 1.33331 12.6667 3.87037 12.6667 6.99998C12.6667 8.24666 12.2641 9.39927 11.5819 10.335L11.4507 10.5078L13.8856 12.9428C14.146 13.2031 14.146 13.6252 13.8856 13.8856C13.6453 14.1259 13.2672 14.1444 13.0056 13.941L12.9428 13.8856L10.5079 11.4506C9.54304 12.2122 8.32464 12.6666 7.00004 12.6666C3.87043 12.6666 1.33337 10.1296 1.33337 6.99998C1.33337 3.87037 3.87043 1.33331 7.00004 1.33331ZM7.00004 2.66665C4.60681 2.66665 2.66671 4.60675 2.66671 6.99998C2.66671 9.39325 4.60681 11.3333 7.00004 11.3333C9.39331 11.3333 11.3334 9.39325 11.3334 6.99998C11.3334 4.60675 9.39331 2.66665 7.00004 2.66665ZM7.00004 3.99998C7.34194 3.99998 7.62371 4.25734 7.66222 4.5889L7.66671 4.66665V6.33331H9.33337C9.70157 6.33331 10 6.63179 10 6.99998C10 7.34188 9.74269 7.62365 9.41112 7.66216L9.33337 7.66665H7.66671V9.33331C7.66671 9.70151 7.36824 9.99998 7.00004 9.99998C6.65815 9.99998 6.37637 9.74263 6.33786 9.41106L6.33337 9.33331V7.66665H4.66671C4.29852 7.66665 4.00004 7.36818 4.00004 6.99998C4.00004 6.65809 4.2574 6.37631 4.58896 6.3378L4.66671 6.33331H6.33337V4.66665C6.33337 4.29846 6.63185 3.99998 7.00004 3.99998Z' fill='%23151A1F'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_44_2175'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
                position: absolute;
                left: 1.2rem;
                top: 50%;
                transform: translateY(-50%);
                width: 1.6rem;
                height: 1.6rem;
                background-repeat: no-repeat;
            }
        }
    }
}

.bzfy-t-feed-carousel .bzfy-c-carousel-post--minimalistic {
    border-radius: 2rem !important;
}
.bzfy-feed-gate-modal.bzfy-feed-gate-modal--visible {
    border-radius: 2rem !important;
    .bz-gate-media {
        border-radius: 2rem 0 0 2rem !important;
    }
    #bz-gate-content {
        border-radius: 0 2rem 2rem 0 !important;
    }
}
