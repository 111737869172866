.coupon-code__container {
    background-color: color(base, White);
    border-radius: 1.2rem;
    margin-bottom: 2rem;
    @include breakpoint(small only){
        margin-bottom: 2rem;
    }
    .coupon-code__header {
        cursor: pointer;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m19 9-7 7-7-7'/%3E%3C/svg%3E");
        background-size: 2rem;
        background-repeat: no-repeat;
        background-position: right 1.5rem center;
        padding: 1.6rem 2rem;

        &.--active {
            span {
                font-weight: 700;
            }
        }

        span {
            font-size: 1.4rem;
        }
    }

    .coupon-code__form {
        padding: 0 2rem 1.6rem;

        .form-holder {
            display: flex;
        }

        input {
            border: 1px solid color(gray, Gray-150);
            height: 4.8rem;
            margin-right: .4rem;
            flex: 1;
            border-radius: 10rem;
            font-size:1.6rem;
        }

        button.--coupon {
            background-color: color(base, Primary);
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.704781 12.9619C0.357647 12.6147 0.357647 12.0519 0.70478 11.7048L5.40957 7.00001L0.70478 2.29521C0.357646 1.94808 0.357646 1.38527 0.70478 1.03813C1.05191 0.691002 1.61472 0.691001 1.96186 1.03813L7.29519 6.37147C7.64232 6.7186 7.64232 7.28141 7.29519 7.62855L1.96186 12.9619C1.61473 13.309 1.05191 13.309 0.704781 12.9619Z" fill="black"/></svg>');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: .8rem;
            padding: 0;
            height: 4.8rem;
            width: 4.8rem;
        }
    }
}

.cart__totals {
    padding: 2rem;
}
.checkout-checkout{
    .cart__totals{
        @include breakpoint(small only){
            margin-bottom: 0;
        }
    }
    .coupon-code__container {
        @include breakpoint(small only){
            margin-bottom: .8rem;
        }
    }
}
