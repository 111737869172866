.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .5rem 0;
    margin: auto 0 0;

    ul {
        display: flex;
        gap: 1.6rem;
        flex-wrap: wrap;

        li {
            &.--wide {
                margin: 0 .8rem;
            }
            &.--ellipsis {
                margin-left:.4rem;
                margin-right: .4rem;
                font-size: 1.3rem;
                display: flex;
                align-items: end;
                padding-bottom: .6rem;
            }
            span, a {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 4rem;
                height: 4rem;
                font-size: 1.6rem;
                cursor: pointer;
                border: 1px solid color(gray, Gray-200);
                border-radius: .4rem;
                @include breakpoint(medium) {
                    &:not(.--next):not(.--prev):hover {
                        border-color: color(base, Black);
                    }
                }
                &.--current {
                    pointer-events: none;
                    border:1px solid color(base, Black);
                }
                &.--next, &.--prev {
                    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="9" height="14" viewBox="0 0 9 14" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M1.2049 12.9618C0.85777 12.6147 0.85777 12.0519 1.2049 11.7047L5.9097 6.99995L1.2049 2.29515C0.85777 1.94802 0.857771 1.3852 1.2049 1.03807C1.55204 0.690939 2.11485 0.690939 2.46198 1.03807L7.79532 6.37141C8.14245 6.71854 8.14245 7.28135 7.79531 7.62849L2.46198 12.9618C2.11485 13.309 1.55203 13.309 1.2049 12.9618Z" fill="black"/></svg>');
                    background-color: color(gray, Gray-100);
                    border-radius: 10rem;
                    background-repeat: no-repeat;
                    background-position: center;
                    border: none;
                    &.--prev {
                        transform: scaleX(-1);
                    }
                }
            }
        }
        @include breakpoint(small only){
            gap: .6rem;
            li {
                span, a{
                    height: 3.6rem;
                    width: 3.6rem;
                    font-size:1.3rem;
                }
            }
        }
    }
}
.catalog-category__toolbar.--bottom{
    .pagination{
        margin-left:0;
    }
}
