.checkout-success {
    @include breakpoint(medium) {
        margin: 2rem 0 0;
    }
    h1 {
        font-size: 2.3rem;
        line-height: 3.2rem;
        @include breakpoint(medium) {
            font-size: 3rem;
            line-height: 3.8rem;
        }
    }
    .global__message--bg {
        font-size: 1.4rem;
        line-height: 3rem;
    }
    .order__addresses {
        margin: 3rem 0 0;
        h4 {
            font-weight: 700;
            font-size: 1.4rem;
            margin: 0 0 1rem;
        }
        .order__address {
            background: color(gray, Gray-soft);
            padding: 1rem 1.5rem;
            font-size: 1.4rem;
            line-height: 2.2rem;
            margin: 0 0 3rem;
            min-height: 13rem;
        }
    }
    .register__account {
        margin: 0 0 3rem;
        border: .2rem solid color(base, Black);
        padding: 1.5rem;
        @include breakpoint(medium) {
            padding: 3rem;
        }
        h4 {
            font-family: 'Gotham Rounded', sans-serif;
            font-size: 1.6rem;
            font-weight: 700;
            margin: 0 0 1rem;
            @include breakpoint(medium) {
                font-size: 2rem;
            }
        }
        p {
            margin: 0 0 2rem;
        }
        form {
            > span {
                display: block;
                margin: 0 0 1rem;
                input {
                    width: 100%;
                    @include breakpoint(medium) {
                        width: 40%;
                    }
                }
            }
            button {
                margin: 1rem 0 0;
            }
        }
    }
    .success__share {
        h2 {
            font-family: 'Gotham Rounded', sans-serif;
            font-size: 1.6rem;
            font-weight: 700;
            @include breakpoint(medium) {
                font-size: 2rem;
            }
        }
        .--product {
            display: flex;
            margin: 2rem 0 0;
            gap: 1rem;
            .image__holder {
                display: flex;
                padding: .5rem;
                border: .1rem solid color(gray, Gray-200);
            }
            .--info {
                padding: 2rem;
                flex: 1;
                > a {
                    display: block;
                    color: color(base, Tertiary);
                    font-size: 1.6rem;
                    font-weight: 700;
                    @include breakpoint(medium) {
                        &:hover {
                            color: color(base, TertiaryHover);
                        }
                    }
                }
                span {
                    display: block;
                    font-size: 1.2rem;
                    margin: .5rem 0 0;
                }
                ul {
                    display: flex;
                    margin: 1rem 0 0;
                    gap: 1rem;
                    li {
                        a {
                            display: block;
                            width: 4rem;
                            height: 4rem;
                            background: color(base, Black);
                            border-radius: 50%;
                            padding: .8rem;
                            i {
                                display: block;
                                width: 100%;
                                height: 100%;
                                background-size: cover;
                                &.fa-facebook-f {
                                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-brand-facebook' width='44' height='44' viewBox='0 0 24 24' stroke-width='1.5' stroke='White' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M0 0h24v24H0z' stroke='none'/%3E%3Cpath d='M7 10v4h3v7h4v-7h3l1-4h-4V8a1 1 0 0 1 1-1h3V3h-3a5 5 0 0 0-5 5v2H7'/%3E%3C/svg%3E");
                                }
                                &.fa-pinterest-p {
                                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-brand-pinterest' width='44' height='44' viewBox='0 0 24 24' stroke-width='1.5' stroke='White' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M0 0h24v24H0z' stroke='none'/%3E%3Cpath d='m8 20 4-9M10.7 14c.437 1.263 1.43 2 2.55 2 2.071 0 3.75-1.554 3.75-4a5 5 0 1 0-9.7 1.7'/%3E%3Ccircle cx='12' cy='12' r='9'/%3E%3C/svg%3E");
                                }
                                &.fa-whatsapp-w {
                                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-brand-whatsapp' width='44' height='44' viewBox='0 0 24 24' stroke-width='1.5' stroke='%23ffffff' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M3 21l1.65 -3.8a9 9 0 1 1 3.4 2.9l-5.05 .9' /%3E%3Cpath d='M9 10a0.5 .5 0 0 0 1 0v-1a0.5 .5 0 0 0 -1 0v1a5 5 0 0 0 5 5h1a0.5 .5 0 0 0 0 -1h-1a0.5 .5 0 0 0 0 1' /%3E%3C/svg%3E");
                                }
                            }
                        }
                        @include breakpoint(medium) {
                            &:hover {
                                &:nth-child(1) a {
                                    background-color: #3b5998;
                                }
                                &:nth-child(2) a {
                                    background-color: #e60023;
                                }
                                &:nth-child(3) a {
                                    background-color: #25d366;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .sidebar__right {
        .sidebar--right {
            display: none;
        }
        .--contact {
            ul li{
                font-size:1.4rem;
            }
            h3{
                font-size: 1.5rem;
                margin-top: 1.2rem;
                margin-bottom: 0.6rem;
                font-weight: bold;
            }
        }

    }
}
