.checkout__progress {
    display: flex;
    justify-content: space-between;
    gap: 1.8rem;
    margin-bottom: 7.2rem;
    @include breakpoint(small only){
        margin-bottom: 4.2rem;
    }

    &-item {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .progress-item__icon {
            height: 4.8rem;
            width: 4.8rem;
            border-radius: 10rem;
            position: relative;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 1.3rem;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            @include breakpoint(small only){
                width: 3.6rem;
                height: 3.6rem;
                background-size: 1.2rem;
            }

            &:after {
                content: '';
                position: absolute;
                display: block;
                height: 4.2rem;
                width: 4.2rem;
                top: .3rem;
                left: .3rem;
                border: .3rem solid color(base, White);
                border-radius: 10rem;
                @include breakpoint(small only){
                    height: 3rem;
                    width: 3rem;
                }
            }

            span {
                color: white;
                font-size: 1.6rem;
                font-weight: 700;
            }
        }

        &.--complete {
            .progress-item__icon {
                background-color: color(base, Green);
                background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M17.485 0.87655C18.0057 1.39725 18.0057 2.24146 17.485 2.76217L7.49117 12.756C6.9358 13.3113 6.0353 13.3113 5.47989 12.756L0.514428 7.79044C-0.00627417 7.26982 -0.00627417 6.42555 0.514428 5.90484C1.03512 5.38413 1.87934 5.38413 2.40005 5.90484L6.48555 9.99035L15.5994 0.87655C16.1201 0.355847 16.9643 0.355847 17.485 0.87655Z" fill="white"/></svg>');
                span{
                    display: none;
                }
            }
        }

        &.--active {
            .progress-item__icon {
                background-color: color(base, Black);
            }
            .progress-item__text span{
                font-weight: 700;
            }
        }

        &.--incomplete {
            .progress-item__icon {
                background-color: color(base, White);
                border: .2rem solid color(gray, Gray-400);

                span {
                    color: color(gray, Gray-400);
                }
                &:after {
                    content: none;
                }
            }

        }

        .progress-item__text {
            font-size: 1.3rem;
            line-height: 3.2rem;
            color: color(base, Black);
            text-align: center;
            position: absolute;
            bottom: -3.2rem;

            &.--active {
                span {
                    font-weight: 700;
                }
            }
        }
    }

    &-divider {
        display: block;
        height: .2rem;
        width: 100%;
        background-color: color(gray, Gray-200);
        margin-top:2.4rem;
        @include breakpoint(small only){
            margin-top: 1.7rem;
        }
    }
}
