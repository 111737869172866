.site__banner-item {
    display: block;
    position: relative;
    padding: 0 0 1rem;
    .site__banner-content {
        min-height: 5rem;
        display: flex;
        align-items: center;
        gap: 1rem;
        picture {
            display: flex;
            width: 100%;
            img {
                width: 100%;
            }
        }
        .--text {
            flex: 1;
            padding: 1rem 0;
            p {
                text-align: center;
            }
        }
        .--close {
            width: 2rem;
            height: 2rem;
            display: block;
            cursor: pointer;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='#{color(gray, Gray-500)}' stroke-width='3'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M6 18L18 6M6 6l12 12' /%3E%3C/svg%3E");
            @include breakpoint(medium) {
                &:hover {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='#{color(gray, Gray-600)}' stroke-width='3'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M6 18L18 6M6 6l12 12' /%3E%3C/svg%3E");
                }
            }
        }
        &.--image {
            .--close {
                position: absolute;
                right: 1rem;
            }
        }
        &:not(.--image) {
            padding: 0 1rem;
        }
    }
}
