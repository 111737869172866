.page-builder__intro-block-container {
    @include breakpoint(small only) {
        margin-bottom: 1.85rem;
    }

    .image__holder {
        @include border-radius();
        overflow: hidden;
        height: 100%;

        picture {
            display: flex;
            height: 100%;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
    }

    .--content {
        @include border-radius();
        height: 100%;
        padding: 3rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        @include breakpoint(small only) {
            margin-top: 1rem;
            padding: 1.5rem 2rem;
        }

        h1, h2 {
            font-size: 2.5rem;
        }

        > * {
            margin-right: auto;
        }
    }
}
