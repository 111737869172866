.catalog__header-wrapper {
    @include breakpoint(small only) {
        display: flex;
        flex-direction: column;
        .categories_list {
            margin-bottom: 1rem;
        }
        .catalog__header-container {
            margin-bottom: 0;
        }
    }

    .swiper-container {
        .swiper-slide {
            a {
                &.--active {
                    background-color: color(gray, Gray-100);
                }
            }
        }
    }
}

.catalog-category-page {
    .active__filter__container {
        @include breakpoint(small only) {
            background: color(base, White);
            left: 0;
            padding-bottom: 1rem;
            right: 0;
            top: 210px;
            width: 100%;
            z-index: 1;
            flex-wrap: wrap;
        }

        span {
            display: flex;
            align-items: center;
            font-size: 1.3rem;
            margin: 0 0 .5rem;
        }

        div {
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;
        }

        .active__filter__wrapper {
            margin-bottom: 2rem;
            @include breakpoint(small only) {
                height: 3.8rem;
                overflow: hidden;
                &.--expanded{
                    height: auto;
                    overflow: auto;
                }
            }
        }
    }

    .catalog__header-container {
        display: flex;
        gap: 2rem;
        margin-bottom: 1.6rem;
        @include breakpoint(small only) {
            margin-top: 2.4rem;
            margin-bottom: 0;
        }

        .catalog__header-container__image {
            position: relative;
            display: block;
            background-size: cover;
            background-position: center center;
            flex: 2;
            border-radius: 1.2rem;
            min-height: 20rem;
            @include breakpoint(small only) {
                display: none;
            }

            img {
                border-radius: 2rem;
            }

            span {
                bottom: 0;
                color: color(base, White);
                font-family: 'True North Textures', sans-serif;
                font-size: 6.5rem;
                left: 6rem;
                line-height: 9rem;
                position: absolute;
                text-shadow: .2rem .2rem .3rem rgba(0, 0, 0, .45);
            }
        }

        .catalog__header-container__text {
            display: flex;
            justify-content: center;
            flex-direction: column;
            flex: 1;
            border-radius: 1.2rem;
            padding: 1.8rem 4rem;
            background-color: color(gray, Gray-soft);

            @include breakpoint(small only) {
                padding: 0;
                background-color: transparent;
            }

            .catalog__header-count {
                color: color(gray, Gray-150);
                font-size: 2rem;
                @include breakpoint(medium) {
                    display: none;
                }
            }

            h1 {
                font-size: 3.2rem;
                @include breakpoint(small only) {
                    padding-bottom: .8rem;
                    font-size: 2.4rem;
                }

                span {
                    &:first-child {
                        @include font-primary;
                    }
                }
            }

            p {
                font-size: 1.3rem;
            }

            .--actions {
                margin-top: 1.2rem;
            }

            &.--center {
                text-align: center;
            }
        }

        .flex {
            display: flex;
            gap: .5rem;
            @include breakpoint(small only) {
                align-items: center;
                justify-content: center;
            }

            h1 {
                font-size: 2.3rem;
                margin: .5rem 0;
                @include breakpoint(medium) {
                    font-size: 3rem;
                    line-height: 4rem;
                    margin: 0;
                }
            }

            span {
                color: color(gray, Gray-300);
                font-family: 'True North Textures', sans-serif;
                font-size: 2.6rem;
                line-height: 5rem;
                @include breakpoint(medium) {
                    font-size: 2.8rem;
                    line-height: 4rem;
                }
            }
        }
    }

    .active__filter__container {
        display: flex;
        gap: 0 .8rem;
        padding: 0 0 2rem;
        width: 100%;
        flex-wrap: wrap;
        align-items: center;

        .active__filter-label {
            font-weight: 700;
        }

        @include breakpoint(small only) {
            padding: 0 0 1rem;
            top: 20.1rem;
        }

        button:not(.show-more) {
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M4 12L12 4M4 4L12 12" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
            background-position: right 1.2rem center;
            background-repeat: no-repeat;
            background-size: 1.2rem;
            border: 1px solid color(gray, Gray-150);
            border-radius: .4rem;
            display: block;
            font-size: 1.1rem;
            line-height: 2.4rem;
            padding: .8rem 3.6rem .8rem 1.2rem;

            &::first-letter {
                text-transform: uppercase;
            }

            @include breakpoint(small only) {
                padding: .5rem 3.6rem .5rem 1.2rem;
                margin-bottom: .5rem;
            }
        }

        button.show-more{
            color: color(base, Tertiary);
            justify-content: center;
            text-decoration: underline;
            width: 100%;
            display: inline-flex;
            font-size: 1.4rem;
            line-height: 2.1rem;
            margin: .5rem 0 0;
            margin-bottom: 1.6rem;
        }

        .reset__filters button, .reset__filters a {
            border: none;
            background-color: color(base, Black);
            border-radius: 10rem;
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M13.3525 7.78995H17.5125L14.8617 5.13745C14.0083 4.28401 12.9452 3.67028 11.7794 3.35795C10.6135 3.04563 9.38603 3.04572 8.22025 3.35822C7.05447 3.67072 5.9915 4.28461 5.13819 5.13817C4.28489 5.99174 3.67133 7.0549 3.35919 8.22078M2.48753 16.3699V12.21M2.48753 12.21H6.64753M2.48753 12.21L5.13753 14.8624C5.99096 15.7159 7.05402 16.3296 8.21985 16.6419C9.38568 16.9543 10.6132 16.9542 11.779 16.6417C12.9448 16.3292 14.0077 15.7153 14.861 14.8617C15.7143 14.0082 16.3279 12.945 16.64 11.7791M17.5125 3.62995V7.78828" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
            color: color(base, White);
            line-height: 2.4rem;
            padding: .8rem 2rem .8rem 4.4rem;
            background-position: left 1.6rem center;
            background-size: 20px;
            font-size: 1.1rem;
            font-weight: 700;
        }
    }

    .catalog-category__list {
        position: relative;

        .sticky-bottom {
            position: sticky;
            bottom: 1rem;
            left: 0;
            right: 0;
            width: 100%;
            background: color(base, White);

            button {
                position: relative;
                font-size: 1.4rem;
                width: 100%;
                justify-content: center;
                font-weight: 600;
                padding-left: 2rem;

                &:before {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='size-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75' /%3E%3C/svg%3E%0A");
                    background-repeat: no-repeat;
                    background-position: left center;
                    background-size: 1.6rem;
                    content: '';
                    width: 2rem;
                    height: 2rem;
                    display: block;
                }

                > span {
                    width: 2rem;
                    height: 2rem;
                    background: color(base, Primary);
                    border-radius: 50%;
                    color: color(base, DefaultFontColor);
                    text-align: center;
                    line-height: 2rem;
                    margin-left: .5rem;
                    font-size: 1.2rem;
                    font-weight: 600;
                }
            }
        }
    }

    .catalog__description {
        margin: 3rem 0 0;
        margin-top: 6.4rem;
        position: relative;
        @include breakpoint(medium) {
            column-count: 2;
            column-gap: 4rem;
            padding-top: 2.5rem;
            h2 {
                margin-bottom: 1.8rem;
            }
        }

        .catalog__description-col {
            font-size: 1.6rem;
            line-height: 3rem;
        }

        .--expand {
            color: color(base, Black);
            text-decoration: underline;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 0;
            z-index: 1;
            font-size: 1.3rem;
            margin: auto;
        }

        .--expanded {
            bottom: -3%;
            @include breakpoint(small only) {
                bottom: -1%;
            }
        }

        &.--closed {
            position: relative;
            max-height: 25rem;
            overflow: hidden;

            &:after {
                content: '';
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                position: absolute;
                background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);

            }
        }
    }

    .no__results {
        margin: 1rem 0 0;

        .search__component-container {
            margin: 2rem 0;
            @include breakpoint(medium) {
                max-width: 60%;
            }
        }
    }

    .category__product-count {
        font-size: 1.3rem;
        margin-top: 1rem;
        flex: 1;
        @include breakpoint(small only) {
            display: none;
        }
    }

    .divider {
        height: 1px;
        width: 100%;
        background-color: color(gray, Gray-100);
        margin-bottom: 2rem;
    }

    .categories__title {
        margin-bottom: 1.6rem;
        margin-top: 1.6rem;
        display: block;
        font-size: 1.5rem;
        font-weight: 700;
        @include breakpoint(medium) {
            display: none;
        }
    }

    .catalog__category-filter-page-builder {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        @include breakpoint(small only) {
            position: sticky;

        }
        top: 15rem;
        z-index: 2;

        p {
            font-size: 1.3rem;
            line-height: 2.4rem;

            span {
                font-weight: 700;
            }
        }
    }
}

