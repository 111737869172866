.checkout-cart {
    .cart__header {
        display: flex;
        align-items: center;
        gap: 3rem;
        @include breakpoint(medium) {
            margin: 2rem 0;
            justify-content: flex-end;
            h1 {
                margin-right: auto;
            }
        }
        @include breakpoint(small only) {
            p, button {
                display: none;
            }
        }

        h1 {
            font-size: 2.8rem;
            line-height: 5rem;
        }

        button {
            text-transform: lowercase;
        }
    }

    .cart__container {
        h1 {
            font-size: 3.2rem;
            padding-bottom: 2.8rem;
            @include breakpoint(small only) {
                font-size: 2rem;
                text-align: center;
                padding-bottom: 1.6rem;
            }
        }

        @include breakpoint(small only) {
            padding-top: 2rem;
            margin-bottom:2.4rem;
        }
        @include breakpoint(medium) {
            margin: 4rem 0 0;
        }

        .--heading {
            span {
                display: block;
                line-height: 4rem;
                font-size: 1.5rem;
                text-transform: uppercase;
                font-weight: 700;

                &.--right {
                    text-align: right;
                }
            }
        }

        .image__holder {
            display: inline-flex;
            padding: .5rem;
            aspect-ratio: 1;
            @include breakpoint(small only) {
                width: 11rem;
                height: 11rem;
            }
        }

        .order__line {
            padding: 1.5rem 0;
            border-top: 1px solid color(gray, Gray-100);
            &__image{
                display:flex;
                align-items: center;
                justify-content: center;
                img{
                    object-fit: contain;
                }
            }
            &.--disabled{
                .item__details a{
                    color: color(gray, Gray-300);
                }
                select{
                    pointer-events: none;
                    color:color(gray, Gray-300);
                    border-color: color(gray, Gray-300);
                    background-image:url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M2.53818 4.7048C2.88531 4.35766 3.44813 4.35766 3.79526 4.7048L8.50005 9.40959L13.2048 4.7048C13.552 4.35766 14.1148 4.35766 14.4619 4.7048C14.8091 5.05193 14.8091 5.61474 14.4619 5.96187L9.12859 11.2952C8.78146 11.6423 8.21865 11.6423 7.87151 11.2952L2.53818 5.96187C2.19105 5.61474 2.19105 5.05193 2.53818 4.7048Z" fill="%239A9A9A"/></svg>');
                }
                .price{
                    color:color(gray, Gray-300);
                }
            }

        }

        .item__details {

            > a {
                font-size: 1.6rem;
                line-height: 2.4rem;
                font-weight: 500;
                margin-top: 0;
                @include breakpoint(small only) {
                    font-size: 1.4rem;
                }
            }

            span {
                display: flex;
                font-size: 1.2rem;
                line-height: 2rem;
                @include breakpoint(medium) {
                    font-size: 1.3rem;
                }
            }

            .--promotion {
                background-image: url("data:image/svg+xml,%3Csvg width='166' height='40' viewBox='0 0 166 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.976198 0.962897C0.976198 0.962897 0.976198 1.00779 0.976198 1.02703C1.10918 1.77091 1.24217 2.50839 1.42835 3.25228C1.42835 3.30358 1.42835 3.34847 1.42835 3.39977L1.29536 7.24747C1.21557 9.76771 0.843212 12.3072 1.50814 14.821C1.56134 15.0198 1.64113 15.2186 1.72092 15.4174C1.56134 16.995 0.76342 28.9806 0.258073 30.3337C-0.273871 31.7445 0.151684 33.181 0.337865 34.5982C0.524045 36.0988 0.736823 37.6058 0.923004 39.1064C0.949601 39.4078 1.9071 39.6258 3.13057 39.6451C28.0788 39.9593 53.1067 40.0106 78.0815 39.9401C80.0231 39.9593 81.9381 39.9721 83.8797 39.985C89.5183 40.0234 95.1569 39.9914 100.742 39.8503C107.87 39.8118 115.025 39.7733 122.153 39.7285C128.217 39.69 134.281 39.6515 140.372 39.613C145.798 39.7285 151.224 39.8439 156.65 39.8824C159.043 39.8952 163.352 39.9721 165.081 39.4591C166.039 39.1769 165.879 38.8307 165.773 38.4844C165.613 38.0291 165.773 34.2006 165.64 32.918C165.507 31.6675 164.868 30.4363 164.23 29.205V27.9417C164.23 27.9417 165.347 18.1172 165.028 17.5336C165.187 15.9625 165.32 14.3849 165.453 12.8074C166.065 12.1212 165.773 9.56891 165.64 9.00459C165.666 6.89476 165.613 4.79136 165.32 2.68154C165.241 2.04666 165.932 0.918007 163.831 0.33444C161.996 -0.133697 158.777 0.0202108 156.357 0.0394493L133.67 0.212596C128.35 0.251073 123.057 0.295963 117.738 0.33444H93.8537C74.2515 0.28955 54.6228 0.24466 35.0206 0.219009C29.6746 0.212596 24.302 0.206183 18.9293 0.19977C16.4558 0.19977 12.9449 0.19977 10.498 0.19977C8.74259 0.19977 6.61481 0.129229 4.85939 0.251073C4.753 0.251073 4.67321 0.251073 4.56682 0.251073C4.08807 0.251073 3.60932 0.263898 3.13057 0.263898C1.69432 0.270311 0.57724 0.603778 1.02939 0.956484' fill='%23FFD400'/%3E%3C/svg%3E%0A");
                background-size: cover;
                background-repeat: no-repeat;
                padding: .5rem .7rem;
                font-size: 1.3rem;
                font-family: 'True North Textures', sans-serif;
                display: inline-block;
            }

            a {
                display: block;
                margin-top: .6rem;
            }

        }

        .item__status {
            font-size: 1.3rem;
            display: block;
            margin: .8rem 0;

            > div {
                &:before {
                    background-position: 50%;
                    background-repeat: no-repeat;
                    background-size: 1.5rem;
                    content: "";
                    display: inline-block;
                    height: 2rem;
                    margin-right: .6rem;
                    vertical-align: middle;
                    width: 2rem;
                }
            }

            .--in-stock {
                color: color(base, Green);

                &:before {
                    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="11" viewBox="0 0 16 11" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M15.3359 0.317185C15.539 0.520341 15.6531 0.795841 15.6531 1.0831C15.6531 1.37036 15.539 1.64586 15.3359 1.84902L6.66927 10.5157C6.46612 10.7188 6.19062 10.8329 5.90335 10.8329C5.61609 10.8329 5.34059 10.7188 5.13744 10.5157L0.804104 6.18235C0.606766 5.97803 0.497573 5.70438 0.500041 5.42033C0.502509 5.13629 0.616442 4.86457 0.817301 4.66372C1.01816 4.46286 1.28987 4.34892 1.57392 4.34646C1.85797 4.34399 2.13162 4.45318 2.33594 4.65052L5.90335 8.21794L13.8041 0.317185C14.0073 0.114092 14.2828 0 14.57 0C14.8573 0 15.1328 0.114092 15.3359 0.317185Z" fill="%2300C853"/></svg>');
                }
            }

            .--out-of-stock {
                color: color(base, Red);

                &:before {
                    content: none;
                }
            }

            .--low-stock {
                &:before {
                    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="11" viewBox="0 0 16 11" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M15.3359 0.317185C15.539 0.520341 15.6531 0.795841 15.6531 1.0831C15.6531 1.37036 15.539 1.64586 15.3359 1.84902L6.66927 10.5157C6.46612 10.7188 6.19062 10.8329 5.90335 10.8329C5.61609 10.8329 5.34059 10.7188 5.13744 10.5157L0.804104 6.18235C0.606766 5.97803 0.497573 5.70438 0.500041 5.42033C0.502509 5.13629 0.616442 4.86457 0.817301 4.66372C1.01816 4.46286 1.28987 4.34892 1.57392 4.34646C1.85797 4.34399 2.13162 4.45318 2.33594 4.65052L5.90335 8.21794L13.8041 0.317185C14.0073 0.114092 14.2828 0 14.57 0C14.8573 0 15.1328 0.114092 15.3359 0.317185Z" fill="%23FF9D0E"/></svg>');
                }

                color: color(base, Orange);
            }
        }

        .item__details{
            .--ending_time {
                display: flex;
                font-size:1.1rem;
                margin-top:.6rem;
                svg {
                    width: 1.5em;
                    margin-right: .5rem;
                }

                color: color(base, Secondary);
            }

            .--ending_time {
                svg {
                    width: 1.5em;
                    margin-right: .5rem;
                }

                color: color(base, Secondary);
            }
        }

        .item__actions {
            padding: 1rem 0;
            @include breakpoint(small only) {
                flex-flow: row-reverse;
            }
            @include breakpoint(medium) {
                select, input {
                    order: 1;
                }
                button {
                    order: 2;
                }
            }

            .price-line {
                display: flex;
                justify-content: space-between;
            }

            .action--amount {
                display: flex;
                align-items: center;
                margin-bottom: 1.6rem;

                .price {
                    font-size: 1.3rem;
                    margin-left: .8rem;
                    color: color(gray, Gray-400);
                }
            }

            form {
                display: block;

                button {
                    font-size: 1.3rem;
                    text-decoration: underline;
                }

                select {
                    padding: 0 2.5rem;
                    height: 4.8rem;
                    width: 8rem;
                    border-radius: 10rem;
                    display: flex;
                    align-items: center;
                    background-position: right 1.8rem top 1.3rem;
                }
            }

            .--remove {
                width: 1.8rem;
                height: 1.8rem;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='#{color(gray, Gray-200)}' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='m19 7-.867 12.142A2 2 0 0 1 16.138 21H7.862a2 2 0 0 1-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v3M4 7h16'/%3E%3C/svg%3E");
                @include breakpoint(medium) {
                    &:hover {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='#{color(gray, Gray-400)}' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='m19 7-.867 12.142A2 2 0 0 1 16.138 21H7.862a2 2 0 0 1-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 0 0-1-1h-4a1 1 0 0 0-1 1v3M4 7h16'/%3E%3C/svg%3E");
                    }
                }
            }

            select, input {
                width: 7.6rem;
                padding: 0 2rem;

                &[disabled] {
                    background: color(gray, Gray-100);
                    cursor: not-allowed;
                }
            }
        }

        .price__holder {
            padding: 0;

            @include breakpoint(small only){
                padding-top: 1.5rem;
            }

            span {
                display: block;
                font-size: 1.5rem;
                line-height: 1.6rem;

                &.price {
                    font-weight: 700;
                }

                @include breakpoint(medium) {
                    font-size: 1.6rem;
                    line-height: normal;
                }

                &.price__old-price {
                    color: color(gray, Gray-300);
                    font-size: 1.3rem;
                    text-decoration: line-through;
                }
            }
        }

        .cart__seperator {
            display: block;
            height: .1rem;
            background: color(gray, Gray-100);
        }
    }




    .usp__list {
        margin: 2rem 0 0;
        @include breakpoint(small only) {
            margin-bottom: 1.6rem;
        }
        ul li {
            line-height: 2.2rem;
            font-size: 1.2rem;
            @include breakpoint(medium) {
                line-height: 2.6rem;
                font-size: 1.4rem;
            }

            &.title {
                font-size: 1.4rem;
                @include breakpoint(medium) {
                    line-height: 2.6rem;
                    font-size: 1.6rem;
                }
            }
        }
    }

    .checkout-clear-cart {
        a {
            background-color: color(gray, Gray-100);
            font-weight: 400;
        }
    }
    .out-of-stock-notification{
        width: 100%;
        padding: 1.2rem;
        background-color: color(gray, Gray-150);
        font-size:1.2rem;
        text-align: center;
        position: relative;
        margin-top: 1.2rem;
        border-radius: .4rem;
        &:before{
            content:'';
            display:block;
            position: absolute;
            height: 2rem;
            width: 2rem;
            transform: rotate(45deg);
            background-color: color(gray, Gray-150);
            top:-.6rem;
            left:2.5rem;
        }
    }
}

.checkout-cart-empty {
    .flow-box__container{
        margin-top:2.4rem;
        a{
            color: color(base, Black);
            font-size: 2.4rem;
            font-weight: normal;
            margin-top:1.2rem;
            @include breakpoint(small only){
                font-size: 1.9rem;
            }
        }
    }
    .flbx-webp .bzfy-c-carousel-slider-wrapper__inner, .flow-box__container .bzfy-c-carousel-slider-wrapper__inner{
        padding:0;
    }
    .flbx-webp .bzfy-t-feed-carousel .bzfy-c-carousel-control, .flow-box__container .bzfy-t-feed-carousel .bzfy-c-carousel-control{
        left:4rem;
        &.bzfy-c-carousel-control--right{
            right:4rem;
            left:unset;
        }
    }
    .global__content {
        margin: 2rem 0 0;

        .button-container {
            display: flex;
            justify-content: center;
            gap: 1.5rem;

            @include breakpoint(small only) {
                flex-flow: column;

                .--button {
                    margin: 0 auto;
                }
            }
        }
        > p a{
            @include default-button;
            background-color: transparent;
            border: .1rem solid #000;
            height: 4.8rem;
            color: color(base, Black);
            &:not(:disabled):hover {
                background-color: transparent;
                color: color(base, Black);
            }
        }
    }

    .sidebar_right {
        margin: 2rem 0 0;
    }
    .flbx-webp{
        margin-bottom: 1.6rem;
    }
}
@include breakpoint(small only){
    .grid-container{
        .grid-x.grid-padding-x{
            .gray-wrapper--cart{
                padding-left:2rem;
                padding-right:2rem;
                background-color: color(gray, Gray-soft);
                margin-left:-.5rem;
                &.cell{
                    width: 100vw;
                }
            }
        }
    }
}

@keyframes slideInOffset {
    0% {
        width: 10%;
    }
    100% {
        width: 100%;
    }
}

.checkout-cart-empty {
    .flowbox-link{
        margin-bottom: 1.6rem;
    }
    .bzfy-o-container .slick-list{
        margin: 0 -5rem;
    }
}
