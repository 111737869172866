.dialog__container {
    position: fixed;
    z-index: 999999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.2);
    display: none;
    justify-content: center;
    align-items: center;
    &.is-open {
        display: flex;
    }

    .dialog__content {
        position: relative;
        background-color: #fefefe;
        padding: 3rem;
        width: 616px;
        max-width: 616px;
        border-radius: 2.4rem;
        @include breakpoint(small only) {
            padding: 2rem;
            width: 90%;
        }
        button {
            text-transform: none;
        }
        .dialog__close {
            display: block;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='size-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M6 18 18 6M6 6l12 12' /%3E%3C/svg%3E%0A");
            width: 2.4rem;
            height: 2.4rem;
            position: absolute;
            top: 1.2rem;
            right: 1.2rem;
            cursor: pointer;
            @include breakpoint(medium) {
                display: none;
            }
        }
    }

    &.review-form {
        .dialog__header {
            padding: 1.6rem 0;
            @include breakpoint(medium) {
                padding: 1.4rem 0;
            }
            .global__content {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                align-items: center;
                padding-bottom: 2rem;
                @include breakpoint(small only) {
                    padding-bottom: 1rem;
                }
                h3 {
                    font-family: Gotham Rounded,sans-serif;
                    font-size: 2.4rem;
                    line-height: 2.4rem;
                }
            }
            p {
                font-size: 1.3rem;
                line-height: 2.4rem;
            }
            a {
                color: color(base, Tertiary);
                text-decoration: underline;
                font-size: 1.3rem;
                line-height: 2.4rem;
            }
        }
        .dialog__custom_component {
            input,
            textarea,
            button {
                width: 100%;
                height: 4.8rem;
                border: 1px solid color(gray, Gray-100);
                @include breakpoint(small only) {
                    height: 3.8rem;
                    font-size: 1.2rem;
                }
            }
            textarea {
                height: 13rem;
                @include breakpoint(small only) {
                    height: 9rem;
                }
            }
            button {
                justify-content: center;
                background-color: color(base, Primary);
                border: none;
            }
            form {
                display: flex;
                flex-direction: column;
                gap: 0.6rem;
                .field__group:last-of-type {
                    margin-bottom: 0.5rem;
                }
                .field__group:first-of-type {
                    margin-bottom: 1.2rem;
                }
            }
        }
    }
    .dialog__footer {
        button {
            margin-top: 1.2rem;
            width: 100%;
            justify-content: center;
        }
    }
}
