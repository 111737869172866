.search__autocomplete__dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    @include breakpoint(small only) {
        background: rgba(0, 0, 0, .4);
        top: 100%;
        right: -1rem;
        left: -5rem;
        height: 100vh;
    }
    .--container {
        background: color(base, White);
        border: .1rem solid color(gray, Gray-100);
        border-top: none;
        .--header {
            p {
                padding: 1rem;
                display: flex;
                align-items: center;
                gap: 1rem;
                span {
                    display: block;
                    width: 2rem;
                    height: 2rem;
                }
            }
        }
        .--content {
            @include breakpoint(small only) {
                max-height: calc(100vh - 40rem);
                overflow-y: scroll;
                min-height: 100px;
            }
            .--categories, .--keywords {
                padding: .5rem 1rem;
                h4 {
                    font-size: 1.3rem;
                    text-transform: uppercase;
                    line-height: 2.4rem;
                    font-weight: 700;
                }
                ul li a {
                    display: inline-flex;
                    padding: .5rem 0;
                    font-size: 1.3rem;
                    line-height: normal;
                    @include breakpoint(medium) {
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
                &.--keywords em {
                    text-transform: capitalize;
                }
            }
            .--products {
                border-top: .1rem solid color(gray, Gray-100);
                padding: .5rem 1rem;
                ul li a {
                    display: flex;
                    align-items: center;
                    gap: 1rem;
                    font-size: 1.3rem;
                    @include breakpoint(medium) {
                        &:hover .product__name {
                            text-decoration: underline;
                        }
                    }
                    .image__holder {
                        display: block;
                        aspect-ratio: 1;
                    }
                    .product__info {
                        overflow: hidden;
                        display: block;
                        flex: 1;
                        span {
                            display: block;
                            &.product__category {
                                color: color(gray, Gray-300);
                            }
                        }
                    }
                }
            }
            em {
                font-weight: 600;
            }
        }
        .--footer {
            border-top: .1rem solid color(gray, Gray-100);
            padding: .5rem 1rem;
            a {
                display: block;
                text-align: center;
                line-height: 2.6rem;
                font-size: 1.3rem;
                span {
                    font-weight: 600;
                }
                @include breakpoint(medium) {
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
