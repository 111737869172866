.physical__stores {
    h3 {
        font-size: 1.8rem;
        line-height: 3rem;
        font-weight: 700;
        @include breakpoint(medium) {
            line-height: 5rem;
            font-size: 2.4rem;
        }
    }
    .--header {
        h1 {
            font-size: 1.8rem;
            line-height: 3rem;
            margin: 0 0 .5rem;
            @include breakpoint(medium) {
                line-height: 5rem;
                font-size: 2.6rem;
            }
        }
    }
    .stores__map {
        margin: 2rem 0;
        .--loading {
            width: 2.2rem;
            height: 2.2rem;
            display: block;
            margin: 0 0 1rem;
        }
        .search__container {
            margin: 0 0 1rem;
            @include breakpoint(medium) {
                max-width: 50%;
            }
            .form__container {
                display: flex;
                input {
                    flex: 1;
                    line-height: 3.2rem;
                    height: 3.4rem;
                }
                button {
                    flex-shrink: 1;
                    line-height: 3rem;
                    height: 3.4rem;
                }
                @include breakpoint(medium) {
                    input, button {
                        line-height: 3.6rem;
                        height: 4rem;
                    }
                }
            }
        }
        .stores__result {
            p {
                strong {
                    text-transform: capitalize;
                }
            }
            .result__list {
                display: flex;
                flex-wrap: wrap;
                gap: 1rem;
                margin: 1rem 0 2rem;
                a {
                    display: block;
                    background: color(gray, Gray-100);
                    flex-shrink: 1;
                    text-transform: uppercase;
                    font-size: 1.2rem;
                    line-height: normal;
                    padding: .5rem 1rem;
                    @include breakpoint(medium) {
                        &:hover {
                            background: color(gray, Gray-200);
                        }
                    }
                }
            }
        }
        .map__container {
            height: 40rem;
            .vue-map {
                height: 40rem;
                div[role='dialog'] {
                    h4 {
                        font-weight: 700;
                        font-size: 1.5rem;
                        margin: 0 0 .5rem;
                    }
                    p {
                        font-size: 1.3rem;
                        line-height: 1.6rem;
                    }
                }
            }
        }
    }
    .stores__overview {
        @include breakpoint(medium) {
            margin: 1rem 0 0;
        }
        .--navigation {
            margin: .5rem 0 0;
            display: grid;
            grid-template-columns: repeat(10, 1fr);
            grid-gap: .5rem;
            @include breakpoint(medium) {
                grid-template-columns: repeat(auto-fill, 3.4rem);
            }
            a {
                display: flex;
                background: color(base, Primary);
                color: color(base, White);
                font-weight: 700;
                aspect-ratio: 1;
                font-size: 1.6rem;
                align-items: center;
                justify-content: center;
                @include breakpoint(medium) {
                    &:hover {
                        background: color(base, PrimaryHover);
                    }
                }
            }
        }
        ul.--cities {
            list-style: disc;
            margin: 1rem 1.5rem 2rem;
            li {
                font-size: 1.4rem;
                line-height: 2.8rem;
                @include breakpoint(medium) {
                    line-height: 3.2rem;
                    a {
                        font-size: 1.6rem;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
    .store__rows {
        margin: 2rem 0;
        .store__row {
            margin: 0 0 1rem;
            strong {
                font-size: 1.6rem;
                line-height: 2.8rem;
                color: color(gray, Gray-300);
                @include breakpoint(medium) {
                    font-size: 2rem;
                    line-height: 4rem;
                }
            }
            a {
                line-height: 2.8rem;
                @include breakpoint(medium) {
                    font-size: 1.6rem;
                    line-height: 3.2rem;
                    padding-right: 2rem;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    .popular__cities {
        .--content {
            margin: 1rem 0 0;
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;
            a {
                display: block;
                background: color(gray, Gray-100);
                padding: 0 1rem;
                line-height: 2.4rem;
                text-transform: uppercase;
                font-size: 1.2rem;
                @include breakpoint(medium) {
                    &:hover {
                        background: color(gray, Gray-200);
                    }
                }
            }
        }
    }
    .store__header {
        margin: 0 0 3rem;
        position: relative;
        max-height: 17.8rem;
        overflow: hidden;
        span {
            font-family: 'True North Textures', sans-serif;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 6rem;
            color: color(base, White);
            font-size: 4.8rem;
            display: flex;
            align-items: center;
        }
        img {
            object-fit: cover;
            width: 100%;
        }
    }
    .store__detail__view {
        @include breakpoint(small only) {
            text-align: center;
        }
        h1 {
            font-size: 2.8rem;
            line-height: 3rem;
            margin: 0 0 1rem;
            font-family: 'Gotham Rounded', sans-serif;
            font-weight: 700;
            @include breakpoint(medium) {
                font-size: 3.5rem;
                line-height: 5rem;
            }
        }
        h2 {
            font-size: 1.8rem;
            margin: 0 0 1rem;
            @include breakpoint(medium) {
                font-size: 2.2rem;
            }
        }
        p {
            &:not(:last-child) {
                margin: 0 0 1.6rem;
            }
        }
        .store__information__pane {
            margin: 0 0 2rem;
            address {
                font-size: 1.4rem;
                line-height: 2.8rem;
                @include breakpoint(medium) {
                    font-size: 1.6rem;
                }
                .--block {
                    display: block;
                    a {
                        background-repeat: no-repeat;
                        background-size: 1.4rem;
                        background-position: left center;
                        @include breakpoint(small only) {
                            @include default-button;
                            margin: .5rem 0;
                            padding: 0 1.5rem 0 3rem;
                            background-position: left 1rem center;
                        }
                        &.--phone {
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-5 w-5' viewBox='0 0 20 20' fill='currentColor'%3E%3Cpath d='M2 3a1 1 0 0 1 1-1h2.153a1 1 0 0 1 .986.836l.74 4.435a1 1 0 0 1-.54 1.06l-1.548.773a11.037 11.037 0 0 0 6.105 6.105l.774-1.548a1 1 0 0 1 1.059-.54l4.435.74a1 1 0 0 1 .836.986V17a1 1 0 0 1-1 1h-2C7.82 18 2 12.18 2 5V3z'/%3E%3C/svg%3E");
                        }
                        &.--mail {
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-5 w-5' viewBox='0 0 20 20' fill='currentColor'%3E%3Cpath d='M2.003 5.884 10 9.882l7.997-3.998A2 2 0 0 0 16 4H4a2 2 0 0 0-1.997 1.884z'/%3E%3Cpath d='m18 8.118-8 4-8-4V14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8.118z'/%3E%3C/svg%3E");
                        }
                        @include breakpoint(medium) {
                            font-size: 1.6rem;
                            padding-left: 2rem;
                        }
                    }
                }
            }
            .opening__times {
                margin: 1rem 0 0;
                h4 {
                    font-weight: 700;
                    font-size: 1.4rem;
                    line-height: 2.4rem;
                    margin: 0 0 .5rem;
                    @include breakpoint(medium) {
                        font-size: 1.6rem;
                    }
                }
                ul {
                    margin: 0 0 2rem;
                    li {
                        font-size: 1.4rem;
                        line-height: 2.4rem;
                        @include breakpoint(medium) {
                            display: flex;
                            font-size: 1.6rem;
                            span {
                                display: block;
                                &:first-child {
                                    width: 20%;
                                }
                            }
                        }
                        &.--open {
                            color: color(alert, Success);
                        }
                    }
                }
            }
        }
        .stores__route {
            .stores__route__form {
                padding: 1rem;
                border: .1rem solid color(gray, Gray-100);
                text-align: left;
                @include breakpoint(medium) {
                    display: flex;
                    align-items: center;
                    padding: 1.5rem 2rem;
                }
                .--intro {
                    flex: 1;
                    h4 {
                        font-size: 1.8rem;
                        line-height: 2.5rem;
                        font-weight: 700;
                    }
                }
                form {
                    flex: 1;
                    span {
                        display: block;
                        margin: 1rem 0 0;
                        .form__container {
                            display: flex;
                            input {
                                flex: 1;
                            }
                            button, input {
                                height: 3.4rem;
                                line-height: 3.4rem;
                                @include breakpoint(medium) {
                                    height: 5rem;
                                    line-height: 5rem;
                                    font-size: 1.6rem;
                                }
                            }
                        }
                    }
                }
            }
            .stores__route__response {
                margin: 2rem 0 0;
                .stores__route__steps {
                    margin: 2rem 0 0;
                    h3 {
                        font-size: 1.8rem;
                        line-height: 2.5rem;
                        text-align: left;
                        span {
                            color: color(base, Tertiary);
                        }
                    }
                    .--sub-header {
                        font-size: 1.6rem;
                        margin: .5rem 0 2rem;
                        text-align: left;
                        display: flex;
                        align-items: center;
                        gap: .5rem;
                        span {
                            display: block;
                            width: 2rem;
                            height: 2rem;
                            &.--distance {
                                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z' /%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M15 11a3 3 0 11-6 0 3 3 0 016 0z' /%3E%3C/svg%3E");
                            }
                            &.--duration {
                                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z' /%3E%3C/svg%3E");
                                margin-left: .5rem;
                            }
                        }
                    }
                    ul {
                        border: .1rem solid color(gray, Gray-100);
                        border-bottom: none;
                        li {
                            display: flex;
                            align-items: center;
                            gap: 1rem;
                            border-bottom: .1rem solid color(gray, Gray-100);
                            line-height: 2.2rem;
                            font-size: 1.2rem;
                            padding: 1rem;
                            span {
                                display: block;
                                text-align: left;
                                &.--content {
                                    flex: 1;
                                    b {
                                        font-weight: 600;
                                    }
                                    div {
                                        text-align: left;
                                        line-height: normal;
                                    }
                                }
                                &:last-child {
                                    margin-left: auto;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
