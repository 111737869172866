.checkout-checkout_body {
    .__usp[aria-hidden=true] {
        display: none;
    }
    .header__container {
        border-bottom: .1rem solid color(gray, Gray-100);
        padding: 2rem 0;
        > .grid-container {
            h1 {
                font-size: 2.8rem;
                text-align: center;
            }
        }
        .header__logo {
            display: flex;
            width: 12.4rem;
        }
        .flex {
            justify-content: flex-end;
            p.--secure {
                font-size: 1.3rem;
                background-position: left;
                background-size: 1.4rem;
                padding-left: 2rem;
            }
        }
    }
    .__usp.header {
        text-align: center;
        margin: 1rem 0;
        li {
            display: inline-flex;
            margin: 0 1rem;
            @include breakpoint(small only) {
                &:not(:nth-child(1)) {
                    display: none;
                }
            }
            @include breakpoint(medium) {
                &:not(:nth-child(1)):not(:nth-child(2)) {
                    display: none;
                }
            }
        }
    }
    .checkout-checkout {
        padding-top: 4rem;
        @include breakpoint(small only){
            padding-top: 0;

            .grid-container .grid-x.grid-padding-x.gray-wrapper{
                padding:2rem;
                background-color: color(gray, Gray-soft);
                .cell{
                    padding:0;
                }
            }
            .checkout-container{
                padding:2rem;
                background-color: color(base, White);
                border-radius: 1.2rem;
            }
        }
        > .grid-container > .grid-x.grid-padding-x > .cell {
            padding:0;
        }
        .checkout-container {
            margin-top: 4rem;

            .checkout-error-container {
                span {
                    font-size: 1.4rem;
                    margin: 0 0 1rem;
                    display: block;
                    a {
                        text-decoration: underline;
                    }
                }
                .error {
                    color: color(alert, Error);
                }
                .warning {
                    color: color(alert, Warning);
                }
            }
            .checkout-step-container {
                h2 {
                    font-size: 3.2rem;
                    line-height: 2.8rem;
                    padding-bottom: 2rem;
                    @include breakpoint(small only){
                        font-size: 2.4rem;
                    }
                }
                .--loading {
                    position: static;
                    width: 100%;
                    height: 2rem;
                    display: block;
                    &.--margin {
                        margin: 2rem 0;
                    }
                }
                .billing__address {
                    margin: 2rem 0 0;
                }
                .shipping__address, .billing__address {

                    .shipping-address-select {
                        label {
                            display: block;
                            font-size: 1.4rem;
                            margin: 2rem 0 1rem;
                        }
                        select {
                            @include breakpoint(small only) {
                                width: 100%;
                            }
                            border: 1px solid color(gray, Gray-150);
                            border-radius: .4rem;
                            height: 4.8rem;
                            line-height: 2.4rem;
                            display: flex;
                            align-items: center;
                        }
                    }
                    .checkout__login {
                        p {
                            font-size: 1.3rem;
                            line-height: 2.2rem;
                            font-weight: 500;
                            span {
                                text-decoration: underline;
                                cursor: pointer;
                                color: color(base, Tertiary);
                            }

                        }
                    }
                    .checkout__login-container {
                        .--actions {
                            display: flex;
                            margin: 1rem 0 0;
                            gap: 2rem;
                            align-items: center;
                            a {
                                font-size: 1.2rem;
                                @include breakpoint(medium) {
                                    &:hover {
                                        text-decoration: underline;
                                    }
                                }
                            }
                        }
                    }
                    .shipping-address-form {
                        border-top: .1rem solid color(Gray, Gray-150);
                        margin-top: 2.8rem;
                        padding-top: .5rem;
                        @include breakpoint(small only){
                            margin-top:1.6rem;
                        }
                        .checkout__store-switch{
                            a{
                                font-size:1.4rem;
                            }
                        }
                    }
                    .field__group {
                        display: flex;
                        gap: 1.6rem;
                        margin: 1rem 0 0;
                        @include breakpoint(small only) {
                            margin: .4rem 0 0;
                            &.email__group {
                                flex-flow: column;
                                gap: 0;
                            }
                        }
                        &.--newsletter, &.--create-account {
                            margin: 2rem 0 0;
                            label {
                                line-height: 2rem;
                                font-size: 1.3rem;
                                font-weight: 500;
                                &::before {
                                    border: .1rem solid color(base, DefaultFontColor);
                                    height: 2rem;
                                    width: 2rem;
                                    border-radius: .4rem;
                                }
                                &::after {
                                    height: 1.2rem;
                                    left: .4rem;
                                    width: 1.2rem;
                                    display: none;
                                    border-radius: .2rem;
                                }
                                @include breakpoint(medium) {
                                    &:hover {
                                        text-decoration: underline;
                                    }
                                }
                            }
                            input[type=checkbox]:checked + label::after {
                                display: block;
                            }
                        }
                        .checkout__field {
                            flex: 1;
                            label {
                                font-size: 1.4rem;
                                line-height: 3rem;
                                &.flex {
                                    align-items: center;
                                    gap: .5rem;
                                }
                            }
                            input, select {
                                border-radius: .4rem;
                                width: 100%;
                            }
                            select{
                                border: 1px solid #eae7de;
                                height: 4.8rem;
                                line-height: 4.8rem;
                            }
                            input {
                                &.passed {
                                    border-color: color(alert, Success);
                                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-5 w-5' viewBox='0 0 20 20' fill='#{color(alert, Success)}'%3E%3Cpath fill-rule='evenodd' d='M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm3.707-9.293a1 1 0 0 0-1.414-1.414L9 10.586 7.707 9.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4z' clip-rule='evenodd'/%3E%3C/svg%3E");
                                    background-repeat: no-repeat;
                                    background-size: 1.6rem;
                                    background-position: right 0.5rem center;
                                }
                            }

                            @include breakpoint(medium) {
                                &.email, &.password, &.street_0, &.city, &.company, &.firstname, &.lastname, &.postcode, &.telephone  {
                                    max-width: 78rem;
                                }
                                &.country {
                                    max-width: 25rem;

                                }
                                &.street_1, &.street_2 {
                                    width: 38.2rem;
                                }
                            }
                        }
                    }
                    .checkout__store-switch {
                        a {
                            display: block;
                            line-height: 3.2rem;
                            font-size: 1.2rem;
                            color: color(base, Tertiary);
                            @include breakpoint(small only) {
                                margin-right: auto;
                            }
                            @include breakpoint(medium) {
                                &:hover {
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                    .checkout__login__link {
                        font-size: 1.3rem;
                        margin: 1.5rem 0 0;
                        @include breakpoint(medium) {
                            padding: 3rem 0 0;
                            flex: 1;
                        }
                        span {
                            text-decoration: underline;
                            cursor: pointer;
                        }
                    }
                    .billing-same-as-shipping-control {
                        margin: 2rem 0 0;
                        input + label
                        {
                            &::after {
                                background: gold;
                                border-radius: .2rem;
                                content: "";
                                display: block;
                                height: 1.2rem;
                                left: .4rem;
                                position: absolute;
                                width: 1.2rem;

                            }
                        }
                        input:checked + label {
                            &::after {
                               content:none;
                            }
                        }
                        label {
                            display: inline-flex;
                        }
                        label, .guest__toggle {
                            line-height: 2rem;
                            font-size: 1.3rem;
                            span {
                                cursor: pointer;
                            }
                        }
                        .guest__toggle span {
                            text-decoration: underline;
                        }
                    }
                }
            }
            .billing__address h2{
                padding-bottom: 0;
                margin-top: 2.4rem;
            }
            .shipping__methods, .payment__methods {

                .--loading-methods {
                    display: flex;
                    align-items: center;
                    gap: 1rem;
                    font-size: 1.2rem;
                    margin: 2rem 0 0;
                    .--loading {
                        width: 2rem;
                    }
                }
                .shipping__method, .payment__method {
                    border-radius: .4rem;
                    padding: 1.6rem;
                    margin: .5rem 0;
                    position:relative;
                    border: .1rem solid color(gray, Gray-200);

                    @include breakpoint(medium) {
                        margin: 1rem 0;
                        padding: 2rem;

                    }

                    &.--active{
                        border: .1rem solid color(base, Black);
                    }

                    &.--active:not(.--pickup-post-nl){
                        background-color:color(gray, Gray-150);
                    }
                    &.--pickup-post-nl{
                        padding-top:2.4rem;
                    }
                    &.--active.--pickup-post-nl{
                        padding:0;
                        .shipping__method__header{
                            padding:2rem;
                            background: color(gray, Gray-150);
                            border-radius: .4rem .4rem 0 0;

                            @include breakpoint(small only){
                                padding:2rem .8rem 1.6rem;
                            }
                        }
                        .shipping__method__options{
                            padding: 0 2rem;
                        }
                    }

                    label {
                        gap: .8rem;
                        margin-bottom: 1.2rem;
                        &::before {
                            width: 2.4rem;
                            height: 2.4rem;
                            flex: 0 0 2.4rem;
                            border: .1rem solid color(gray, Gray-100);

                        }
                        img {

                            aspect-ratio: 8/5;
                        }
                        .--content {
                            display: block;
                            width:100%;
                            span {
                                display: block;
                                &.--title {
                                    display: flex;
                                    justify-content: space-between;
                                    width: 100%;
                                    gap: .5rem;
                                    line-height: 2rem;
                                    font-size: 1.4rem;
                                    font-weight: 700;
                                    @include breakpoint(small only){
                                        margin-bottom: .6rem;
                                    }
                                    span{
                                        font-weight: 600;
                                    }
                                    .--free {
                                        font-weight: 700;
                                        color: color(alert, Success);
                                    }
                                }
                                &.--sub {
                                    line-height: 2rem;
                                    font-size: 1.3rem;
                                    color: color(gray, Gray-500);
                                    margin: -.5rem 0 0;
                                    &.--free{
                                        color: color(alert, Success);
                                        font-weight: 700;
                                    }
                                }
                            }
                        }
                        small {
                            font-size: 1rem;
                            margin-left: auto;
                            color: color(base, White);
                            background-color:color(alert, Success);
                            background-image:url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none"><path d="M10.5 1.5V2.5C10.5 7.3135 7.8135 9.5 4.5 9.5H3.549C3.655 7.994 4.124 7.0825 5.348 5.9995C5.95 5.467 5.899 5.1595 5.6025 5.336C3.5605 6.551 2.5465 8.193 2.5015 10.815L2.5 11H1.5C1.5 10.3185 1.558 9.7 1.673 9.134C1.558 8.487 1.5 7.609 1.5 6.5C1.5 3.7385 3.7385 1.5 6.5 1.5C7.5 1.5 8.5 2 10.5 1.5Z" fill="white"/></svg>');
                            background-repeat: no-repeat;
                            background-position: center left .4rem;
                            text-transform: uppercase;
                            font-weight: 600;
                            padding: .4rem .4rem .4rem 2rem;
                            display:block;
                            position: absolute;
                            top:0;
                            left:5.2rem;
                            border-radius: 0 0 .4rem .4rem;
                        }
                    }
                    .bank-transfer--container {
                        margin: 1rem 0 0;
                        background: color(gray, Gray-soft);
                        padding: 1.5rem 1rem;
                        @include breakpoint(medium) {
                            margin-left: 10rem;
                            max-width: 100%;
                        }
                        span > * {
                            font-size: 1.2rem;
                        }
                    }
                    input[type="radio"]:checked + label {
                        &::before {
                            border: .4rem solid color(base, White);
                            box-shadow: 0 0 0 .1rem color(gray, Gray-400);
                            background-color: gold;
                            height: 2.4rem;
                            width: 2.4rem;
                            flex:0 0 2.4rem;
                            @include breakpoint(medium) {
                                border-width: .6rem;
                            }
                        }
                        &::after {
                            display: none;
                        }
                    }
                    .shipping__method__options {
                        &.--narrow {
                            margin-left: 3.5rem;
                            .loading__stores {
                                font-size: 1.2rem;
                                display: flex;
                                align-items: center;
                                gap: 1rem;
                                .--loading {
                                    position: static;
                                    width: 2rem;
                                    height: 2rem;
                                }
                            }
                        }
                        .pick-up__stores__result {
                            .checkout-method-options{
                                > span{
                                    font-size:1.3rem;
                                }
                            }
                            select {
                                margin: 1rem 0 0;
                                height:4.8rem;
                                padding: 0 2rem;
                                border:none;
                                width: 100%;
                                border-radius: 10rem;
                                display:flex;
                                align-items: center;
                            }
                            .selected__store {
                                margin: 1rem 0 0;
                                font-size: 1.2rem;
                                line-height: 2rem;
                                strong {
                                    font-size: 1.3rem;
                                    display: block;
                                }
                                ul {
                                    margin: 1rem 0 0;
                                    li {
                                        display: flex;
                                        span {
                                            display: block;
                                            &:first-child {
                                                min-width: 9rem;
                                            }
                                        }
                                        &.--today {
                                            font-weight: 700;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .payment__method__options {
                        padding: 1.5rem 1rem;
                        @include breakpoint(medium) {
                            margin-left: 2rem;
                            max-width: 60%;
                        }
                        select{
                            width: 20rem;
                            height: 4.8rem;
                            border: none;
                            border-radius: 10rem;
                            display: flex;
                            align-items: center;
                        }
                        .payment__method__option-holder {
                            .payment__field {
                                &:not(:last-of-type) {
                                    margin: 0 0 1rem;
                                    > label {
                                        display: block;
                                        font-size: 1.2rem;
                                        margin: 0 0 1rem;
                                    }
                                    .field__container {
                                        label {
                                            font-size: 1.2rem;
                                        }
                                    }
                                }
                                input[name=termsCondition]:checked + label::after, input[name=termsCondition] + label:hover::after {
                                    left: .5rem;
                                }
                                input[type=date] {
                                    width: 60%;
                                    font-size: 1.3rem;
                                }
                                .--terms {
                                    font-size: 1.2rem;
                                    gap: 0;
                                    &:before{
                                        background-color: color(base, White);
                                        margin-right: .4rem;
                                    }
                                    a {
                                        font-size: 1.2rem;
                                        text-decoration: underline;
                                        margin: 0 0 0 5px;
                                        display: block;
                                        line-height: normal;
                                    }
                                }
                            }
                            span {
                                display: block;
                                &:not(:last-child) {
                                    margin: 0 0 1.5rem;
                                }
                                label {
                                    gap: .5rem;
                                    font-size: 1.2rem;
                                    img{
                                        background-color: transparent;
                                    }
                                }
                            }
                        }
                        select {
                            padding-right: 4rem;
                            font-size: 1.2rem;
                            @include breakpoint(small only) {
                                width: 100%;
                            }
                        }
                    }
                }
                .field-error {
                    display: block;
                    font-size: 1.2rem;
                    margin: .5rem 0 0;
                    color: color(alert, Error);

                    a {
                        font-size: 1.2rem;
                        text-decoration: underline;
                    }
                }
            }
            .payment__method{
                &.--active{
                    label img {
                        background-color: color(base, White);
                    }
                }
                label img {
                    height: 4.5rem;
                    width: 7.2rem;
                    background-color: color(gray, Gray-150);
                    order:1;
                    padding: 1rem;
                    border-radius: .5rem;
                    box-sizing: content-box;
                }
            }
            .coupon-code__container {
                @include breakpoint(small only) {
                    padding: 2rem 0 0;
                    border-top: .1rem solid color(gray, Gray-100);
                }
                .toggle-coupon {
                    label {
                        line-height: 2rem;
                        font-size: 1.3rem;
                    }
                }
                .coupon-code__form {
                    margin: 1.5rem 0 0;
                    @include breakpoint(medium) {
                        max-width: 60%;
                    }
                    .form-holder {
                        display: flex;
                        input {
                            flex: 1;
                        }
                        button {
                            height: 3.2rem;
                            line-height: 3.2rem;
                            @include breakpoint(medium) {
                                height: 4rem;
                                line-height: 4rem;
                                &:hover {
                                    background: color(gray, Gray-200);
                                    border-color: color(gray, Gray-300);
                                }
                            }
                        }
                    }
                }
            }
            .checkout-summary {
                margin: 2rem 0 0;
                @include breakpoint(medium) {
                    padding: 2rem;
                    border: .1rem solid color(gray, Gray-100);
                }
                h4 {
                    font-size: 1.3rem;
                    line-height: 2rem;
                    font-weight: 700;
                    @include breakpoint(small only) {
                        &:last-of-type {
                            margin: 2rem 0 0;
                        }
                    }
                }
                .summary-block {
                    margin: 1rem 0;
                    .address, .delivery__address{
                        padding:0;
                    }
                    @include breakpoint(medium) {
                        max-width: 80%;
                    }
                    .--loading {
                        display: flex;
                        height: 2rem;
                        margin: 2rem;
                        position: relative;
                        top: 2.5rem;
                    }
                    ul {
                        padding: 1rem 1.5rem;
                        li {
                            display: flex;
                            font-size: 1.3rem;
                            line-height: 1.6rem;
                            > span {
                                display: block;
                                &:first-child {
                                    flex: 1;
                                    padding-right: 1rem;
                                }

                                &:last-child {
                                    display: flex;
                                    align-items: baseline;
                                    gap: .5rem;

                                    span.--old {
                                        color: color(gray, Gray-300);
                                        text-decoration: line-through;
                                        font-size: 1.1rem;
                                    }
                                }
                            }
                        }
                    }
                    .cart__items {
                        border-bottom: .1rem solid color(gray, Gray-100);
                        li:not(:last-child) {
                            margin: 0 0 1rem;
                        }
                    }
                    .cart__totals {
                        li {
                            &:not(:last-child) {
                                margin: 0 0 1rem;
                            }
                            .--free {
                                font-weight: 700;
                                color: color(alert, Success);
                            }
                            &.--grand {
                                span:first-child {
                                    font-weight: 700;
                                }
                            }
                        }
                    }
                    .delivery__address {
                        li {
                            line-height: 2rem;
                        }
                    }

                }
                .--edit {
                    font-size: 1.2rem;
                    color: color(base, Tertiary);
                    @include breakpoint(medium) {
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
            .checkout-footer {
                margin: 2rem 0 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 3rem;

                @include breakpoint(small only) {
                    position: fixed;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background: color(base, White);
                    padding: 1.5rem;
                    border-top: .1rem solid color(gray, Gray-100);
                    transition: transform .3s ease;
                    &.--hide{
                        transform: translateY(10rem);
                    }
                }

                a {
                    display: flex;
                    font-size: 1.4rem;
                    height: 4.8rem;
                    text-transform: initial;

                    @include breakpoint(small only) {
                        margin-right: auto;
                        justify-content: center;
                        width: 100%;
                    }
                    @include breakpoint(medium) {
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
                button{
                    min-width: 24rem;
                    font-size: 1.4rem;
                    height: 4.8rem;
                    text-transform: initial;
                    justify-content: center;

                    @include breakpoint(small only) {
                        width: 100%;
                        justify-content: center;
                    }
                }
                .--legacy {
                    @media screen and (max-width: 360px) {
                        font-size: 1.4rem;
                    }
                    @media screen and (min-width: 361px) and (max-width: 767px) {
                        font-size: 1.4rem;
                    }
                }
            }
            .checkout-footer--mobile{
                @include breakpoint(medium){
                    display:none;
                }
                .--button{
                    width: 100%;
                    justify-content: center;
                    height: 4.8rem;
                    margin-top: 1.2rem;
                    text-transform: initial;
                    font-size:1.4rem;
                }
            }
        }
        .delivery-details{

            @include breakpoint(small only){
                padding-bottom: 2.4rem;
                border-bottom: .1rem solid color(gray, Gray-100);
            }
            .personal-details{
                margin-bottom:2.4rem;
                padding-left:1.6rem;
                padding-right:1.6rem;
                &:first-child{
                    margin-bottom: .8rem;
                }
                .address{
                    padding-bottom: 1.2rem;
                    border-bottom: .1rem solid color(gray, Gray-100);
                }
                h4{
                    font-size: 2rem;
                    line-height:2.8rem;
                    font-family:'True North Textures';
                    padding: 1.6rem 0;
                    border-bottom:.1rem solid color(gray, Gray-100);
                    margin-bottom:1.6rem;
                }
                li{
                    font-size:1.4rem;
                    strong {
                        margin-bottom: .8rem;
                    }
                }
                a, button{
                    color: color(base, Tertiary);
                    font-size:1.4rem;
                    text-decoration:underline;
                    margin-top:1.2rem;
                }
            }
            .--button{
                width: 100%;
                justify-content: center;
                height: 4.8rem;
                font-size:1.4rem;
                text-transform: initial;
            }
        }
    }
    footer {
        padding: 1rem 10%;
        @include breakpoint(small only) {
            background: color(gray, Gray-100);
            margin-bottom: 6.9rem;
        }
        > .grid-container {
            max-width: 72.6rem;
            ul {
                display: inline-flex;
                flex-wrap: wrap;
                justify-content: center;
                li {
                    span {
                        display: block;
                        &::after {
                            content: '|';
                            display: inline-flex;
                            margin: 0 1rem;
                        }
                        a {
                            font-size: 1.2rem;
                            @include breakpoint(medium) {
                                font-size: 1.4rem;
                                line-height: 2.2rem;
                                &:hover {
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                    &:last-child span::after {
                        display: none;
                    }
                }
            }
            > span {
                margin: 1rem 0 0;
                display: block;
                font-size: 1.2rem;
                a {
                    font-size: 1.2rem;
                }
                @include breakpoint(medium) {
                    display: inline-block;
                    font-size: 1.4rem;
                    line-height: 2.2rem;
                    &::before {
                        content: '|';
                        display: inline-flex;
                        margin: 0 1rem;
                    }
                    a {
                        font-size: 1.4rem;
                        line-height: 2.2rem;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
    .shipping__address, .billing-address-form {
        input {
            border-radius: unset;
            border: 1px solid color(gray, Gray-150);
            height: 4.8rem;
            line-height: 2.4rem;
        }
    }
    .checkout__privacy-policy{
        font-size:1.3rem;
        text-align: center;
        margin-top:1.2rem;
        a{
            color:color(base, Tertiary);
            font-size:1.3rem;
        }
    }
}

.--shake {
    animation: shake-input 1s .8 linear;
}

.checkout-checkout_body .checkout-checkout {
    .receipt {
        .personal-details {
            padding: 0;
            h4{
                padding:0;
                border-bottom: none;
            }
            button{
                display: block;
                margin-left: auto;
            }
        }
        .summary-block{
            border-radius: 1.2rem;
            background-color:color(base, White);
            padding: 2.4rem;
            .address, .delivery__address{
                padding-bottom:0;
                border-bottom:none;
                li{
                    margin-bottom:.8rem;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

