.cms-page {
    @include breakpoint(small only) {
        .breadcrumbs {
            display: none;
        }
    }
    .cms__menu {
        ul li {
            border-bottom: .1rem solid color(gray, Gray-100);
            line-height: 4rem;
            display: none;
            a {
                display: flex;
                line-height: 4rem;
                color: color(gray, Gray-600);
                @include breakpoint(medium) {
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
            &.title {
                @include icon('chevron-down-black');
                background-position: right 1rem center;
                background-size: 2.2rem;
                cursor: pointer;
                font-weight: 700;
                font-size: 1.4rem;
                display: block;
                &.--active {
                    @include icon('chevron-up-black');
                    background-position: right 1rem center;
                    background-size: 2.2rem;
                }
            }
        }
    }
    .content {
        margin: 1rem 0 0;
        h2, h3 {
            margin: 0 0 1rem;
            font-weight: bold;
        }
        h1 {
            font-size: 2.3rem;
            line-height: 3.2rem;
            margin: 0 0 1rem;
        }
        h2 {
            font-size: 2.3rem;
            line-height: 3.2rem;
        }
        h3 {
            font-size: 1.6rem;
            margin: 0;
        }
        h4 {
            font-size: 1.4rem;
            line-height: 2.2rem;
        }
        p:not(:last-of-type) {
            margin: 0 0 2rem;
        }
        a:not(.--button):not(.button) {
            color: color(base, Tertiary);
            @include breakpoint(medium) {
                &:hover {
                    color: color(base, TertiaryHover);
                }
            }
        }
        .--button, .button {
            strong {
                line-height: 3.4rem;
            }
        }
        ul {
            font-size: 1.4rem;
            line-height: 2.1rem;
            list-style: disc outside;
            margin: 0 0 2rem 1.5rem;
            &.__usp {
                list-style: none;
                margin: 0 0 2rem;
            }
        }
        ol {
            font-size: 1.4rem;
            line-height: 2.1rem;
            list-style: decimal outside;
            margin: 0 0 2rem 1.5rem;
        }
        table {
            width: 100%;
            border-collapse: collapse;
            font-size: 1.4rem;
            tr {
                border-top: .1rem solid color(gray, Gray-100);
            }
        }
        .justify {
            text-align: justify;
        }
        .light-gray {
            color: color(gray, Gray-100);
        }
        .medium-gray {
            color: color(gray, Gray-200);
        }
        .dark-gray {
            color: color(gray, Gray-300);
        }
        .default-primary {
            color: color(base, Primary);
        }
        .default-secondary {
            color: color(base, Secondary);
        }
        .default-tertiary {
            color: color(base, Tertiary);
        }
    }
    &.error-page {
        @include breakpoint(medium) {
            margin: 2rem 0 0;
        }
        h1 {
            font-size: 2.3rem;
            line-height: 3.2rem;
            @include breakpoint(medium) {
                font-size: 3rem;
                line-height: 3.8rem;
            }
        }
        .no-route__search {
            margin: 0 0 2rem;
            @include breakpoint(medium) {
                max-width: 50%;
            }
            p {
                font-weight: 700;
                margin: 0 0 1rem;
            }
            form {
                display: flex;
                input {
                    height: 4rem;
                    line-height: 3.8rem;
                    flex: 1;
                }
                button {
                    height: 4rem;
                    line-height: 3.8rem;
                }
            }
        }
    }
    .folder__container {
        margin: 0 0 2rem;
        a {
            display: block;
            text-align: center;
            img {
                max-width: 40rem;
                @include breakpoint(small only) {
                    max-width: 100%;
                    height: auto;
                }
            }
            span {
                display: block;
                font-size: 1.6rem;
                text-align: center;
                color: color(base, DefaultFontColor);
                padding: 1rem 0;
            }
            @include breakpoint(medium) {
                &:hover span {
                    text-decoration: underline;
                }
            }
        }
    }

    .accordion {
        max-width: 100%;
        @include breakpoint(small only) {
            max-width: 100%;
            margin: 1rem 0 0;
        }
        ul {
            list-style: none;
            margin-left: 0;
            li {
                p {
                    margin: 1rem 0 0 0 !important;
                }
                &.title {
                    font-size: 1.4rem;
                    cursor: pointer;
                    line-height: 4rem;
                    padding: 0 1.8rem 0 0;
                    border-bottom: .1rem solid color(gray, Gray-100);
                    text-transform: none;
                    font-weight: 700;
                    @include icon('chevron-down-black');
                    background-position: right 1rem center;
                    background-size: 1.6rem;
                    &.--active {
                        @include icon('chevron-up-black');
                        background-position: right 1rem center;
                        background-size: 1.6rem;
                    }
                    a {
                        display: block;
                        font-size: 1.4rem;
                        strong {
                            display: inline;
                        }
                        color: color(gray, Gray-600);
                        padding: .8rem 0;
                        border-bottom: .1rem solid color(gray, Gray-100);
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
}

.newsletter__block__widget {
    @include breakpoint(medium) {
        max-width: 40%;
    }
}

.iframe-container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
}
