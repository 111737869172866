.modal__overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 99992;
    display: flex;
    justify-content: center;
    align-items: center;
    .modal__wrapper {
        background: color(base, White);
        box-shadow: 0 0 1.5rem 0 rgba(0, 0, 0, .5);
        max-width: 90vw;
        max-height: 90vh;
        border-radius: 0.5rem;
        overflow: hidden;
        .modal-header {
            padding: 1rem 1rem 0;
            display: flex;
            align-items: center;
            h4 {
                font-size: 1.6rem;
            }
            span {
                cursor: pointer;
                display: block;
                width: 2rem;
                height: 2rem;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-5 w-5' viewBox='0 0 20 20' fill='currentColor'%3E%3Cpath fill-rule='evenodd' d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z' clip-rule='evenodd' /%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: center;
                margin-left: auto;
            }
        }
        .modal-content {
            padding: .5rem 1rem 1rem;
        }
    }
}

.recommended__store {
    .modal__wrapper {
        min-width: 20rem;
        padding: 1rem;
        @include breakpoint(medium) {
            max-width: 40%;
        }
        h2 {
            margin: 0 0 1rem;
            font-size: 1.6rem;
            line-height: 2.6rem;
            @include breakpoint(medium) {
                line-height: 4.6rem;
                font-size: 2.6rem;
            }
        }
        a {
            margin: 2rem 0 0;
        }
    }
}
