header {
    .top-banner {
        a {
            display: block;
            height: 4rem;
            background-repeat: repeat;
        }
        &.--no-url a {
            pointer-events: none;
        }
    }
    .sticky {
        background: color(base, White);
        @include breakpoint(small only) {
            z-index: 3;
        }
        &.is-stuck {
            z-index: 5;
            box-shadow: 0 0 .3rem 0 rgba(0, 0, 0, .25);
            .site__banner-item {
                display: none;
            }
            .header__items {
                padding: 1rem 0;
            }
            .header__menu {
                padding: 0;
                border-color: transparent;
            }
        }
        .header__logo {
            display: flex;
            order: 1;
            @include breakpoint(small only) {
                flex: 1;
                max-width: 12.5rem;
            }
        }
        .header__bar {
            background: color(gray, Gray-soft);
            .grid-container {
                display: flex;
                align-items: center;
                padding: .5rem 0;
                .header__bar__holder--marquee {
                    flex: 1;
                    overflow-x: hidden;
                    .header__bar--marquee {
                        position: relative;
                        display: flex;
                        overflow: hidden;
                        user-select: none;
                        gap: 1rem;
                        ul.__usp {
                            flex-shrink: 0;
                            display: flex;
                            justify-content: flex-start;
                            gap: 1rem;
                            min-width: 100%;
                            position: relative;
                            font-size: 1.3rem;
                            @include breakpoint(small only) {
                                justify-content: space-around;
                                animation: marquee 30s linear infinite;
                            }
                            @include breakpoint(medium) {
                                font-size: 1.4rem;
                            }
                            li {
                                @include icon('check-green', 'left');
                                padding: 0 0 0 2.5rem;
                                margin: 0 2rem 0 0;
                                background-size: 2.2rem;
                                flex-shrink: 0;
                            }
                        }
                        &.desktop-animation {
                            ul.__usp {
                                justify-content: space-around;
                                animation: marquee-desktop 30s linear infinite;
                            }
                        }
                    }
                }
                .dropdown__element {
                    background-size: 2rem;
                    border-radius: .2rem .2rem 0 0;
                    > span, > ul li a {
                        padding: .5rem;
                        gap: .5rem;
                        line-height: 2rem;
                        &:is(span) {
                            padding: .5rem 2rem .5rem .5rem;
                        }
                    }
                    > ul {
                        border-radius: 0 0 .2rem .2rem;
                        li a:hover {
                            text-decoration: underline;
                        }
                    }
                    img {
                        border-radius: .2rem;
                    }
                    &:hover {
                        background-color: color(gray, Gray-200);
                        > ul {
                            background-color: color(gray, Gray-200);
                        }
                    }
                }
            }
        }
        .header__items {
            align-items: center;
            padding: 1rem 0;
            flex-wrap: wrap;
            @include breakpoint(medium) {
                padding: 3rem 0 2rem;
            }
            .header__search-form {
                flex: 1;
                order: 2;
                padding: 0 clamp(1rem, 5vw, 10%);
                display: flex;
                align-items: center;
                .search__component-container {
                    flex: 1;
                    z-index: 3;
                    input {
                        border: .2rem solid color(grey, Gray-100);
                    }
                }
                @include breakpoint(small only) {
                    order: 4;
                    flex-basis: 100%;
                    padding: 0;
                    margin: .5rem 0 0;
                    gap: 1rem;
                }
            }
        }
        .header__navigation {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: .5rem;
            order: 3;
            margin-left: auto;
            a {
                display: flex;
                flex-flow: column;
                align-items: center;
                > span:not(.--label) {
                    position: relative;
                    display: block;
                    height: 3rem;
                    width: 3rem;
                    @include breakpoint(medium) {
                        height: 3.5rem;
                        width: 3.5rem;
                    }
                    .--amount {
                        top: -5px;
                        right: -10px;
                        position: absolute;
                        border-radius: 50%;
                        width: 2rem;
                        height: 2rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 1.1rem;
                        background: color(base, Primary);
                        z-index: 1;
                        @include breakpoint(medium) {
                            width: 2.5rem;
                            height: 2.5rem;
                            font-size: 1.6rem;
                        }
                    }
                }
                .--label {
                    text-transform: lowercase;
                    font-size: .9rem;
                    line-height: normal;
                    display: block;
                    margin: 0 .2rem .2rem;
                    @include breakpoint(medium) {
                        font-size: 1.1rem;
                    }
                }
            }
        }
        .mobile__navigation--toggle {
            overflow: hidden;
            button {
                font-size: .8rem;
                text-align: center;
                line-height: 1rem;
            }
        }
    }
}
