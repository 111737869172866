@use 'sass:math';

* {
    font-family: 'Gotham Rounded', sans-serif;
    font-weight: 100;
    font-feature-settings: "ss01";
    -webkit-font-smoothing: antialiased;
    -ms-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -o-font-smoothing: antialiased;
}

ul {
    padding-inline-start: 0;
}

html, body {
    font-size: 62.5%;
    color: color(base, DefaultFontColor);
}

h1, h2 {
    font-family: 'True North Textures', sans-serif;
    margin: 0;
}

a {
    color: inherit;
    text-decoration: inherit;
    font-size: 1.4rem;
    line-height: 2.1rem;
}

p, strong {
    font-size: 1.4rem;
    line-height: 2.1rem;
}

strong {
    font-weight: 700;
}

.align-center {
    align-items: center;
}

.flex {
    display: flex;
}

.--hidden {
    display: none;
}

@include breakpoint(small only) {
    .grid-container {
        padding-left: $grid__gutter;
        padding-right: $grid__gutter;
        .grid-container {
            padding-left: math.div($grid__gutter, 2);
        }
    }
    .no-padding--mobile {
        padding: 0;
    }
}

.usp__list ul li, .__usp li {
    @include usp;
}

.no-scroll {
    overflow-y: hidden !important;
    position: inherit;
}

p.--secure {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-5 w-5' viewBox='0 0 20 20' fill='#{color(alert, Success)}'%3E%3Cpath fill-rule='evenodd' d='M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z' clip-rule='evenodd' /%3E%3C/svg%3E");
    background-repeat: no-repeat;
    color: color(alert, Success);
    background-size: 1.8rem;
    padding-left: 2.5rem;
}

.label__tooltip {
    display: block;
    width: 1.8rem;
    height: 1.8rem;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-5 w-5' viewBox='0 0 20 20' fill='currentColor'%3E%3Cpath fill-rule='evenodd' d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z' clip-rule='evenodd' /%3E%3C/svg%3E");
    cursor: help;
}

.vue-tooltip {
    font-size: 1.4rem;
    line-height: 2rem;
}

.video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 3rem;
    height: 0;
    overflow: hidden;
    iframe, object, embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.field-success {
    font-size: 1.3rem;
    margin: 1rem 0 0;
    display: block;
    color: color(alert, Success);
}

@include breakpoint(small only) {
    .tf-v1-sidetab.ready.open {
        top: 4rem !important;
        left: 4rem !important;
        right: 4rem !important;
        bottom: 4rem !important;
        max-width: calc(100% - 8rem);
        max-height: calc(100% - 8rem);

        &::before {
            content: '';
            position: absolute;
            top: -4rem;
            left: -4rem;
            right: -4rem;
            bottom: -4rem;
            background: rgba(0, 0, 0, .5);
        }
    }
}