
.receipt {
    background-color: color(gray, Gray-soft);
    border-radius: 1.2rem;
    padding: 1.6rem;
    top: 4rem;

    @include breakpoint(small only){
        width: 100%;
        border-radius: 0;
        padding:0;
        margin-top:2rem;
        .cart.--mini{
            width: 100vw;
            margin-left:-2rem;
        }
    }
    @include breakpoint(medium){
        position: sticky;
    }
    h2 {
        font-size: 2rem;
        margin-bottom: .8rem;

    }
    .cart.--mini{
        @include breakpoint(small only){
            h2 {
                background: white;
                margin-bottom: 0;
                padding: 1.6rem;
                border-bottom: .1rem solid color(gray, Gray-100);
            }
        }
    }
    .checklist {
        list-style: none;

        li {
            vertical-align: middle;
            font-size: 1.3rem;
            margin-bottom: .8rem;

            &:before {
                vertical-align: middle;
                content: '';
                display: inline-block;
                height: 2rem;
                width: 2rem;
                background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="11" viewBox="0 0 16 11" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M15.3359 0.317185C15.539 0.520341 15.6531 0.795841 15.6531 1.0831C15.6531 1.37036 15.539 1.64586 15.3359 1.84902L6.66927 10.5157C6.46612 10.7188 6.19062 10.8329 5.90335 10.8329C5.61609 10.8329 5.34059 10.7188 5.13744 10.5157L0.804104 6.18235C0.606766 5.97803 0.497573 5.70438 0.500041 5.42033C0.502509 5.13629 0.616442 4.86457 0.817301 4.66372C1.01816 4.46286 1.28987 4.34892 1.57392 4.34646C1.85797 4.34399 2.13162 4.45318 2.33594 4.65052L5.90335 8.21794L13.8041 0.317185C14.0073 0.114092 14.2828 0 14.57 0C14.8573 0 15.1328 0.114092 15.3359 0.317185Z" fill="%2300C853"/></svg>');
                background-size: 1.5rem;
                background-position: center center;
                background-repeat: no-repeat;
                margin-right: .6rem;
            }
        }
    }
    .cart {
        &.--mini {
            ul {
                padding: 0 2rem;
                background-color: color(base, White);
                margin-bottom: 2rem;
                overflow: auto;
                @include breakpoint(medium){
                    border-radius: 1.2rem;
                    max-height: 50rem;

                }
                li {
                    display: flex;
                    flex-direction: row;
                    padding: 1.6rem 0;
                    &:not(:first-child) {
                        border-top: 2px solid color(gray, Gray-soft);
                    }
                    .image__holder {
                        display: inline-flex;
                        padding: .5rem;
                        aspect-ratio: 1;
                        object-fit: contain;
                        img {
                            max-width: unset;
                            display: flex;
                            height: 9.5rem;
                            width: 9.5rem;
                        }
                        @include breakpoint(small only) {
                            img{
                                width: 7rem;
                                height: 7rem;
                            }
                        }
                    }
                    .cart__item__info {
                        font-size: 1.6rem;
                        line-height: 2.4rem;
                        font-weight: 700;
                        .--title {
                            font-size: 1.6rem;
                            line-height: 2.4rem;
                            font-weight: 700;
                        }
                        .item__status {
                            font-size: 1.3rem;
                            display: block;
                            margin: .8rem 0;

                            > div {
                                &:before {
                                    background-position: 50%;
                                    background-repeat: no-repeat;
                                    background-size: 1.5rem;
                                    content: "";
                                    display: inline-block;
                                    height: 2rem;
                                    margin-right: .6rem;
                                    vertical-align: middle;
                                    width: 2rem;
                                }
                            }

                            .--in-stock {
                                color: color(base, Green);

                                &:before {
                                    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="11" viewBox="0 0 16 11" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M15.3359 0.317185C15.539 0.520341 15.6531 0.795841 15.6531 1.0831C15.6531 1.37036 15.539 1.64586 15.3359 1.84902L6.66927 10.5157C6.46612 10.7188 6.19062 10.8329 5.90335 10.8329C5.61609 10.8329 5.34059 10.7188 5.13744 10.5157L0.804104 6.18235C0.606766 5.97803 0.497573 5.70438 0.500041 5.42033C0.502509 5.13629 0.616442 4.86457 0.817301 4.66372C1.01816 4.46286 1.28987 4.34892 1.57392 4.34646C1.85797 4.34399 2.13162 4.45318 2.33594 4.65052L5.90335 8.21794L13.8041 0.317185C14.0073 0.114092 14.2828 0 14.57 0C14.8573 0 15.1328 0.114092 15.3359 0.317185Z" fill="%2300C853"/></svg>');
                                }
                            }

                            .--out-of-stock {
                                color: color(base, Red);

                                &:before {
                                    content: none;
                                }
                            }

                            .--low-stock {
                                &:before {
                                    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="11" viewBox="0 0 16 11" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M15.3359 0.317185C15.539 0.520341 15.6531 0.795841 15.6531 1.0831C15.6531 1.37036 15.539 1.64586 15.3359 1.84902L6.66927 10.5157C6.46612 10.7188 6.19062 10.8329 5.90335 10.8329C5.61609 10.8329 5.34059 10.7188 5.13744 10.5157L0.804104 6.18235C0.606766 5.97803 0.497573 5.70438 0.500041 5.42033C0.502509 5.13629 0.616442 4.86457 0.817301 4.66372C1.01816 4.46286 1.28987 4.34892 1.57392 4.34646C1.85797 4.34399 2.13162 4.45318 2.33594 4.65052L5.90335 8.21794L13.8041 0.317185C14.0073 0.114092 14.2828 0 14.57 0C14.8573 0 15.1328 0.114092 15.3359 0.317185Z" fill="%23FF9D0E"/></svg>');
                                }

                                color: color(base, Orange);
                            }
                        }
                        .price__holder {
                            display: flex;
                            gap: 1rem;
                            .old__price {
                                display: block;
                                line-height: 2rem;
                                font-size: 1.4rem;
                                text-decoration: line-through;
                            }

                            .price {
                                display: block;
                                font-weight: 700;
                            }
                        }
                    }
                }
            }

        }
    }
    .cart__totals {
        padding: 1.6rem 2rem;
        background-color: color(base, White);
        border-radius: 1.2rem;
        margin-bottom: 2rem;
        @include breakpoint(medium) {
            padding: 2rem 3rem;
            padding-bottom: 4rem;

        }
        div {
            display: flex;
            font-size: 1.4rem;
            @include breakpoint(medium) {
                font-size: 1.6rem;
            }

            &.--grand-total {
                span {
                    font-size: 1.6rem;
                    line-height: 2.4rem;

                    &:first-child {
                        font-weight: 700;
                    }
                }
            }

            &.--total, &.--grand-total {
                border-top: 1px solid color(gray, Gray-150);
                margin-top: .8rem;
            }

            &.--grand-total {
                padding-top: .8rem;
                padding-bottom: .8rem;
            }

            &.--discount {

                &.--first-discount {
                    .--discount-value{
                        margin-bottom: .8rem;
                    }
                }

                span:first-child {
                    font-weight: bold;
                }
            }

            span {
                display: block;
                line-height: 3.2rem;
                font-size: 1.4rem;

                &:last-child {
                    margin-left: auto;
                }

                &.--free {
                    font-weight: 700;
                    color: color(alert, Success);
                }

                &.--discount-value {
                    background-image: url("data:image/svg+xml,%3Csvg width='166' height='40' viewBox='0 0 166 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.976198 0.962897C0.976198 0.962897 0.976198 1.00779 0.976198 1.02703C1.10918 1.77091 1.24217 2.50839 1.42835 3.25228C1.42835 3.30358 1.42835 3.34847 1.42835 3.39977L1.29536 7.24747C1.21557 9.76771 0.843212 12.3072 1.50814 14.821C1.56134 15.0198 1.64113 15.2186 1.72092 15.4174C1.56134 16.995 0.76342 28.9806 0.258073 30.3337C-0.273871 31.7445 0.151684 33.181 0.337865 34.5982C0.524045 36.0988 0.736823 37.6058 0.923004 39.1064C0.949601 39.4078 1.9071 39.6258 3.13057 39.6451C28.0788 39.9593 53.1067 40.0106 78.0815 39.9401C80.0231 39.9593 81.9381 39.9721 83.8797 39.985C89.5183 40.0234 95.1569 39.9914 100.742 39.8503C107.87 39.8118 115.025 39.7733 122.153 39.7285C128.217 39.69 134.281 39.6515 140.372 39.613C145.798 39.7285 151.224 39.8439 156.65 39.8824C159.043 39.8952 163.352 39.9721 165.081 39.4591C166.039 39.1769 165.879 38.8307 165.773 38.4844C165.613 38.0291 165.773 34.2006 165.64 32.918C165.507 31.6675 164.868 30.4363 164.23 29.205V27.9417C164.23 27.9417 165.347 18.1172 165.028 17.5336C165.187 15.9625 165.32 14.3849 165.453 12.8074C166.065 12.1212 165.773 9.56891 165.64 9.00459C165.666 6.89476 165.613 4.79136 165.32 2.68154C165.241 2.04666 165.932 0.918007 163.831 0.33444C161.996 -0.133697 158.777 0.0202108 156.357 0.0394493L133.67 0.212596C128.35 0.251073 123.057 0.295963 117.738 0.33444H93.8537C74.2515 0.28955 54.6228 0.24466 35.0206 0.219009C29.6746 0.212596 24.302 0.206183 18.9293 0.19977C16.4558 0.19977 12.9449 0.19977 10.498 0.19977C8.74259 0.19977 6.61481 0.129229 4.85939 0.251073C4.753 0.251073 4.67321 0.251073 4.56682 0.251073C4.08807 0.251073 3.60932 0.263898 3.13057 0.263898C1.69432 0.270311 0.57724 0.603778 1.02939 0.956484' fill='%23FFD400'/%3E%3C/svg%3E%0A");
                    background-size: cover;
                    background-repeat: no-repeat;
                    padding: 0 .5rem;
                    font-size: 1.6rem;
                    font-family: 'True North Textures', sans-serif;
                }
            }

            &.shipping__message {
                display: block;
                margin: .5rem 0 0;
                background: color(gray, Gray-150);
                border-radius: .4rem;
                font-size: 1.3rem;
                line-height: 2.2rem;
                padding: 1.2rem 1.2rem;
                text-align: center;

                .shipping__bar {
                    height: 1rem;
                    width: 100%;
                    background-color: color(base, White);
                    border-radius: .3rem;
                    margin: 2rem 0 4rem 0;
                    position: relative;

                    .--bar-fill {
                        animation: 1s ease-in 0s 1 slideInOffset;
                        width: 100%;
                        border-radius: .3rem;
                        background-color: color(base, Primary);
                        position: relative;
                    }

                    .--icon {
                        padding: 0.7rem;
                        background-color: color(base, Primary);
                        border-radius: 50%;
                        height: 3rem;
                        width: 3rem;
                        top: -1rem;
                        margin-left: -1.5rem;
                        position: absolute;
                        right: 0;
                        z-index: 1;
                    }

                    .--text {
                        font-size: 1.4rem;
                        min-width: 4em;
                        position: absolute;
                        right: -0.7em;
                        top: 0.8em;
                        text-align: center;
                        display: inline-block;
                    }
                }
            }
        }

        p {
            margin: 2rem 0 0;
            font-size: 1.2rem;
            line-height: 1.6rem;

            a {
                text-decoration: underline;
                font-size: 1.2rem;
                line-height: 1.6rem;
            }
        }

        .--actions {
            display: flex;
            flex-flow: column;
            margin: 1.2rem 0 0;
            gap: 1rem;

            flex-flow: wrap;
            flex-flow: wrap;
            align-items: center;

            button {
                text-transform: initial;
                width: 100%;
                text-align: center;
                margin-left: auto;
                font-size: 1.6rem;
                display: block;
                height: 4.8rem;
            }

            p {
                margin: 1rem 0 0;
            }

            > a {
                color: color(base, Tertiary);
                line-height: 3.2rem;
                font-size: 1.3rem;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='#{color(base, Tertiary)}' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M15 19l-7-7 7-7' /%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: top .8rem left;
                background-size: 1.4rem;
                padding: 0 0 0 1.8rem;
            }

            p {
                font-size: 1.2rem;
                max-width: 40rem;
                line-height: 1.6rem;

                a {
                    font-size: 1.2rem;
                    line-height: 1.6rem;
                    text-decoration: underline;
                }
            }
        }
    }
}

.checkout-checkout{
    @include breakpoint(medium){
        .receipt .cart__totals{
            padding-bottom: 2rem;
            .--shipping{
                flex-wrap: wrap;
                justify-content: space-between;
                > div{
                    width: 100%;
                }
                p{
                    margin-top:0;
                    font-size:1rem;
                }
            }

        }
    }
    .receipt .cart__totals{
        padding-bottom: 2rem;
        .--shipping{
            flex-wrap: wrap;
            justify-content: space-between;
            > div{
                width: 100%;
            }
            p{
                margin-top:0;
                font-size:1rem;
            }
        }
        }
}
