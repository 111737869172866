@font-face {
    font-family: 'True North Textures';
    src: local('True North Textures'), local('TrueNorthTextures-Regular'),
    url('/area/web/default/assets/fonts/TrueNorthTextures-Regular.woff2') format('woff2'),
    url('/area/web/default/assets/fonts/TrueNorthTextures-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'Gotham Rounded';
    src: url('/area/web/default/assets/fonts/GothamRndSSm-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: fallback;
}
@font-face {
    font-family: 'Gotham Rounded';
    src: local('GothamRounded-Book'),
    url('/area/web/default/assets/fonts/GothamRounded-Book.woff2') format('woff2'),
    url('/area/web/default/assets/fonts/GothamRounded-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: 'Gotham Rounded';
    src: local('GothamRounded-Bold'),
    url('/area/web/default/assets/fonts/GothamRounded-Bold.woff2') format('woff2'),
    url('/area/web/default/assets/fonts/GothamRounded-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: fallback;
}

