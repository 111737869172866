$sl-font-family: 'Roboto Slab',sans-serif;
$sl-overlay-background: color(base, Black);

.sl-wrapper {
    .sl-close {
        @include transition;
        display: none;
        background-color: color(base, White);
        border-radius: 50%;
        background-size: 3rem;
        background-repeat: no-repeat;
        background-position: center;
        border: .1rem solid transparent;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M6 18L18 6M6 6l12 12' /%3E%3C/svg%3E%0A");
    }

    .sl-navigation {
        button {
            @include transition;
            background-color: color(base, White);
            border-radius: 50%;
            background-size: 3rem;
            background-repeat: no-repeat;
            background-position: center;
            border: .1rem solid transparent;

            @include breakpoint(small only) {
                width: 4.4rem !important;
            }

            &.sl-prev {
               display: block !important;
               background: hsla(0,0%,100%,1) url("data:image/svg+xml;charset=utf-8,%3Csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.295 2.038a.889.889 0 0 1 0 1.257L6.59 8l4.705 4.705a.889.889 0 1 1-1.257 1.257L4.705 8.629a.889.889 0 0 1 0-1.257l5.333-5.334a.889.889 0 0 1 1.257 0Z' fill='%23000'/%3E%3C/svg%3E") 50%/1.6rem no-repeat;
            }
            
            &.sl-next {
               display: block !important;
               background: hsla(0,0%,100%,1) url("data:image/svg+xml;charset=utf-8,%3Csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.705 13.962a.889.889 0 0 1 0-1.257L9.41 8 4.705 3.295a.889.889 0 0 1 1.257-1.257l5.333 5.333a.889.889 0 0 1 0 1.257l-5.333 5.334a.889.889 0 0 1-1.257 0Z' fill='%23000'/%3E%3C/svg%3E") 50%/1.6rem no-repeat;
            }
        }
    }
}
