button, input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
}

input[type='text'], input[type='search'], input[type='date'], input[type='tel'], input[type='email'], input[type='number'], input[type='password'], select, textarea {
    @include border-radius();
    border: .1rem solid color(base, Black);
    padding: 0 2rem;
    color: color(base, DefaultFontColor);
    background-color: color(base, White);
    line-height: 3rem;
    height: 3.2rem;
    font-size: 1.6rem;
    @include breakpoint(medium) {
        line-height: 3.8rem;
        height: 4rem;
    }
    &[type='date'] {
        align-items: center;
    }
}

fieldset:not(:first-of-type) {
    margin: 2rem 0 0;
}

[type=checkbox]:not([class^=Cybot]), [type=radio] {
    position: absolute;
    left: -999999rem;
}

select {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m19 9-7 7-7-7'/%3E%3C/svg%3E");
    background-size: 2rem;
    background-repeat: no-repeat;
    background-position: right 1.5rem center;
    padding: 0 3.5rem 0 2rem;

    @include breakpoint(medium) {
        padding: 0 4rem 0 2rem;
    }
}

textarea {
    height: auto;
    line-height: 2rem;
    padding: 1rem 2rem;
}

button {
    cursor: pointer;
}

input[type='checkbox'] {
    + label {
        display: flex;
        align-items: center;
        position: relative;
        gap: .5rem;
        cursor: pointer;
        &::before {
            content: '';
            border: .1rem solid color(base, Black);
            border-radius: .4rem;
            height: 2rem;
            width: 2rem;
        }
        @include breakpoint(medium) {
            &:hover::after {
                content: '';
                position: absolute;
                left: .4rem;
                width: 1rem;
                height: 1rem;
                background: color(base, Primary);
                display:none;
            }
        }
    }
    &:checked + label::after {
        content: '';
        position: absolute;
        background: color(base, Primary);
        border-radius: .2rem;
        display: block;
        height: 1.2rem;
        left: .4rem;
        width: 1.2rem;
    }
}

input[type='radio'] {
    + label {
        display: flex;
        align-items: center;
        position: relative;
        gap: .5rem;
        cursor: pointer;
        &::before {
            content: '';
            width: 2rem;
            height: 2rem;
            border: .1rem solid color(base, DarkFontColor);
            border-radius: 50%;
            background: color(base, White);
        }
        @include breakpoint(medium) {
            &:hover::after {
                content: '';
                position: absolute;
                left: .6rem;
                width: 1.2rem;
                height: 1.2rem;
                border-radius: 50%;
                background: color(base, Primary);
            }
        }
    }
    &:checked + label::after {
        content: '';
        position: absolute;
        left: .4rem;
        width: 1.2rem;
        height: 1.2rem;
        border-radius: 50%;
        background: color(base, Primary);
    }
}

.field__container {
    margin: 1rem 0 0;
    &.--flex {
        display: flex;
        gap: 2rem;
    }
    &.--margin-top {
        margin: 2rem 0 0;
    }
    label {
        font-size: 1.4rem;
        margin: 0 0 .5rem;
    }
    input[type='text'], input[type='search'], input[type='tel'], input[type='email'], input[type='number'], input[type='password'], select {
        width: 100%;
    }
}

.field__actions {
    margin: 2rem 0 0;
    display: flex;
    align-items: center;
    a:not(.--button) {
        margin-left: 1.6rem;
    }
    &.--column {
        flex-flow: column;
        align-items: flex-start;
        a:not(.--button) {
            margin: 1rem 0 0 0;
            color: color(base, Tertiary);
            @include breakpoint(medium) {
                &:hover {
                    color: color(base, TertiaryHover);
                }
            }
        }
    }
}

.search__component-container {
    position: relative;
    .search__component {
        display: flex;
        position: relative;
        @include search-bar;
        .--clear {
            width: 2.2rem;
            height: 2.2rem;
            position: absolute;
            right: 4rem;
            top: 50%;
            transform: translateY(-50%);
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-5 w-5' viewBox='0 0 20 20' fill='#{color(gray, Gray-200)}'%3E%3Cpath fill-rule='evenodd' d='M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z' clip-rule='evenodd' /%3E%3C/svg%3E");
        }
    }
}

.vue-slider {
    margin: 0 1rem;
    .vue-slider-rail {
        background-color: color(gray, Gray-100);
        .vue-slider-process {
            background-color: color(base, Black);
        }
        .vue-slider-dot {
            z-index: 4;
            height: 2.4rem;
            width: 2.4rem;
            .vue-slider-dot-handle {
                box-shadow: none;
                background-color: color(base, Black);
            }
        }
        .vue-slider-dot-tooltip {
            .vue-slider-dot-tooltip-inner {
                padding: .5rem 1rem;
                background-color: color(gray, Gray-100);
                border-color: color(gray, Gray-100);
                color: color(base, DefaultFontColor);
            }
        }
    }
}
