.shop-by-look-index {
    @include breakpoint(small only) {
        .breadcrumbs {
            display: none;
        }
    }
    .shop-by-look__collection {
        h1 {
            font-size: 2.6rem;
            line-height: 4.6rem;
        }
        .--header {
            align-items: center;
            margin: 0 0 2rem;
            > h2, > span {
                @include block-title;
                margin: 0 !important;
                text-align: left;
                flex: 0 0 50%;
            }
            a {
                margin-left: auto;
            }
        }
        .--back {
            display: inline-block;
            margin: 2rem 0 0;
            @include breakpoint(medium) {
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        .shop-by-look__series {
            margin: 2rem 0 0;
            position: relative;
            .block__placeholder {
                position: relative;
                background-color: color(base, Placeholder);
                background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3Csvg xmlns:svg='http://www.w3.org/2000/svg' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.0' width='20px' height='20px' viewBox='0 0 128 128' xml:space='preserve'%3E%3Cg%3E%3Ccircle cx='16' cy='64' r='16' fill='%23000000' fill-opacity='1'/%3E%3Ccircle cx='16' cy='64' r='16' fill='%23555555' fill-opacity='0.67' transform='rotate(45,64,64)'/%3E%3Ccircle cx='16' cy='64' r='16' fill='%23949494' fill-opacity='0.42' transform='rotate(90,64,64)'/%3E%3Ccircle cx='16' cy='64' r='16' fill='%23cccccc' fill-opacity='0.2' transform='rotate(135,64,64)'/%3E%3Ccircle cx='16' cy='64' r='16' fill='%23e1e1e1' fill-opacity='0.12' transform='rotate(180,64,64)'/%3E%3Ccircle cx='16' cy='64' r='16' fill='%23e1e1e1' fill-opacity='0.12' transform='rotate(225,64,64)'/%3E%3Ccircle cx='16' cy='64' r='16' fill='%23e1e1e1' fill-opacity='0.12' transform='rotate(270,64,64)'/%3E%3Ccircle cx='16' cy='64' r='16' fill='%23e1e1e1' fill-opacity='0.12' transform='rotate(315,64,64)'/%3E%3CanimateTransform attributeName='transform' type='rotate' values='0 64 64;315 64 64;270 64 64;225 64 64;180 64 64;135 64 64;90 64 64;45 64 64' calcMode='discrete' dur='720ms' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/g%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: center;
                height: 3.6rem;
            }
            .--navi {
                position: absolute;
                top: calc(50% - 4rem);
                transform: translateY(-50%);
                button {
                    height: 4rem;
                    width: 4rem;
                }
                &.--prev {
                    left: 0.5rem;
                }
                &.--next {
                    right: 0.5rem;
                }
            }
            .swiper-wrapper {
                margin: 0 -.5rem;
                .swiper-slide {
                    width: auto;
                    padding: 0 .5rem;
                }
            }
        }
        .collection__looks {
            margin: 2rem 0 0;
            .collection__item {
                display: block;
                margin: 0 0 2rem;
                @include breakpoint(small only){
                    margin: 0;
                }
                .image__holder {
                    position: relative;
                    display: block;
                    border-radius: 2.2rem;
                    overflow: hidden;
                    picture {
                        display: flex;
                        img {
                            transition: transform .3s ease-in-out;
                            width:100%;
                            @include breakpoint(small only) {
                                aspect-ratio: 121/160;
                            }
                        }
                    }
                    .--spot {
                        display: flex;
                        position: absolute;
                        justify-content: center;
                        width: 3rem;
                        height: 3rem;
                        background: color(base, Primary);
                        border: .25rem solid color(base, PrimaryHover);
                        border-radius: 50%;
                        font-size: 1.8rem;
                        line-height: 2.4rem;
                        z-index: 1;
                    }
                    .--discount {
                        position: absolute;
                        background: color(base, Secondary);
                        color: color(base, White);
                        font-size: 1.1rem;
                        line-height: 2.2rem;
                        padding: 0 1rem;
                        left: 50%;
                        transform: translate(-50%);
                        bottom: 1rem;
                        text-transform: uppercase;
                        white-space: nowrap;
                    }
                }
                h2 {
                    display: block;
                    text-align: center;
                    padding: 0 1rem;
                    margin: 1rem 0;
                }
                @include breakpoint(medium) {
                    &:hover {
                        img {
                            transform: scale(1.05);
                        }
                        h2 {
                            text-decoration: underline;
                        }
                    }
                }
            }
            .blaze-slider{
                width: 100%;
            }
            .blaze-container{
                overflow: visible;
            }
        }
    }
    .--footer{
        text-align: center;
        a{
            color: color(base, Tertiary);
            text-decoration:underline;
        }
    }
}

.shop-by-look-detail {
    @include breakpoint(small only) {
        .breadcrumbs {
            display: none;
        }
    }
    .shop-by-look__detail {
        h1 {
            font-size: 2rem;
            line-height: 4.6rem;
        }
        .shop-by-look__detail__image {
            section {
                position: relative;
                display: block;
                picture {
                    display: flex;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .hotspot__item__content {
                    &.--active .--active {
                        transform: rotate(0) scale(1.2);
                    }
                    .show__hotspot {
                        font-size: 2rem;
                    }
                }
            }
        }
        .shop-by-look__detail__products {
            .--title {
                font-family: 'True North Textures', sans-serif;
                text-transform: uppercase;
                font-size: 2.6rem;
                line-height: 4.6rem;
                display: block;
            }
            .shop-by-look__detail__products__list {
                margin: 1rem 0 0;
                border-top: .2rem solid color(gray, Gray-100);
                .global__message--bg {
                    margin-top: 2rem;
                }
                .--item {
                    display: flex;
                    gap: 2rem;
                    padding: 1rem 0;
                    border-bottom: .1rem solid color(gray, Gray-100);
                    position: relative;
                    span {
                        display: block;
                        &.--count {
                            align-items: center;
                            display: flex;
                            font-size: 2rem;
                            font-weight: 700;
                            @include breakpoint(small only) {
                                position: absolute;
                                font-size: 1.2rem;
                                top: .5rem;
                                left: 0;
                            }
                        }
                        &.image__holder {
                            a {
                                display: flex;
                                img {
                                    aspect-ratio: 1;
                                }
                            }
                        }
                        &.product__content {
                            flex: 1;
                            display: flex;
                            flex-flow: column;
                            h2 {
                                flex: 1;
                                a {
                                    display: block;
                                    color: color(base, Tertiary);
                                    margin: 0 0 2rem;
                                    font-size: 1.6rem;
                                    @include breakpoint(medium) {
                                        font-size: 1.8rem;
                                        &:hover {
                                            color: color(base, TertiaryHover);
                                        }
                                    }
                                }
                            }
                            .price {
                                display: flex;
                                gap: 1rem;
                                font-size: 2.1rem;
                                line-height: 3.6rem;
                                font-weight: 700;
                                justify-content: space-between;
                                @include breakpoint(small only) {
                                    justify-content: flex-end;
                                    font-size: 1.9rem;
                                }
                                .price__old-price {
                                    text-decoration: line-through;
                                    font-size: 1.4rem;
                                    @include breakpoint(medium) {
                                        font-size: 1.6rem;
                                    }
                                }
                                .price__special-price {
                                    font-weight: 700;
                                }
                            }
                        }
                        &.--action {
                            display: flex;
                            align-items: flex-end;
                        }
                    }
                    button {
                        @include default-button;
                        width: 3.6rem;
                        height: 3.6rem;
                        padding: 0;
                        background-image: url("data:image/svg+xml,%3Csvg width='25' height='29' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M7.971 10.65V4.098c0-1.103.954-2.487 2.118-3.075 1.165-.588 3.011-.468 4.104.267 1.09.736 2.164 2.12 2.382 3.076.218.955.396 2.761.396 4.011v2.273' stroke='%231E1E1C' stroke-width='1.3' stroke-linecap='round'/%3E%3Cpath stroke='%23020203' stroke-width='2' d='m3.333 6.445-.941 2.167-.726 8.186L1 25.278v2.667h22.666l-2-21.5H14z'/%3E%3Cpath d='M9.11 10.364a1.139 1.139 0 1 1-2.278-.002 1.139 1.139 0 0 1 2.278.002' fill='%23000'/%3E%3Cpath d='M18.11 10.364a1.139 1.139 0 1 1-2.278-.002 1.139 1.139 0 0 1 2.278.002' fill='%23020203'/%3E%3C/g%3E%3C/svg%3E");
                        background-size: 1.8rem;
                        background-repeat: no-repeat;
                        background-position: center;
                        &.--loading-cart {
                            background-image: url('../assets/images/loader.svg');
                            background-color: color(gray, Gray-100);
                            cursor: not-allowed;
                        }
                    }
                    .--out-of-stock {
                        color: color(alert, Error);
                        font-size: 1.3rem;
                    }
                }
            }
            .discount__message {
                margin: 2rem 0 0;
                color: color(base, White);
                text-transform: uppercase;
                background: color(base, Secondary);
                font-weight: 700;
                padding: .3rem 1rem;
                font-size: 1.1rem;
                line-height: 2rem;
                text-align: center;
            }
            .--actions {
                display: flex;
                font-size: 1.4rem;
                margin: 2rem 0 0;
                .--prices {
                    display: flex;
                    align-items: center;
                    gap: .5rem;
                    span {
                        font-size: 2rem;
                        display: block;
                        margin-right: .5rem;
                        @include breakpoint(small only) {
                            display: none;
                        }
                    }
                    .--old-price {
                        font-size: 1.4rem;
                        @include breakpoint(medium) {
                            font-size: 1.7rem;
                        }
                        text-decoration: line-through;
                    }
                    .--price {
                        font-size: 2rem;
                        @include breakpoint(medium) {
                            font-size: 2.7rem;
                        }
                        font-weight: 700;
                    }
                }
                .add-to-cart {
                    margin-left: auto;
                    button {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M16 11V7a4 4 0 0 0-8 0v4M5 9h14l1 12H4L5 9z'/%3E%3C/svg%3E");
                        background-size: 3rem;
                        background-repeat: no-repeat;
                        background-position: left 1rem center;
                        padding-left: 4.5rem;
                        @include breakpoint(medium) {
                            line-height: 4.6rem;
                            height: 4.8rem;
                            font-size: 1.8rem;
                        }
                    }
                }
            }
        }
    }
}
