.swiper-container {
    width: 100%;
    .swiper-slide a{
        padding:1.2rem 2rem;
        height: auto;
        border-radius: 10rem;
        line-height: 2.4rem;
        text-transform: math-auto;
    }
}

.swiper-pagination-fraction {
    right: 0;
    bottom: 1rem;
    left: 0;
    font-size: 1.3rem;
    color: color(gray, Gray-500);
    line-height: 1.5rem;
    > span {
        width: auto;
        margin: 0;
        background: none;
        line-height: 1.5rem;
        color: color(gray, Gray-500);
    }
}

.swiper-navigation {
    display: block;
    width: 4rem;
    height: 4rem;
    background:hsla(0,0%,100%,.7) url("data:image/svg+xml;charset=utf-8,%3Csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.295 2.038a.889.889 0 0 1 0 1.257L6.59 8l4.705 4.705a.889.889 0 1 1-1.257 1.257L4.705 8.629a.889.889 0 0 1 0-1.257l5.333-5.334a.889.889 0 0 1 1.257 0Z' fill='%23000'/%3E%3C/svg%3E") 50%/1.6rem no-repeat;
    box-shadow: 0 4px 8px rgba(0,0,0,.08);
    border-radius: 50%;
    border: .1rem solid transparent;
    right: .5rem;
    &.--prev {
        transform: scaleX(-1);
        right: auto;
        left: .5rem;
    }
    &.swiper-button-disabled {
        opacity: .4;
        cursor: default;
        &:hover {
            opacity: .4;
        }
    }
    @include breakpoint(medium) {
        &:hover {
            opacity: .7;
        }
    }
}

.swiper__progress {
    background-color: color(gray, Gray-100);
    height: .5rem;
    margin: 1rem 0 0;
    position: relative;
    .swiper-pagination-progressbar-fill {
        background: color(base, Primary);
    }
}

.swiper__progress.--dots {
    background-color: transparent;
    position: relative;
    height: auto;
    display:flex;
    justify-content: center;
    z-index:2;
    gap:.4rem;
    margin-bottom: 2.4rem;
    padding-bottom: 2.4rem;
    border-bottom:.1rem solid color(gray, Gray-100);
    .swiper-pagination-bullet{
        background-color: color(gray, Gray-100);
        opacity: 1;
    }
    .swiper-pagination-bullet-active{
        background-color: color(base, Primary);
    }

    span {
        border-radius: 10rem;
        width: 1.2rem;
        height: 1.2rem;
        margin: 0 .2rem;
        background: color(gray, Gray-100);
        opacity: 1;
        &.swiper-pagination-bullet-active {
            background: color(base, Primary);
        }
        @include breakpoint(small only) {
            width: .8rem;
            height: .8rem;
        }
    }
}

.blaze-pagination {
    display: flex;
    margin: 1rem 0 0;

    button {
        @include transition();
        flex: 1;
        background-color: color(base, Primary);
        text-indent: -999rem;
        height: .5rem;

        &.active ~ button {
            background-color: color(gray, Gray-100);
        }

        &.active {
            background-color: color(base, Primary);
        }
    }
}

.slider-controls {
    display: flex;
    gap: 1rem;
    position: absolute;
    top: -.5rem;
    right: 0;
    z-index: 1;

    button {
        @include transition();
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        height: 4rem;
        width: 4rem;

        &.blaze-prev {
            background-image: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='47.5' y='47.5' width='47' height='47' rx='23.5' transform='rotate(180 47.5 47.5)' fill='white'/%3E%3Crect x='47.5' y='47.5' width='47' height='47' rx='23.5' transform='rotate(180 47.5 47.5)' stroke='black'/%3E%3Cpath d='M27.2002 30.1512L26.2946 31L18.8002 24L26.2946 17L27.2002 17.8444L20.6158 24L27.2002 30.1512Z' fill='black'/%3E%3C/svg%3E%0A");
            left: .5rem;
        }

        &.blaze-next {
            transform: scaleX(1);
            background-image: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='47' height='47' rx='23.5' fill='white'/%3E%3Crect x='0.5' y='0.5' width='47' height='47' rx='23.5' stroke='black'/%3E%3Cpath d='M20.7998 17.8488L21.7054 17L29.1998 24L21.7054 31L20.7998 30.1556L27.3842 24L20.7998 17.8488Z' fill='black'/%3E%3C/svg%3E%0A");
            right: .5rem;
        }

        &.swiper-button-disabled {
            opacity: .4;
        }

        @include hover() {
            opacity: .7;
        }
    }
}
.blaze-slider {
    &.start {
        .--navi.--prev {
            display: none;
        }
    }
    &.end {
        .--navi.--next {
            display: none;
        }
    }
}
