.off-canvas {
    &.--wide {
        width: 100%;
    }

    .modal__header {
        position: relative;
        display: block;
        line-height: 4rem;
        font-size: 1.5rem;
        border-bottom: .1rem solid color(gray, Gray-100);

        .--title {
            font-weight: 700;
        }


        button {
            right: 1rem;
            line-height: 4rem;
            padding: 0;

            &.--left {
                right: auto;
                left: 0;

                &.--chevron {
                    span {
                        display: block;
                        height: 4rem;
                        width: 4rem;
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-5 w-5' viewBox='0 0 20 20' fill='currentColor'%3E%3Cpath fill-rule='evenodd' d='M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z' clip-rule='evenodd' /%3E%3C/svg%3E");
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 2.6rem;
                    }
                }
            }
        }
    }

    .off-canvas--filter {
        .modal__header.--mobile {
            background-color: color(base, White);
            text-transform: uppercase;
            text-align: left;
            padding: 1.5rem;
            display: flex;
            align-items: center;

            .--title {
                font-family: 'Gotham Rounded', sans-serif;
                font-size: 2rem;
                text-transform: capitalize;
            }

            button {
                right: 1.5rem;
                font-size: unset;
                line-height: 1.2rem;
                text-align: center;

                .close__icon {
                    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none"><path d="M1 13L13 1M1 1L13 13" stroke="black" stroke-width="1 " stroke-linecap="round" stroke-linejoin="round"/></svg>');
                    background-position: 50%;
                    background-repeat: no-repeat;
                    background-size: 2.6rem;
                    display: block;
                    margin-top: .8rem;
                    height: 1.6rem;
                    width: 1.6rem;
                    margin: auto;
                }

                .close__text {
                    font-size: .6rem;
                }
            }
        }

        .modal__header.--medium{
            border-bottom: none;
            .--title{
                font-size: 2.4rem;
                font-family: 'True North Textures', sans-serif;
                font-weight: 400;
                padding-left:1.6rem;
            }
        }

        .modal__footer {
            button .--button{
                box-shadow: 0 .4rem 1.2rem 0 rgba(0,0,0,.12);
                display: flex;
                justify-content: center;
                padding:1.2rem;
                line-height: 2.4rem;
            }
        }
    }

    @include breakpoint(small only) {
        .off-canvas__container {
            display: flex;
            flex-direction: column;
            height: 100%;
            left: 0;
            position: fixed;
            top: 0;
            width: 100%;

            .modal__content {
                height: 100%;
                overflow-y: auto;
                -webkit-overflow-scrolling: touch;
            }

            .modal__footer {
                border-top: .1rem solid color(gray, Gray-100);
                padding: 1rem;

                button, button span {
                    width: 100%;
                    text-transform: unset;
                }
            }
        }
    }
}
