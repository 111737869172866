.simple-text__container{
    h1 {
        font-size: 3.2rem;
        line-height: 2.6rem;
        margin-bottom: .8rem;
        @include breakpoint(small only) {
            font-size: 2.4rem;
            line-height: 2.8rem;
        }
    }
    .cta {
        background-color: transparent;
        height: auto;
        line-height: 2.4rem;
        padding: .1rem 1.5rem;
        border: 1px solid color(base, Black);
        font-weight: 400;
        &:hover {
            background-color: color(base, White);
        }
    }
    &.simple-text--padded{
        padding: 2.8rem 4rem;
        border-radius: 1.2rem;
        @include breakpoint(small only){
            padding: 1.2rem 1.6rem;
            margin-top: 2.4rem;
            margin-bottom: 2.4rem;
        }
    }
}
.--first{
    .simple-text__container.simple-text--padded{
        margin-bottom: 0;
    }
}

.home_body {
    .simple-text__container.simple-text--padded{
        margin-top: 0;
    }
}
