.catalog__category-filter {
    background-color: color(gray, Gray-soft);
    padding: .8rem;
    padding-top: 2rem;
    border-radius: .8rem;

    h2 {
        margin-left: 1.6rem;
        font-size: 2.4rem;
        margin-bottom: 2rem;
    }

    .modal__content {
        padding: 1.5rem;

        .subcategory__container {
            @include left-menu;
            border-radius: 2rem;

            .strong {
                font-weight: 700;
            }
        }

        .reset__filters {
            text-align: center;
            margin-top: 1.6rem;

            a, button {
                font-size: 1.4rem;
                justify-content: center;
                font-weight: 600;
                background-color: transparent;
                background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M13.3524 7.78995H17.5124L14.8616 5.13745C14.0081 4.28401 12.9451 3.67028 11.7792 3.35795C10.6134 3.04563 9.38591 3.04572 8.22013 3.35822C7.05435 3.67072 5.99137 4.28461 5.13807 5.13817C4.28477 5.99174 3.67121 7.0549 3.35907 8.22078M2.48741 16.3699V12.21M2.48741 12.21H6.64741M2.48741 12.21L5.13741 14.8624C5.99084 15.7159 7.0539 16.3296 8.21973 16.6419C9.38556 16.9543 10.6131 16.9542 11.7788 16.6417C12.9446 16.3292 14.0076 15.7153 14.8609 14.8617C15.7142 14.0082 16.3278 12.945 16.6399 11.7791M17.5124 3.62995V7.78828" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
                background-repeat: no-repeat;
                background-position: left center;
                background-size: 20px;
                margin-left: 2rem;
                line-height: normal;
                height: auto;
                text-transform: unset;
                @include breakpoint(small only) {
                    font-size: 1.3rem;
                }
                @include breakpoint(medium) {
                    display: none;
                }
            }
        }

        .filter__divider {
            color: color(Gray, Gray-400);
            font-size: 1.2rem;
            text-align: center;
            position: relative;
            margin-bottom: 1.2rem;

            @include breakpoint(small only) {
                margin-bottom: 2.4rem;
            }

            span {
                background-color: color(gray, Gray-soft);
                position: relative;
                padding: 0 .8rem;
            }

            &.--other {
                margin-top: 2rem;
            }

            &:before {
                content: '';
                height: 1px;
                position: absolute;
                top: 50%;
                width: 100%;
                background-color: color(gray, Gray-400);
                left: 0;
            }
        }

        dl {
            background-color: color(base, White);
            border-radius: .8rem;
            padding: 1.6rem;
            margin: 1rem 0 0;

            @include breakpoint(small only) {
                border-radius: 2.4rem;
                .filter__label {
                    font-size: 1.4rem;
                }

            }

            dt {
                display: flex;
                align-items: center;
                line-height: normal;
                font-size: 1.5rem;
                font-weight: 700;
                gap: .5rem;
                cursor: pointer;

                .filter__label {
                    position: relative;

                    &.--active {
                        font-weight: bold;

                        &:before {
                            background-color: color(base, Secondary);
                            content: '';
                            border-radius: 10rem;
                            display: block;
                            position: absolute;
                            right: -1.2rem;
                            top: calc(50% - .3rem);
                            height: .8rem;
                            width: .8rem;
                        }
                    }
                }

                button {
                    color: color(gray, Gray-300);
                }

                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m19 9-7 7-7-7'/%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: right center;
                background-size: 2rem;

                &.--visible {
                    margin-bottom: 1.6rem;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m5 15 7-7 7 7'/%3E%3C/svg%3E");
                }
            }

            dd {
                ol li {
                    label {
                        display: flex;
                        align-items: center;
                        gap: .5rem;
                        line-height: 2.6rem;
                        cursor: pointer;
                        padding-left: .8rem;
                        @include breakpoint(small only) {
                        }

                        span {
                            display: block;
                            font-size: 1.4rem;

                            &.--checkbox {
                                @include checkbox;

                                border-radius: .4rem;
                                height: 2rem;
                                width: 2rem;
                            }

                            &.--count {
                                color: color(gray, Gray-300);
                            }

                            > span {
                                &.--color {
                                    position: relative;
                                    display: inline-block;
                                    width: 3.5rem;
                                    height: 3.5rem;
                                    border-radius: 50%;
                                    border: .1rem solid color(gray, Gray-400);
                                }
                            }
                        }

                        &.--active {
                            span {
                                &.--checkbox {
                                    background-color: color(base, Black);
                                    border-color: color(base, Black);
                                }

                                &.--checkbox::after {
                                    content: '';
                                    height: 1.1rem;
                                    width: 1.5rem;
                                    background-color: color(base, Black);
                                    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="11" viewBox="0 0 16 11" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M15.3359 0.317185C15.539 0.520341 15.6531 0.795841 15.6531 1.0831C15.6531 1.37036 15.539 1.64586 15.3359 1.84902L6.66927 10.5157C6.46612 10.7188 6.19062 10.8329 5.90335 10.8329C5.61609 10.8329 5.34059 10.7188 5.13744 10.5157L0.804104 6.18235C0.606766 5.97803 0.497573 5.70438 0.500041 5.42033C0.502509 5.13629 0.616442 4.86457 0.817301 4.66372C1.01816 4.46286 1.28987 4.34892 1.57392 4.34646C1.85797 4.34399 2.13162 4.45318 2.33594 4.65052L5.90335 8.21794L13.8041 0.317185C14.0073 0.114092 14.2828 0 14.57 0C14.8573 0 15.1328 0.114092 15.3359 0.317185Z" fill="white"/></svg>');
                                    background-position: center center;
                                    background-repeat: no-repeat;
                                    background-size: contain;
                                }
                            }
                        }

                        @include breakpoint(medium) {
                            &:hover {
                                span {
                                    &.--checkbox {
                                        background-color: color(base, Black);
                                        border-color: color(base, Black);
                                    }

                                    &.--checkbox::after {
                                        content: '';
                                        height: 1.1rem;
                                        width: 1.5rem;
                                        background-color: color(base, Black);
                                        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="11" viewBox="0 0 16 11" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M15.3359 0.317185C15.539 0.520341 15.6531 0.795841 15.6531 1.0831C15.6531 1.37036 15.539 1.64586 15.3359 1.84902L6.66927 10.5157C6.46612 10.7188 6.19062 10.8329 5.90335 10.8329C5.61609 10.8329 5.34059 10.7188 5.13744 10.5157L0.804104 6.18235C0.606766 5.97803 0.497573 5.70438 0.500041 5.42033C0.502509 5.13629 0.616442 4.86457 0.817301 4.66372C1.01816 4.46286 1.28987 4.34892 1.57392 4.34646C1.85797 4.34399 2.13162 4.45318 2.33594 4.65052L5.90335 8.21794L13.8041 0.317185C14.0073 0.114092 14.2828 0 14.57 0C14.8573 0 15.1328 0.114092 15.3359 0.317185Z" fill="white"/></svg>');
                                        background-position: center center;
                                        background-repeat: no-repeat;
                                        background-size: contain;
                                    }
                                }
                            }
                        }
                    }
                }

                ol.--swatch {
                    display: flex;
                    flex-wrap: wrap;

                    li {
                        width: 100%;
                    }

                    label {
                        display: flex;

                        .--checkbox {
                            display: none;
                        }

                        .color__container {
                            display: flex;
                            align-items: center;

                            padding: 1rem 1.6rem;
                            width: 100%;
                            border-bottom: .1rem solid color(gray, Gray-100);

                            > span {
                                display: flex;
                                align-items: center;
                                gap: .8rem;
                            }

                            .--title, .--count {
                                font-size: 1.3rem;
                                line-height: 2rem;
                            }

                            .--title {
                                margin-left: .8rem;
                            }

                            .--count {
                                margin-left: auto;
                            }

                            .--color {
                                position: relative;
                                height: 2.4rem;
                                width: 2.4rem;
                                border: .2rem solid;
                                border-color: color(base, White);

                                &._white {
                                    border-color: color(gray, Gray-100);
                                }
                            }

                            .--checkmark {
                                background-size: contain;
                                background-repeat: no-repeat;
                                background-position: center center;
                                background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M15.3359 0.817185C15.539 1.02034 15.6531 1.29584 15.6531 1.5831C15.6531 1.87036 15.539 2.14586 15.3359 2.34902L6.66927 11.0157C6.46612 11.2188 6.19062 11.3329 5.90335 11.3329C5.61609 11.3329 5.34059 11.2188 5.13744 11.0157L0.804104 6.68235C0.606766 6.47803 0.497573 6.20438 0.500041 5.92033C0.502509 5.63629 0.616442 5.36457 0.817301 5.16372C1.01816 4.96286 1.28987 4.84892 1.57392 4.84646C1.85797 4.84399 2.13162 4.95318 2.33594 5.15052L5.90335 8.71794L13.8041 0.817185C14.0073 0.614092 14.2828 0.5 14.57 0.5C14.8573 0.5 15.1328 0.614092 15.3359 0.817185Z" fill="black"/></svg>');
                                height: 1.1rem;
                                width: 1.5rem;
                                display: none;
                            }
                        }
                        @include breakpoint(medium) {
                            &:hover {
                                .color__container {
                                    background-color: color(gray, Gray-100);
                                    border-radius: .4rem;
                                }
                            }
                        }

                        &.--active {
                            background-color: color(gray, Gray-100);
                            border-radius: .4rem;

                            .--title {
                                font-weight: 700;
                            }

                            .--checkmark {
                                display: block;
                            }
                        }
                    }
                    .--first-item {
                        .color__container {
                            justify-content: start;
                            .--title {
                                margin-left: .8rem;
                            }
                            .--count {
                                margin-left: auto;
                            }
                        }
                    }
                }

                .price__filter {
                    .custom__controls {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .divider {
                            height: 1px;
                            width: 20px;
                            background-color: color(base, Black);
                            margin-bottom: 0;
                        }

                        input {
                            border-radius: .4rem;
                            height: 4rem;
                            width: 8.4rem;
                            border-color: color(gray, Gray-150);
                            text-align: center;
                        }

                        @include breakpoint(small only) {
                            gap: .8rem;
                            .divider{
                               width: 2rem;
                            }
                            input {
                                flex:1;
                            }
                        }
                    }

                    .vue-slider {
                        margin-top: 1.5rem;
                    }
                }

                > button {
                    display: inline-flex;
                    margin: .5rem 0 0;
                    font-size: 1.4rem;
                    line-height: 2.1rem;
                    @include breakpoint(medium) {
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }

    @include breakpoint(medium) {
        margin-right: 3rem;
        margin-top: 1rem;
        .modal__header {
            display: none;
        }
        .modal__content {
            padding: 0;
        }
    }

    .show-more {
        color: color(base, Tertiary);
        justify-content: center;
        width: 100%;
        text-decoration: underline;
    }

    .filter--attr {
        li label {
            display: flex;
            height: 4rem;
            align-items: center;

            .--count {
                margin-left: auto;
            }
        }
    }
}


.categories_list {
    padding: 1rem 0 0;
    display: block;
    @include breakpoint(medium) {
        margin-bottom: 1rem;
    }

    .swiper-slide {
        width: auto;
    }

    .swiper-slide {
        .--button:hover {
            line-height: 2.4rem;
        }
    }

    .swiper-navigation {
        display: none;
    }

}

#offCanvas-filter {
    @include breakpoint(small only) {
        width: 100%;
        background-color: color(gray, Gray-soft);
    }
}

.catalog__category{
    .catalog-category__toolbar.--top{
        justify-content: end;
    }
    @include breakpoint(medium){
        .catalog-category__sorter{
            width: auto;
            flex:unset;
        }
    }
}
