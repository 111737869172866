.rating-container {
    display: block;
    width: 7rem;
    height: 1.2rem;
    background-image: url("data:image/svg+xml,%3Csvg width='70' height='12' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23FFEB99' fill-rule='nonzero'%3E%3Cpath d='M6.614 0 4.305 3.601 0 4.583l2.877 3.208L2.526 12l4.088-1.619L10.701 12l-.351-4.209 2.877-3.208-4.304-.982L6.614 0zM20.807 0l-2.309 3.601-4.305.982 2.877 3.208L16.719 12l4.088-1.619L24.894 12l-.35-4.209 2.877-3.208-4.305-.982L20.807 0zM35 0l-2.309 3.601-4.305.982 2.877 3.208L30.912 12 35 10.381 39.087 12l-.35-4.209 2.877-3.208-4.305-.982L35 0zM49.193 0l-2.309 3.601-4.305.982 2.877 3.208L45.105 12l4.088-1.619L53.281 12l-.351-4.209 2.877-3.208-4.304-.982L49.193 0zM63.386 0l-2.309 3.601-4.304.982 2.877 3.208L59.299 12l4.087-1.619L67.474 12l-.351-4.209L70 4.583l-4.304-.982L63.386 0z'/%3E%3C/g%3E%3C/svg%3E");
    .--fill {
        display: block;
        height: 1.2rem;
        background-image: url("data:image/svg+xml,%3Csvg width='70' height='12' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='gold' fill-rule='nonzero'%3E%3Cpath d='M6.614 0 4.305 3.601 0 4.583l2.877 3.208L2.526 12l4.088-1.619L10.701 12l-.351-4.209 2.877-3.208-4.304-.982L6.614 0zM20.807 0l-2.309 3.601-4.305.982 2.877 3.208L16.719 12l4.088-1.619L24.894 12l-.35-4.209 2.877-3.208-4.305-.982L20.807 0zM35 0l-2.309 3.601-4.305.982 2.877 3.208L30.912 12 35 10.381 39.087 12l-.35-4.209 2.877-3.208-4.305-.982L35 0zM49.193 0l-2.309 3.601-4.305.982 2.877 3.208L45.105 12l4.088-1.619L53.281 12l-.351-4.209 2.877-3.208-4.304-.982L49.193 0zM63.386 0l-2.309 3.601-4.304.982 2.877 3.208L59.299 12l4.087-1.619L67.474 12l-.351-4.209L70 4.583l-4.304-.982L63.386 0z'/%3E%3C/g%3E%3C/svg%3E");
    }
    &.empty {
        background-image: none;
    }
}

.rating__group-container {
    display: flex;
    align-items: center;
    .rating__group {
        display: flex;
        flex-direction: row-reverse;
        gap: .3rem;
        > input {
            opacity: 0;
            position: absolute;
            + label {
                height: 24px;
                width: 24px;
                display: block;
                cursor: pointer;
                background-repeat: no-repeat;
                background-size: contain;
                background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 35.3 33' style='enable-background:new 0 0 35.3 33;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23EAE7DE;%7D%0A%3C/style%3E%3Cpath class='st0' d='M17.6,0.5l-6.2,9.6L0,12.7l7.7,8.6L6.7,32.5l10.9-4.3l10.9,4.3l-0.9-11.2l7.7-8.6l-11.5-2.6L17.6,0.5z'/%3E%3C/svg%3E");                &::before, &::after {
                    display: none;
                }
            }
            &.required.failed ~ label {
                background-image: url("data:image/svg+xml,%3Csvg width='28' height='26' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{color(alert, Error)}' d='m14 21-8.229 4.326 1.572-9.163L.685 9.674l9.201-1.337L14 0l4.114 8.337 9.201 1.337-6.658 6.489 1.572 9.163z' fill='none' fill-rule='evenodd'/%3E%3C/svg%3E");
            }
            &:checked ~ label, & + label:hover ~ label, & + label:hover {
                background-image: url("data:image/svg+xml,%3Csvg width='28' height='26' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='gold' fill='gold' d='m14 21-8.229 4.326 1.572-9.163L.685 9.674l9.201-1.337L14 0l4.114 8.337 9.201 1.337-6.658 6.489 1.572 9.163z' fill-rule='evenodd'/%3E%3C/svg%3E");
            }
        }
    }
}
