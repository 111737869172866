@use "sass:math";

@mixin block-title {
    display: block;
    font-family: 'True North Textures', sans-serif;
    font-size: 2rem;
    text-align: center;
    justify-content: center;
    margin: 0 0 1rem;
    a {
        font-family: 'True North Textures', sans-serif;
        font-size: 1.9rem;
    }
    @include breakpoint(medium) {
        text-align: left;
        justify-content: flex-start;
        font-size: 2.4rem;
        margin: 0 0 2rem;
        a {
            font-size: 2.4rem;
        }
    }
    > span {
        margin: 0;
    }
}

.home_body {
    .catalog-product-list-toolbar-active-filters {
        @include breakpoint(small only) {
            background: color(base, White);
            left: 0;
            padding-bottom: 1rem;
            padding-left: 1rem !important;
            right: 0;
            top: 201px;
            width: 100%;
            z-index: 2;
            .active__filter__container {
                padding: .8rem 0 0;
            }
        }
    }
}
.catalog-product-list-toolbar{
    position: relative;
    width: 100%;
}
.page-builder__wrapper {

    @include breakpoint(small only) {
        section.catalog-product-list-toolbar {

            width: 100%;
            z-index: 1;
        }
        .catalog-category__toolbar {
            background: color(base, White);
            min-height: 40px;
            position: sticky;
            top: 144px;
            width: 100%;
            z-index: 1;

            .active__filter__container {
                margin-top: 6rem;
            }
        }
    }

    .page-builder__content-block {
        &:not(.--first) {
            margin: .85rem 0;
            padding-bottom: 0;
            padding-top: 0;
            @include breakpoint(medium) {
                padding-bottom: .75rem;
                padding-top: 1.75rem;
            }
        }

        .image__slider__container {
            > h2, > span {
                @include block-title;
            }

            position: relative;
            @include breakpoint(small only) {
                margin: 0 -1rem;
            }

            picture {
                display: flex;
            }

            .slide__button {
                bottom: 2rem;
                position: absolute;
                right: 2rem;
            }

            ul {
                align-items: center;
                display: flex;

                li {
                    flex: 1;

                    button {
                        cursor: pointer;
                        display: block;
                        font-size: 1.6rem;
                        line-height: 2.6rem;
                        margin: 0 auto;
                        padding: 1rem;
                        text-align: center;
                        width: 20rem;

                        &:hover {
                            background-image: url("data:image/svg+xml,%3Csvg width='140' height='4' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M44.403 3.791c2.51.011 5.104.007 7.934-.032 1.04-.014 1.94-.03 2.88-.046.42.008.787.019 1.244.024 3.038.036 7.096.015 12.399-.064 1.45-.022 4.073-.05 6.994-.083 3.567.025 6.952.027 9.475.015 2.92-.013 5.983-.119 9.224-.23 2.625-.09 5.34-.184 7.736-.222.212.004.797.036 1.182.056 1.247.068 2.797.152 4.635.12 17.903-.316 23.734-.78 25.62-1.133 3.773-.251 5.929-.525 5.728-.913a.157.157 0 0 0-.02-.027l.153-.021-.185-.002c-.665-.648-7.743-.738-11.948-.732-4.135.008-9.88.145-15.962.29l-.835.02a248.634 248.634 0 0 0-6.83 0c-2.894.042-5.785.116-8.15.208-.983.038-1.81.082-2.568.13-.358-.01-.692-.02-1.072-.028-2.013-.042-6.89-.061-11.958-.058-7.502-.117-19.304-.098-25.48-.049-1.437.012-2.863.048-4.24.083-1.041.025-2.1.052-3.12.066-2.201.026-4.41.049-6.546.07-5.72.058-10.661.108-14.176.21-.702.02-1.421.046-2.141.074-10.732-.124-16.617-.115-19.874-.013-1.488-.05-2.605-.022-3.002.185-.844.106-1.11.24-1.262.392-.336.333-.897.89 20.014 1.413l14.82.269c3.103.053 5.972.075 8.744.097.419.003.559-.027.587-.069' fill='%231A1919' fill-rule='evenodd'/%3E%3C/svg%3E");
                            background-position: bottom center;
                            background-repeat: no-repeat;
                        }
                    }
                }
            }
        }
        .--navi{
            position: absolute;
            top:50%;
            transform: translateY(calc(-50% - 2.6rem));
            z-index: 2;
            @include breakpoint(medium){
                .--next{
                    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.704781 12.9618C0.357647 12.6147 0.357648 12.0519 0.704781 11.7047L5.40958 6.99995L0.704782 2.29515C0.357648 1.94802 0.357648 1.3852 0.704782 1.03807C1.05191 0.690939 1.61473 0.690939 1.96186 1.03807L7.29519 6.37141C7.64233 6.71854 7.64233 7.28135 7.29519 7.62849L1.96186 12.9618C1.61473 13.309 1.05191 13.309 0.704781 12.9618Z" fill="black"/></svg>');
                    transform:none;
                }
            }
            button{
                border-color: color(gray, Gray-100);
                background-color:rgba(255,255,255, .8);
                box-shadow: 0 .4rem .8rem 0 rgba(0,0,0,.08);
                background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.704781 12.9618C0.357647 12.6147 0.357648 12.0519 0.704781 11.7047L5.40958 6.99995L0.704782 2.29515C0.357648 1.94802 0.357648 1.3852 0.704782 1.03807C1.05191 0.690939 1.61473 0.690939 1.96186 1.03807L7.29519 6.37141C7.64233 6.71854 7.64233 7.28135 7.29519 7.62849L1.96186 12.9618C1.61473 13.309 1.05191 13.309 0.704781 12.9618Z" fill="black"/></svg>');
                background-size: .7rem 1.2rem;
                border-radius: 10rem;
            }
            &.--next{
                right: 2.4rem;

            }
            &.--prev{
                left:2.4rem;
                button{
                    background-image:url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="13" viewBox="0 0 8 13" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.29522 0.438206C7.64235 0.785339 7.64235 1.34815 7.29522 1.69528L2.59043 6.40008L7.29522 11.1049C7.64235 11.452 7.64235 12.0148 7.29522 12.362C6.94809 12.7091 6.38527 12.7091 6.03814 12.362L0.704808 7.02862C0.357675 6.68149 0.357675 6.11867 0.704808 5.77154L6.03814 0.438206C6.38527 0.0910733 6.94809 0.0910733 7.29522 0.438206Z" fill="black"/></svg>');
                }
            }
        }
        .banner-slider__wrapper{
            position: relative;
        }
        .cat-slider__wrapper{
            position: relative;
            @include breakpoint(medium){
                .--navi {
                    top:12rem;
                }
            }
            button {
                width: 4rem;
                height: 4rem;
            }
        }
        .category-blocks__container {
            > h2, > span {
                @include block-title;

            }

            .category__placeholder-container {
                margin-top: 1rem;
                position: relative;
                @include breakpoint(small only) {
                    margin-right: -1rem;
                    margin-left: -1rem;
                    margin-top: 0;
                }
                @include breakpoint(medium) {
                    #category-block-placeholder .swiper-wrapper {
                        transform: translate3d(-1318.5px, 0px, 0px);
                        transition-duration: 0ms;
                    }
                }

                .category-blocks__categories {
                    .block-category-blocks{
                        position:relative;
                    }
                    .--header{
                        display: flex;
                        margin-bottom: 2rem;
                        h2 {
                            font-size: 2.4rem;
                            line-height: 4rem;
                            @include breakpoint(small only) {
                                text-align: center;
                                width: 100%;
                            }
                        }

                        .--navigation {
                            margin-left: auto;
                            display:flex;
                            gap:.8rem;
                            .--prev{
                                transform: scaleX(1)
                            }
                            .--next{
                                transform: scaleX(-1);
                            }
                            button {
                                width: 4rem;
                                height: 4rem;
                            }
                        }
                    }
                    &.--loaded {
                        bottom: 0;
                        left: 0;
                        position: absolute;
                        right: 0;
                        top: 0;
                    }

                    > section > section {
                        @include breakpoint(medium) {
                            align-items: center;
                            gap: 2rem;
                            .--navi button {
                                margin-top: -4rem;
                            }
                        }

                        .swiper-slide {
                            width: 28.5%;
                            @include breakpoint(medium) {
                                width: math.div(100%, 4);
                                @include breakpoint(1024px) {
                                    width: math.div(100%, 7.5);
                                }
                            }

                            a, > span {
                                display: block;
                                flex-shrink: 0;
                                padding: 0 .5rem;

                                span {
                                    display: block;
                                    font-size: 1.3rem;
                                    padding: 1rem;
                                    text-align: center;
                                }

                                &:not(span):hover span {
                                    text-decoration: underline;
                                }
                            }
                        }

                        .swiper__progress {
                            margin-right: 1rem;
                        }
                    }
                }
            }
        }

        .product__row__container {
            .block-product-row {
                margin: 0 -1rem;
            }
            > h2, > span {
                @include block-title;
            }

            > section > section {
                position: relative;
                @include breakpoint(medium) {
                    align-items: center;
                    display: flex;
                }

                .--navi {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    z-index: 3;
                    &:first-of-type {
                        left: .5rem;
                        @include breakpoint(small only) {
                            left: .5rem;
                        }
                    }
                    &:last-of-type {
                        right: .5rem;
                        @include breakpoint(small only) {
                            right: 0.5rem;
                        }
                    }
                }

                .swiper-container {
                    .swiper-slide {
                        width: 40%;
                        @include breakpoint(medium) {
                            width: math.div(100%, 6);
                        }

                        .product__tile {
                            border: none;
                        }
                    }
                }
            }
        }

        .folders__container {
            height: 100%;
            @include breakpoint(small only) {
                margin: 0 -1rem;
            }

            > a, > span {
                display: block;
                height: 100%;
                position: relative;

                picture {
                    display: flex;
                    height: 100%;

                    img {
                        object-fit: scale-down;
                        object-position: bottom;
                    }
                }

                > a, > span {
                    bottom: .5rem;
                    position: absolute;
                    right: .5rem;
                    @include breakpoint(medium) {
                        bottom: 2rem;
                        right: 2rem;
                    }
                }
            }
        }



        .folders__container {
            height: 100%;
            @include breakpoint(small only) {
                margin: 0 -1rem;
            }

            > a, > span {
                display: block;
                height: 100%;
                position: relative;

                picture {
                    display: flex;
                    height: 100%;

                    img {
                        object-fit: scale-down;
                        object-position: bottom;
                    }
                }

                > a, > span {
                    bottom: .5rem;
                    position: absolute;
                    right: .5rem;
                    @include breakpoint(medium) {
                        bottom: 2rem;
                        right: 2rem;
                    }
                }
            }
        }
        .catalog__header-container {
            .catalog__header-count{
                font-family: "True North Textures", sans-serif;
            }
            .-title {
                font-size: 2rem;
                line-height: 2.8rem;
                text-align: center;
                border-top: .1rem solid color(gray, Gray-100);
                padding-top:2.4rem;
                span{
                    &:first-child {
                        @include font-primary;
                    }
                    &:last-child {
                        color: color(gray, Gray-150);
                    }
                }
            }
        }
    }

    .flow-box__container {
        overflow: hidden;

        .flow-box__holder {
            margin: 0 -5rem;
        }

        h2, span {
            @include block-title;
            align-items: center;
            display: flex;
            gap: .5rem;

            span {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-brand-instagram' width='44' height='44' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(base, DarkFontColor)}' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M0 0h24v24H0z' stroke='none'/%3E%3Crect x='4' y='4' width='16' height='16' rx='4'/%3E%3Ccircle cx='12' cy='12' r='3'/%3E%3Cpath d='M16.5 7.5v.001'/%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-size: contain;
                display: block;
                height: 3rem;
                width: 3rem;
            }
        }

        p a {
            color: color(base, Tertiary);
        }

        .bzfy-c-carousel-slider-wrapper__inner {
            padding: 0 40px;
        }
        .bzfy-t-feed-carousel .bzfy-c-carousel-control {
            // overwrite the default styles of instagram API so !important is necessary in this case
            background-color:rgba(255,255,255,.9) !important;
            border: color(gray, Gray-150) !important;
            background-repeat: no-repeat !important;
            background-image:url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="13" viewBox="0 0 8 13" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.29522 0.438206C7.64235 0.785339 7.64235 1.34815 7.29522 1.69528L2.59043 6.40008L7.29522 11.1049C7.64235 11.452 7.64235 12.0148 7.29522 12.362C6.94809 12.7091 6.38527 12.7091 6.03814 12.362L0.704808 7.02862C0.357675 6.68149 0.357675 6.11867 0.704808 5.77154L6.03814 0.438206C6.38527 0.0910733 6.94809 0.0910733 7.29522 0.438206Z" fill="black"/></svg>') !important;
            background-position: center center !important;
            left: 7rem;
            background-size: .7rem 1.2rem;
            .bzfy-icon{
                display: none;
            }
            &.bzfy-c-carousel-control--right{
                left:unset;
                right: 7rem;
                background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.704781 12.9618C0.357647 12.6147 0.357648 12.0519 0.704781 11.7047L5.40958 6.99995L0.704782 2.29515C0.357648 1.94802 0.357648 1.3852 0.704782 1.03807C1.05191 0.690939 1.61473 0.690939 1.96186 1.03807L7.29519 6.37141C7.64233 6.71854 7.64233 7.28135 7.29519 7.62849L1.96186 12.9618C1.61473 13.309 1.05191 13.309 0.704781 12.9618Z" fill="black"/></svg>') !important;
            }
        }
        .bzfy-c-mobile-carousel-control{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width:92%;
            justify-content: space-between;
        }
        .bzfy-c-mobile-circle{
            // overwrite the default inline-styles of instagram API so !important is necessary in this case

            background-color:rgba(255,255,255,.9) !important;
            height: 4rem !important;
            width: 4rem !important;
            border: color(gray, Gray-150);
            background-repeat: no-repeat;
            background-image:url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="13" viewBox="0 0 8 13" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.29522 0.438206C7.64235 0.785339 7.64235 1.34815 7.29522 1.69528L2.59043 6.40008L7.29522 11.1049C7.64235 11.452 7.64235 12.0148 7.29522 12.362C6.94809 12.7091 6.38527 12.7091 6.03814 12.362L0.704808 7.02862C0.357675 6.68149 0.357675 6.11867 0.704808 5.77154L6.03814 0.438206C6.38527 0.0910733 6.94809 0.0910733 7.29522 0.438206Z" fill="black"/></svg>');
            background-position: center center;
            left: 5.3rem;
            background-size: .7rem 1.2rem;
            box-shadow: 0 .4rem .8rem 0 rgba(0, 0, 0, .08);

            &.bzfy-c-right{
                left:unset;
                right: 1.6rem;
                background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.704781 12.9618C0.357647 12.6147 0.357648 12.0519 0.704781 11.7047L5.40958 6.99995L0.704782 2.29515C0.357648 1.94802 0.357648 1.3852 0.704782 1.03807C1.05191 0.690939 1.61473 0.690939 1.96186 1.03807L7.29519 6.37141C7.64233 6.71854 7.64233 7.28135 7.29519 7.62849L1.96186 12.9618C1.61473 13.309 1.05191 13.309 0.704781 12.9618Z" fill="black"/></svg>');
            }
            img{
                display: none;
            }
        }

    }

    .page-builder__banner-slide-container {
        @include breakpoint(medium) {
            padding: 0 2rem;
        }
    }

    .page-builder__blog-posts-container,
    .page-builder__banner-slide-container {
        > h2, > span {
            @include block-title();

            @include breakpoint(medium) {
                line-height: 4rem;
            }
        }

        .banner-image-four {
            section {
                position: relative;
                width: 100%;
            }

            .--header {
                line-height: 4rem;
                margin: 0 0 2rem;
                display: flex;


                h2 {
                    font-size: 2rem;
                    line-height: 4rem;
                    @include breakpoint(small only) {
                        text-align: center;
                        width: 100%;
                    }
                }

                .--navigation {
                    margin-left: auto;
                }
            }

            .banner__container--row .swiper-slide {
                max-width: 50%;
                width: 50%;
            }

            img {
                @include border-radius();
                transition: transform .3s ease-in-out;
            }

            .swiper-slide {
                margin-bottom: auto;
                margin-top: auto;

                &:hover {
                    img {
                        transform: scale(1.05);
                    }
                }

                a {
                    inset: 0;
                    position: absolute;
                }
            }

            .--navi {
                margin-top: unset !important;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);



                &.hideOnMobile {
                    @include breakpoint(small only) {
                        display: none;
                    }
                }

                &.hideOnDesktop {
                    @include breakpoint(medium) {
                        display: none;
                    }
                }

                .swiper-button-disabled {
                    opacity: 0.4;
                }

                &.--prev {
                    left: -2rem;
                    @include breakpoint(small only) {
                        left: -.5rem;
                    }
                }

                &.--next {
                    right: -2rem;
                    @include breakpoint(small only) {
                        right: -.5rem;
                    }
                }
            }
        }

        .page-builder__blog-posts,
        .banner-image-four {

            .--navi {
                margin-top: -5rem;
            }

            .--navigation {
                display: flex;
                gap: 1rem;
                position: absolute;
                top: -60px;
                right: 0;

                @include inline-swiper-nav();

                &.hideOnDesktop {
                    display: none;
                }
            }

            &:is(.banner-image-four) {
                .--navigation {
                    position: initial;
                }
            }

            .swiper-slide {
                padding: 0 1rem;
                width: 45%;
                @include breakpoint(medium) {
                    width: 25%;
                    &.--overflow {
                        width: 23%;
                    }
                }

                a {
                    display: block;

                    .image__holder {
                        align-items: center;
                        aspect-ratio: 1;
                        border: .1rem solid color(base, Black);
                        display: flex;
                        justify-content: center;
                        border-radius: 2.2rem;

                        picture {
                            display: flex;
                        }
                    }

                    .--title {
                        display: block;
                        font-size: 1.4rem;
                        line-height: 2.2rem;
                        margin: 1rem 0 0;
                        text-align: center;
                    }
                }
            }
        }
        .block-blog-posts {
            position: relative;
            .--navi {
                top: 50%;
                @include breakpoint(small only){
                    top: 45%;
                }
                &.--next{
                    right:2rem;
                }
                &.--prev{
                    left:2rem;
                }
                button{
                    height: 5.2rem;
                    width: 5.2rem;
                    @include  breakpoint(small only){
                        height: 4rem;
                        width: 4rem;
                    }
                }
            }
            .swiper-container{
                width: calc(100% + 2rem);
                margin-left: -1rem;
            }
            .swiper-slide {
                height: auto;
                @include breakpoint(small only){
                    padding: 0 0.4rem;
                }

                a {
                    display:flex;
                    flex-direction: column;
                    padding: 0;
                    height: 100%;
                    .image__holder {
                        border-radius: 1.6rem 1.6rem 0 0;
                        overflow: hidden;
                        border: none;

                        img {
                            border-radius: 0;
                        }
                    }
                    .text__holder{
                        display:flex;
                        background-color: color(gray, Gray-soft);
                        margin-top: 0;
                        padding: 2rem;
                        border-radius: 0 0 1.6rem 1.6rem;
                        text-align: left;
                        flex-grow: 1;
                        justify-content: space-between;

                        .button--link{
                            background-image:url('data:image/svg+xml,<svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M51.5 26C51.5 40.0833 40.0833 51.5 26 51.5C11.9167 51.5 0.500001 40.0833 0.500002 26C0.500004 11.9167 11.9167 0.499996 26 0.499998C40.0833 0.499999 51.5 11.9167 51.5 26Z" stroke="black"/><path fill-rule="evenodd" clip-rule="evenodd" d="M23.7049 31.9618C23.3578 31.6147 23.3578 31.0519 23.7049 30.7047L28.4097 25.9999L23.7049 21.2952C23.3578 20.948 23.3578 20.3852 23.7049 20.0381C24.052 19.6909 24.6148 19.6909 24.962 20.0381L30.2953 25.3714C30.6424 25.7185 30.6424 26.2814 30.2953 26.6285L24.962 31.9618C24.6148 32.309 24.052 32.309 23.7049 31.9618Z" fill="black"/></svg>');
                            background-size: contain;
                            background-repeat: no-repeat;
                            background-position: center center;
                            display: block;
                            content:'';
                            height: 5.2rem;
                            width: 5.2rem;
                            flex-shrink: 0;
                            @include breakpoint(small only){
                                display: none;
                            }
                        }
                        .labels{
                            width: 100%;
                            .label{
                                font-size:1.2rem;
                                // only show first 2 labels on mobile
                                @include breakpoint(small only) {
                                    display:none;
                                    &:nth-child(-n + 2){
                                        display:block;
                                    }
                                }
                            }
                        }
                    }
                    .--title {
                        text-align: left;
                        font-weight: 700;
                        font-size:1.5rem;
                        margin-top: 0;
                    }
                }
            }
        }
    }

    .page-builder__blog-posts-container{
        > span{
            text-align: left;
        }
    }

    .banner__container {
        > h2, > span {
            @include block-title;
        }

        .banner__container--row {
            .single__image, .cell {
                position: relative;

                .--button {
                    bottom: 2rem;
                    position: absolute;
                    right: 2rem;
                    @include breakpoint(small only) {
                        bottom: 1rem;
                        right: 1rem;
                    }
                }
            }

            .banners--vertical--first, .banners--vertical--second {
                position: relative;

                .--button {
                    bottom: 2rem;
                    position: absolute;
                    right: 2rem;
                    @include breakpoint(small only) {
                        bottom: .5rem;
                        right: .5rem;
                    }
                }
            }

            .banner__item {
                display: flex;
                height: 100%;
                position: relative;
                width: 100%;

                picture {
                    display: flex;
                    height: 100%;
                    width: 100%;

                    img {
                        height: 100%;
                        object-fit: cover;
                        width: 100%;
                        border-radius: 2rem;
                    }
                }

                a {
                    bottom: .5rem;
                    position: absolute;
                    right: .5rem;
                }
            }

            .banners--vertical {
                display: grid;
                row-gap: 1rem;
            }
        }
    }

    .hotspots__container {
        > h2, > span {
            @include block-title;
        }

        .hotspot__placeholder-container {
            position: relative;

            .hotspots__container--grid {
                display: flex;
                flex-wrap: wrap;
                margin: 0 -.5rem;

                > section {
                    display: flex;
                    flex: 1 1 50%;
                    padding: .5rem;
                }

                &.--loaded {
                    bottom: 0;
                    left: 0;
                    position: absolute;
                    right: 0;
                    top: 0;
                }
            }
        }
    }

    .buttons__block {
        display: flex;
        gap: 1rem;

        a {
            @include default-button;
            align-items: center;
            background: transparent;
            border: .1rem solid color(gray, Gray-200);
            color: color(gray, Gray-500);
            display: flex;
            flex: 1;
            gap: .5rem;
            justify-content: center;
            padding: .5rem 1rem;

            svg {
                height: 1.8rem;
                width: 1.8rem;
            }
        }
    }

    .--no-border .page-builder__content-block {
        border: none;
        height: 100%;
    }

    picture > img {
        height: 100%;
        width: 100%;
        border-radius: 2rem;
    }
}

.subcategory__container {
    .swiper-slide {
        @include breakpoint(medium) {
            width: math.div(100%, 4);
            @include breakpoint(1024px) {
                width: auto;
            }
        }
    }
}
.flow-box__container, .flbx-webp {
    overflow: hidden;

    .flow-box__holder {
        margin: 0 -5rem;
    }

    h2, span {
        @include block-title;
        align-items: center;
        display: flex;
        gap: .5rem;

        span {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-brand-instagram' width='44' height='44' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(base, DarkFontColor)}' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M0 0h24v24H0z' stroke='none'/%3E%3Crect x='4' y='4' width='16' height='16' rx='4'/%3E%3Ccircle cx='12' cy='12' r='3'/%3E%3Cpath d='M16.5 7.5v.001'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-size: contain;
            display: block;
            height: 3rem;
            width: 3rem;
        }
    }
    p a{
        color: color(base, Tertiary);
    }
    .bzfy-c-carousel-slider-wrapper__inner {
        padding: 0 40px;
    }
    .bzfy-t-feed-carousel .bzfy-c-carousel-control {
        // overwrite the default inline-styles of instagram API so !important is necessary in this case
        background-color:rgba(255,255,255,.9) !important;
        border: color(gray, Gray-150) !important;
        background-repeat: no-repeat !important;
        background-image:url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="13" viewBox="0 0 8 13" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.29522 0.438206C7.64235 0.785339 7.64235 1.34815 7.29522 1.69528L2.59043 6.40008L7.29522 11.1049C7.64235 11.452 7.64235 12.0148 7.29522 12.362C6.94809 12.7091 6.38527 12.7091 6.03814 12.362L0.704808 7.02862C0.357675 6.68149 0.357675 6.11867 0.704808 5.77154L6.03814 0.438206C6.38527 0.0910733 6.94809 0.0910733 7.29522 0.438206Z" fill="black"/></svg>') !important;
        background-position: center center !important;
        left: 7rem;
        background-size: .7rem 1.2rem;
        .bzfy-icon{
            display: none;
        }
        &.bzfy-c-carousel-control--right{
            left:unset;
            right: 7rem;
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.704781 12.9618C0.357647 12.6147 0.357648 12.0519 0.704781 11.7047L5.40958 6.99995L0.704782 2.29515C0.357648 1.94802 0.357648 1.3852 0.704782 1.03807C1.05191 0.690939 1.61473 0.690939 1.96186 1.03807L7.29519 6.37141C7.64233 6.71854 7.64233 7.28135 7.29519 7.62849L1.96186 12.9618C1.61473 13.309 1.05191 13.309 0.704781 12.9618Z" fill="black"/></svg>') !important;
        }
    }
    .bzfy-c-mobile-carousel-control{
        position: absolute;
        top: 48%;
        transform: translateY(-50%);
        width:92%;
        // overwrite the default inline-styles of instagram API so !important is necessary in this case
        justify-content: space-between !important;
    }
    .bzfy-c-mobile-circle{
        // overwrite the default inline-styles of instagram API so !important is necessary in this case

        background-color:rgba(255,255,255,.9) !important;
        height: 4rem !important;
        width: 4rem !important;
        border: color(gray, Gray-150);
        background-repeat: no-repeat;
        background-image:url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="13" viewBox="0 0 8 13" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.29522 0.438206C7.64235 0.785339 7.64235 1.34815 7.29522 1.69528L2.59043 6.40008L7.29522 11.1049C7.64235 11.452 7.64235 12.0148 7.29522 12.362C6.94809 12.7091 6.38527 12.7091 6.03814 12.362L0.704808 7.02862C0.357675 6.68149 0.357675 6.11867 0.704808 5.77154L6.03814 0.438206C6.38527 0.0910733 6.94809 0.0910733 7.29522 0.438206Z" fill="black"/></svg>');
        background-position: center center;
        left: 5.3rem;
        background-size: .7rem 1.2rem;
        box-shadow: 0 .4rem .8rem 0 rgba(0, 0, 0, .08);

        &.bzfy-c-right{
            left:unset;
            right: 5.3rem;
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.704781 12.9618C0.357647 12.6147 0.357648 12.0519 0.704781 11.7047L5.40958 6.99995L0.704782 2.29515C0.357648 1.94802 0.357648 1.3852 0.704782 1.03807C1.05191 0.690939 1.61473 0.690939 1.96186 1.03807L7.29519 6.37141C7.64233 6.71854 7.64233 7.28135 7.29519 7.62849L1.96186 12.9618C1.61473 13.309 1.05191 13.309 0.704781 12.9618Z" fill="black"/></svg>');
        }
        img{
            display: none;
        }
    }
}

.simple-text__container  {
    .description {
        margin: 3rem 0 0;
        position: relative;
        .block-content{
            margin-top: 1rem;
            margin-bottom: 4rem;
        }
        .catalog__description-col {
            font-size: 1.6rem;
            line-height: 3rem;
        }

        .--expand {
            color: color(base, Black);
            text-decoration: underline;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 0;
            z-index: 1;
            font-size: 1.3rem;
            margin: auto;
        }
        .--expanded {
            display: block;
            position: relative;
            margin:auto;
            left:0;
            transform: translateX(0);

        }

        &.--closed {
            position: relative;
            max-height: 25rem;
            overflow: hidden;

            &:after {
                content: '';
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                position: absolute;
                background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);

            }
        }

        // Add a transition for smoother height changes
        transition: max-height 0.3s ease-out;

        // Remove the max-height limitation when not closed
        &:not(.--closed) {
            max-height: none;
        }
    }
    .grid-x {
        gap: 2rem;
        flex-flow: unset;
        @include breakpoint(small only){
            display:block;
        }
        .medium-12 {
            flex: 1 1 50%;
            &:first-child {
                @include breakpoint(small only){
                    margin-bottom: 2rem;
                }
            }
            @include breakpoint(small only){
                flex: 0 0 auto;
            }
        }
    }
}

.product__slider{

    .product__row__container{
        position:relative;
        .swiper-slide a{
            border: none;
            &:hover{
                border:none;
                color: color(base, Black);
                h2, span{
                    color: color(base, Black);
                }
            }
        }
        .--navi {
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 3;
            &:first-of-type {
                @include breakpoint(small only) {
                    left: 20px;
                }
            }
            &:last-of-type {
                right: -50px;
                @include breakpoint(small only) {
                    right: -30px;
                }
            }
            button{
                background-color: hsla(0, 0%, 100%, .8);
                background-image:url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="13" viewBox="0 0 8 13" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.29522 0.438206C7.64235 0.785339 7.64235 1.34815 7.29522 1.69528L2.59043 6.40008L7.29522 11.1049C7.64235 11.452 7.64235 12.0148 7.29522 12.362C6.94809 12.7091 6.38527 12.7091 6.03814 12.362L0.704808 7.02862C0.357675 6.68149 0.357675 6.11867 0.704808 5.77154L6.03814 0.438206C6.38527 0.0910733 6.94809 0.0910733 7.29522 0.438206Z" fill="black"/></svg>');
                background-size: .7rem 1.2rem;
                border-color: #eae7e8;
                border-radius: 10rem;
                box-shadow: 0 .4rem .8rem 0 rgba(0,0,0,.08);
                &.--next{
                    transform: scaleX(-1);
                }
                &.--prev{
                    transform: none;
                }
            }
        }
    }
}

.flbx-webp {
    overflow: hidden;
    &.flow-box__container {
        .bzfy-t-feed-carousel .bzfy-c-carousel-control {
            left: 7rem;

            &.bzfy-c-carousel-control--right{
                right: 7rem;
            }
        }
    }
    .flow-box__holder {
        margin: 0 -5rem;
    }

    h2, span {
        @include block-title;
        align-items: center;
        display: flex;
        gap: .5rem;

        span {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-brand-instagram' width='44' height='44' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(base, DarkFontColor)}' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M0 0h24v24H0z' stroke='none'/%3E%3Crect x='4' y='4' width='16' height='16' rx='4'/%3E%3Ccircle cx='12' cy='12' r='3'/%3E%3Cpath d='M16.5 7.5v.001'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-size: contain;
            display: block;
            height: 3rem;
            width: 3rem;
        }
    }
    p a{
        color: color(base, Tertiary);
    }
    .bzfy-c-carousel-slider-wrapper__inner {
        padding: 0 40px;
    }
    .bzfy-t-feed-carousel .bzfy-c-carousel-control {
        // overwrite the default inline-styles of instagram API so !important is necessary in this case
        background-color:rgba(255,255,255,.9) !important;
        border: color(gray, Gray-150) !important;
        background-repeat: no-repeat !important;
        background-image:url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="13" viewBox="0 0 8 13" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.29522 0.438206C7.64235 0.785339 7.64235 1.34815 7.29522 1.69528L2.59043 6.40008L7.29522 11.1049C7.64235 11.452 7.64235 12.0148 7.29522 12.362C6.94809 12.7091 6.38527 12.7091 6.03814 12.362L0.704808 7.02862C0.357675 6.68149 0.357675 6.11867 0.704808 5.77154L6.03814 0.438206C6.38527 0.0910733 6.94809 0.0910733 7.29522 0.438206Z" fill="black"/></svg>') !important;
        background-position: center center !important;
        left: 1rem;
        background-size: .7rem 1.2rem;
        .bzfy-icon{
            display: none;
        }
        &.bzfy-c-carousel-control--right{
            left:unset;
            right: 1rem;
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.704781 12.9618C0.357647 12.6147 0.357648 12.0519 0.704781 11.7047L5.40958 6.99995L0.704782 2.29515C0.357648 1.94802 0.357648 1.3852 0.704782 1.03807C1.05191 0.690939 1.61473 0.690939 1.96186 1.03807L7.29519 6.37141C7.64233 6.71854 7.64233 7.28135 7.29519 7.62849L1.96186 12.9618C1.61473 13.309 1.05191 13.309 0.704781 12.9618Z" fill="black"/></svg>') !important;
        }
    }
    .bzfy-c-mobile-carousel-control{
        position: absolute;
        top: 48%;
        transform: translateY(-50%);
        width:92%;
        // overwrite the default inline-styles of instagram API so !important is necessary in this case
        justify-content: space-between !important;
    }
    .bzfy-c-mobile-circle{
        // overwrite the default inline-styles of instagram API so !important is necessary in this case

        background-color:rgba(255,255,255,.9) !important;
        height: 4rem !important;
        width: 4rem !important;
        border: color(gray, Gray-150);
        background-repeat: no-repeat;
        background-image:url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="13" viewBox="0 0 8 13" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.29522 0.438206C7.64235 0.785339 7.64235 1.34815 7.29522 1.69528L2.59043 6.40008L7.29522 11.1049C7.64235 11.452 7.64235 12.0148 7.29522 12.362C6.94809 12.7091 6.38527 12.7091 6.03814 12.362L0.704808 7.02862C0.357675 6.68149 0.357675 6.11867 0.704808 5.77154L6.03814 0.438206C6.38527 0.0910733 6.94809 0.0910733 7.29522 0.438206Z" fill="black"/></svg>');
        background-position: center center;
        left: .3rem;
        background-size: .7rem 1.2rem;
        box-shadow: 0 .4rem .8rem 0 rgba(0, 0, 0, .08);

        &.bzfy-c-right{
            left:unset;
            right: .3rem;
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.704781 12.9618C0.357647 12.6147 0.357648 12.0519 0.704781 11.7047L5.40958 6.99995L0.704782 2.29515C0.357648 1.94802 0.357648 1.3852 0.704782 1.03807C1.05191 0.690939 1.61473 0.690939 1.96186 1.03807L7.29519 6.37141C7.64233 6.71854 7.64233 7.28135 7.29519 7.62849L1.96186 12.9618C1.61473 13.309 1.05191 13.309 0.704781 12.9618Z" fill="black"/></svg>');
        }
        img{
            display: none;
        }
    }
}

.product__information-container {
    .flbx-webp .bzfy-t-feed-carousel .bzfy-c-carousel-control{
        left:2rem;
        &.bzfy-c-carousel-control--right{
            right:2rem;
            left:unset;
        }
    }
    .bzfy-t-feed-carousel .bzfy-c-mobile-carousel-control{
        padding:0 1.6rem;
        transform: translate(-50%, -50%);
    }
}
